import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where, } from 'firebase/firestore';

@Injectable()
export class StagedPairingService extends BaseService {

  node = 'staged-pairing';

  createPairing(data: any) {
    return super.create(this.node, data);
  }

  async getByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    
    return docSnap.data();
  }

  async getForEvent(eventId: string) {
    const db = getFirestore(); 
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId),
      orderBy('time', 'asc'),
    )

    return (await getDocs(q)).docs;    
  }

  async getForUserAndEvent(user: string, eventId: string) {
    const db = getFirestore(); 
    const q = query(
      collection(db, this.node),
      where('playerArray', 'array-contains', user),
      where('eventId', '==', eventId)
    )

    return (await getDocs(q)).docs;    
  }

  getForEventAsOBS(eventId: string) {
      return this.afs.collection(this.node, ref => ref.where('eventId', '==', eventId));
  }

  async updatePlayerInfo(stagedPairing) {
    const { id, players, playerArray, updateOpId } = stagedPairing;
    return this.afs.collection(this.node).doc(id)
    .update({
        players,
        playerArray,
        updateOpId,
      });
  }

  delete(id) {
    return this.afs.collection(this.node).doc(id).delete();
  }
}
