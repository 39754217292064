// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --ion-background-color: var(--ion-color-light-gray);
}

/* ion-item {
    --ion-background-color: var(--ion-color-black);
    color: var(--ion-color-light)
} */
ion-card {
  --ion-background-color: white;
  font-weight: bold;
  color: var(--ion-color-new-blue);
}

ion-item {
  --ion-background-color: white;
  font-weight: bold;
  color: var(--ion-color-new-blue);
}

/* .member-card {
    --ion-background-color: var(--ion-color-black)
} */
ion-toggle {
  padding: 20px 20px 20px 30px;
  --handle-width: 20px;
  --handle-height: 20px;
  --handle-max-height: auto;
  --handle-spacing: 6px;
  contain: none;
}

ion-toggle::part(track),
ion-toggle.toggle-checked::part(track) {
  height: 12px;
  width: 50px;
  background: var(--ion-color-light-gray);
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
}

ion-toggle::part(handle) {
  background: var(--ion-color-danger);
  border-radius: 4px;
  border: 2.5px solid white;
  box-shadow: none;
}

ion-toggle.toggle-checked::part(handle) {
  background: var(--ion-color-danger);
}

.name {
  font-size: 115%;
  font-weight: bolder;
  color: var(--ion-color-danger);
}`, "",{"version":3,"sources":["webpack://./src/app/new-pairing-modal/new-pairing-modal2.page.scss"],"names":[],"mappings":"AAAA;EACI,mDAAA;AACJ;;AAEA;;;GAAA;AAKA;EACI,6BAAA;EACA,iBAAA;EACA,gCAAA;AAAJ;;AAGA;EACE,6BAAA;EACA,iBAAA;EACA,gCAAA;AAAF;;AAGA;;GAAA;AAIA;EACI,4BAAA;EACA,oBAAA;EACA,qBAAA;EACA,yBAAA;EACA,qBAAA;EACA,aAAA;AADJ;;AAIE;;EAEE,YAAA;EACA,WAAA;EACA,uCAAA;EAEA,gEAAA;EACA,iBAAA;AAFJ;;AAKE;EACE,mCAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;;AAKE;EACE,mCAAA;AAFJ;;AAKE;EACE,eAAA;EACA,mBAAA;EACA,8BAAA;AAFJ","sourcesContent":["ion-content {\n    --ion-background-color: var(--ion-color-light-gray);\n}\n\n/* ion-item {\n    --ion-background-color: var(--ion-color-black);\n    color: var(--ion-color-light)\n} */\n\nion-card {\n    --ion-background-color: white;\n    font-weight: bold;\n    color: var(--ion-color-new-blue)\n} \n\nion-item {\n  --ion-background-color: white;\n  font-weight: bold;\n  color: var(--ion-color-new-blue)\n} \n\n/* .member-card {\n    --ion-background-color: var(--ion-color-black)\n} */\n\nion-toggle {\n    padding: 20px 20px 20px 30px;\n    --handle-width: 20px;\n    --handle-height: 20px;\n    --handle-max-height: auto;\n    --handle-spacing: 6px;\n    contain: none;\n  }\n  \n  ion-toggle::part(track),\n  ion-toggle.toggle-checked::part(track) {\n    height: 12px;\n    width: 50px;\n    background: var(--ion-color-light-gray);\n  \n    /* Required for iOS handle to overflow the height of the track */\n    overflow: visible;\n  }\n  \n  ion-toggle::part(handle) {\n    background: var(--ion-color-danger);\n    border-radius: 4px;\n    border: 2.5px solid white;\n    box-shadow: none;\n  }\n  \n  ion-toggle.toggle-checked::part(handle) {\n    background: var(--ion-color-danger);\n  }\n\n  .name {\n    font-size: 115%;\n    font-weight: bolder;\n    color: var(--ion-color-danger)\n  }\n\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
