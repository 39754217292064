import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service';
import { RsvpService } from '../services/rsvp.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { StagedPairingService } from '../services/staged-pairing.service';
import { TeeSheetPage } from '../tee-sheet/tee-sheet.page';

@Component({
  selector: 'app-set-group-modal',
  templateUrl: './set-group-modal.page.html',
  styleUrls: ['./set-group-modal.page.scss'],
})
export class SetGroupModalPage implements OnInit {

  currentUser = '';
  isLoading = true;
  loader: any;
  eventId: string;
  event: any;
  course: any;
  mensTees: any[];
  ladiesTees: any[];
  dbMembers = [];
  playersPlayingFromPreferredTees = [];
  isSubmitted = false;
  isDisabled = false;
  updateMode = false;
  stagedPairing: any;
  alreadyGrouped = [];

  selectForm = this.fb.group({
    selMembers: new FormControl(),
    members: this.fb.array([]),
  });

  constructor(
    private authService: AuthService,
    private eventService: EventService,
    private rsvpService: RsvpService,
    private stagedPairingService: StagedPairingService,
    private fb: FormBuilder,
    private navParams: NavParams,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
  ) { }

  async ngOnInit() {
    this.setLoading();
    this.currentUser = await this.authService.currentUser();
    this.getNavParams();
    this.event = await this.eventService.getByDocIdMod(this.eventId);
    await this.checkForStagedPairing();
    await this.loadInitialData();
    this.isLoading = false;
    this.dismissLoading();
  }

  getNavParams() {
    this.eventId = this.navParams.get('eventId');
  }

  async setLoading() {
    this.loader = await this.loadingCtrl.create({
      message: 'Loading',
      translucent: true,
      spinner: 'bubbles',
      duration: 6000,
    });
    await this.loader.present();
  }

  dismissLoading() {
    if (this.loader) {
      this.loader.dismiss();
    }
  }

  async checkForStagedPairing() {
    const docs = await this.stagedPairingService.getForUserAndEvent(this.currentUser, this.event.id)

    if (docs.length > 0) {
      for (const doc of docs) {
        const data = doc.data();
        const { playerArray } = data;
        this.selectForm.patchValue({ selMembers: playerArray });
        this.updateMode = true;
        this.stagedPairing = data;
      }
    } else {
      this.selectForm.patchValue({ selMembers: this.currentUser })
    }

    return docs && docs.length > 0 ? true : false;
  }

  async loadInitialData() {
    const promises = [];
    await this.getStagedPairings();
    promises.push(this.getMembers());
    return await Promise.all(promises);
  }

  async getStagedPairings() {
    const alreadyGrouped = [];
    const docs = await this.stagedPairingService.getForEvent(this.eventId);
    for (const doc of docs) {
      const data = doc.data();
      const { playerArray } = data;
      alreadyGrouped.push(...playerArray);
    }
    this.alreadyGrouped = alreadyGrouped;
  }

  async getMembers() {
    const docs = await this.rsvpService.getRsvpsListForEventMod(this.eventId);
    for (const doc of docs) {
      const docData = doc.data();
      const { firstname, lastname, id } = docData.member;
      const currentStagedPlayers = this.stagedPairing ? this.stagedPairing.playerArray : [];
      if (currentStagedPlayers.includes(id) || !this.alreadyGrouped.includes(id)) {
        const tempMember = {
          firstname,
          lastname,
          id,
        } as any;
        this.dbMembers.push(tempMember)
      }
    }
  }

  async submitForm() {
    this.isSubmitted = true;
    if (this.selectForm.valid) {
      this.isDisabled = true;
      const formData = this.selectForm.value;
      const { selMembers } = formData;
      const playerArray = selMembers;
      const players = [];
      for (const playerId of playerArray) {
        const theMember = this.dbMembers.find(m => m.id === playerId);
        if (theMember) {
          const { firstname, lastname } = theMember;
          const name = `${firstname} ${lastname}`;
          const player = {
            id: playerId,
            name,
            firstname,
            lastname,
          }
          players.push(player);
        }
      }

      const id = !this.updateMode ? this.stagedPairingService.getId() : this.stagedPairing.id;
      let newStagedPairing = {
        id,
        playerArray,
        players,
        eventId: this.eventId,
        updateOpId: this.currentUser,
        createdTS: this.stagedPairingService.getTimeStamp(),
        time: 'TBD',
      } as any;

      if (!this.updateMode) {
        await this.stagedPairingService.createPairing(newStagedPairing);
      } else {
        await this.stagedPairingService.updatePlayerInfo(newStagedPairing);
      }

      this.modalCtrl.dismiss();
    }
  }

  async teeSheet() {
    const popover = await this.modalCtrl.create({
      component: TeeSheetPage,
      componentProps: {
        eventId: this.eventId
      },
      cssClass: 'popover_setting',
      animated: true,
      showBackdrop: true
    });

    return await popover.present();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  get f() {
    return this.selectForm.controls;
  }
}
