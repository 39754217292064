import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PairingChangeEventService extends BaseService {

  node = 'pairing-change-event';

  getForPairing(pairingId: string) {
    return this.afs.collection(this.node, ref => ref
      .where('pairingId', '==', pairingId),
    )
  }
}