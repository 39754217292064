import { BaseService } from './base.service';
import { Injectable } from '@angular/core';


@Injectable()
export class BetSettledService extends BaseService {

  node = 'bet-settled';

  addBetSettled(data: any) {
    return super.create(this.node, data);
  }

  getForEventIdAndPlayerId(eventId: string, playerId: string) {
    return this.afs.collection(
        this.node, ref =>
        ref.where('eventId', '==', eventId)
          .where('playerId', '==', playerId))
  }

  delete(id: string) {
    return this.afs.collection(this.node).doc(id).delete();
  }
}
