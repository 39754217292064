// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smaller-font {
  font-size: 0.75em;
}

.new-blue {
  color: var(--ion-color-new-blue);
}

.bold {
  font-weight: bold;
}

.padding {
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/event-results-modal/event-results-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".smaller-font {\n    font-size: .75em;\n  }\n  \n.new-blue {\n  color: var(--ion-color-new-blue)\n}\n\n.bold {\n  font-weight: bold;\n}\n\n.padding {\n  padding-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
