// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smaller-font {
  font-size: 90%;
  white-space: normal;
}

.float-right {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/bet-pairing-modal/bet-pairing-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".smaller-font {\n    font-size: 90%;\n    white-space: normal;\n}\n\n.float-right { \n    float: right;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
