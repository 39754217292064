import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MonsterDetailService extends BaseService {

    node = 'monster-detail';

    createMonsterDetail(data) {
        return super.create(this.node, data);
    }

    getDetailForEvent(eventId: string) {
        return this.afs.collection('monster-detail', ref =>
            ref.where('eventId', '==', eventId));
    }
}