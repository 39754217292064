import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PlayerScoreService extends BaseService {

    node = 'player-score';


    getDetails() {
        return this.afs.collection('player-score-detail');
    }

    getForEvent(eventId) {
        return this.afs.collection(this.node, ref => ref
            .where('eventId', '==', eventId)
        );
    }

    getForEventSortRelationToPar(eventId) {
        return this.afs.collection(this.node, ref => ref
            .where('eventId', '==', eventId)
            .orderBy('aggRelationToParGross', 'asc')
        );
    }

    getForEventSortRelationToParNet(eventId) {
        return this.afs.collection(this.node, ref => ref
            .where('eventId', '==', eventId)
            .orderBy('aggRelationToParNet', 'asc')
        );
    }
}
