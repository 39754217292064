import { EventSummaryTotalService } from './../services/event-summary-total.service';
import { WorkerService } from './../services/worker-service';
import { MonsterDetailService } from './../services/monster-detail.service';
import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavParams, ModalController } from '@ionic/angular';
import { RsvpService } from '../services/rsvp.service';
import { FormBuilder, FormArray, FormControl } from '@angular/forms';
import { EventSummaryAdjustmentService } from '../services/event-summary-adjustment.service';

@Component({
  selector: 'app-monster-modal',
  templateUrl: './monster-modal.page.html',
  styleUrls: ['./monster-modal.page.scss'],
})
export class MonsterModalPage {
  subscription: Subscription;
  eventId: string;
  monsterArray: [];
  playingRsvps = [];
  viewEntered = false;

  public monsterForm = this.fb.group({
    monsters: this.fb.array([])
  });

  constructor(
    private monsterDetailService: MonsterDetailService,
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private eventSummaryAdjustmentService: EventSummaryAdjustmentService,
    private eventSummaryTotalService: EventSummaryTotalService,
    private rsvpService: RsvpService,
    private workerService: WorkerService,
  ) { }

  ionViewWillEnter() {

    this.subscription = new (Subscription)();
    this.eventId = this.navParams.get('eventId');
    this.monsterArray = this.navParams.get('monsterArray');
    this.retrieveEligibleMembers();
    this.populateMonsterFormArray();
  }

  ionViewDidEnter() {
    this.viewEntered = true;
  }

  retrieveEligibleMembers() {

    this.buildTempMember('None', '', 'n/a');
    const subsription = this.rsvpService.getRsvpThatArePlaying(this.eventId)
      .valueChanges()
      .subscribe((rsvps: any) => {
        for (const rsvp of rsvps) {
          const { firstname, lastname, memberAuthId } = rsvp;
          this.buildTempMember(firstname, lastname, memberAuthId);
        }
      });
    this.subscription.add(subsription);
  }

  buildTempMember(firstname: string, lastname: string, id: string) {
    const tempMember = {} as any;
    tempMember.firstname = firstname;
    tempMember.lastname = lastname;
    tempMember.id = id;
    this.playingRsvps.push(tempMember);
  }


  populateMonsterFormArray() {
    const detSubscription = this.monsterDetailService.getDetailForEvent(this.eventId)
      .valueChanges()
      .subscribe((monsterDetails: any) => {
        for (const monsterDetail of monsterDetails) {
          for (const monster of monsterDetail.monsters) {
            const { monsterHoleNumber, monsterPlayerId, monsterFirstname, monsterLastName } = monster;
            const formMonsterHole = this.fb.group({
              monsterHoleNumber: new FormControl(monsterHoleNumber),
              monsterPlayerId: new FormControl(monsterPlayerId),
              monsterFirstname: new FormControl(monsterFirstname),
              monsterLastname: new FormControl(monsterLastName),
              id: new FormControl()
            });
            this.monsters.push(formMonsterHole);
          }

        }

        for (const monsterHole of this.monsterArray) {
          const theMonsterHole = this.monsters.value.find((c: any) => c.monsterHoleNumber === monsterHole);

          if (theMonsterHole === null || theMonsterHole === undefined) {
            const newHole = {} as any;
            newHole.number = monsterHole;
            const formMonsterHole = this.fb.group({
              monsterHoleNumber: new FormControl(monsterHole),
              monsterPlayerId: new FormControl(),
              monsterFirstname: new FormControl(),
              monsterLastname: new FormControl(),
              id: new FormControl()
            });
            this.monsters.push(formMonsterHole);
          }
        }
      });

    this.subscription.add(detSubscription);

  }

  async submitForm() {
    const data: any = this.monsterForm.value;
    const { monsters } = data;
    data.id = this.eventId;
    data.eventId = this.eventId;
    let i = 0;
    for (const monster of monsters) {
      const { monsterPlayerId } = monster;
      const theMonster = this.playingRsvps.find(r => r.id === monsterPlayerId);
      if (theMonster != null) {
        const { firstname, lastname } = theMonster;
        data.monsters[i].monsterFirstname = firstname;
        data.monsters[i].monsterLastname = lastname;
      }
      i++;
    }
    await this.monsterDetailService.createMonsterDetail(data);
    const estSnapshots = await this.eventSummaryTotalService.getEventSummaryTotalForEventId(this.eventId)
      .get()
      .toPromise()
    if (estSnapshots.size > 0) {
      const newWorker = {} as any;
      newWorker.id = this.workerService.getId();
      newWorker.type = '9';
      newWorker.eventId = this.eventId;
      this.workerService.createEvent(newWorker)
      const newEventSummaryAdjustment = {} as any;
      newEventSummaryAdjustment.id = this.eventSummaryAdjustmentService.getId();
      newEventSummaryAdjustment.eventId = this.eventId;
      this.eventSummaryAdjustmentService.createAdjustment(newEventSummaryAdjustment);
    }
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  get monsters() {
    return this.monsterForm.get('monsters') as FormArray;
  }
}
