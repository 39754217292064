import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class EventSummaryTotalService extends BaseService {

  node = 'event-summary-total';

  getEventSummaryTotalForEventId(eventId: string) {
    return this.afs.collection(this.node,
      ref => ref.where('eventId', '==', eventId)
        .orderBy('name', 'asc'));
  }

  async getEventSummaryTotalForEventIdMod(eventId: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId)
    )
    
    return (await getDocs(q)).docs;
  }
}