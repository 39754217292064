import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import {  doc, getDoc, getFirestore } from 'firebase/firestore';

@Injectable()
export class EventPlayingCountService extends BaseService {

  node = 'event-playing-count';

  async getByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }
  
  getByDocId(id) {
    return this.afs.collection(this.node).doc(id);
  }

}
