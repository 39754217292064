// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --ion-background-color: var(--ion-color-light);
}

ion-item {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
}

.padding {
  padding: 1em;
}

.fb-items {
  background-color: lightgray;
  border-radius: 20px;
  padding: 0.5em;
}

.para {
  font-size: 80%;
  font-family: "Arial";
  padding-top: 8px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.para-diff-padding {
  font-size: 80%;
  font-family: "Arial";
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.larger-font {
  font-size: 1em;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/register-modal/register-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,8CAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,2BAAA;EACA,mBAAA;EACA,cAAA;AACJ;;AAEA;EACI,cAAA;EACA,oBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,cAAA;EACA,oBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,cAAA;EACA,YAAA;AACJ","sourcesContent":["ion-content {\n    --ion-background-color: var(--ion-color-light);\n}\n\nion-item { \n    padding-left: 10px;\n    padding-right: 10px;\n    border-radius: 20px;\n}\n\n.padding {\n    padding: 1em;\n}\n\n.fb-items {\n    background-color: lightgray;\n    border-radius: 20px;\n    padding: .5em;\n}\n\n.para {\n    font-size: 80%;\n    font-family: 'Arial';\n    padding-top: 8px;\n    padding-bottom: 0px;\n    padding-left: 10px;\n    padding-right: 10px;\n}\n\n.para-diff-padding {\n    font-size: 80%;\n    font-family: 'Arial';\n    padding-top: 0px;\n    padding-bottom: 0px;\n    padding-left: 20px;\n    padding-right: 20px;\n}\n\n.larger-font {\n    font-size: 1em;\n    padding: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
