import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { PointsTeamSizeValidator } from '../common/helpers/validators';
import { AuthService } from '../services/auth.service';
import { CourseService } from '../services/course.services';
import { EventService } from '../services/event.service';
import { GlobalService } from '../services/global.service';
import { HandicapHistoryService } from '../services/handicap-history.service';
import { MemberService } from '../services/member.service';
import { PairingService } from '../services/pairing-service';
import { RsvpService } from '../services/rsvp.service';
import { formatDisplayCourseHandicap, calcCourseHandicap, getNamesStr } from '../common/helpers/reuse-functions';
import { pairingValidations } from '../common/validations';
import { WorkerService } from '../services/worker-service';
import { EventFlightBreaksService } from '../services/event-flight-breaks.service';
import { StagedPairingService } from '../services/staged-pairing.service';
import { GroupService } from '../services/group.service';
import { PairingDeleteEventService } from '../services/pairing-delete-event.service';
import { Subscription } from 'rxjs';
import { PairingChangeEventService } from '../services/pairing-change-event.service';

@Component({
  selector: 'app-new-pairing-modal',
  templateUrl: './new-pairing-modal2.page.html',
  styleUrls: ['./new-pairing-modal2.page.scss'],
})
export class NewPairingModalPage {

  validations = pairingValidations;
  pairingId: string;
  eventId: string;
  event: any;
  dbMembers = [];
  isLoading = true;
  isUpdateMode = false;
  pointsDescriptions = [];
  lowSkinsGuy: any;
  mensTees: any[];
  ladiesTees: any[];
  course: any;
  currentUser: string;
  pairing: any;
  eventFormatType: number;
  isSubmitted = false;
  formLowGuy: any;
  newLowSkinsGuy: any;
  hasTeams = false;
  pairingPlayers = [];
  isDisabled = false;
  pointsHandicapMethods = [];
  playersPlayingFromPreferredTees = [];
  eventFlightBreaks: any;
  stagedPairingExists = false;
  loader: any;
  deleteLoader: any;
  changeLoader: any;
  teesMatch = true;
  subscription: Subscription;
  changedPairing: any;

  selectForm = this.fb.group({
    selMembers: new FormControl(),
    members: this.fb.array([]),
    usePoints: new FormControl(false),
    pointsDescription: new FormControl(),
    pointsAmount: new FormControl(),
    team1: new FormControl(),
    team2: new FormControl(),
    pointsHandicapMethod: new FormControl(),
    pointsHandicapPercentage: new FormControl(100, Validators.required),
    manualHandicaps: this.fb.array([]),
    excludePlayersFromSkins: new FormControl(false),
  });

  constructor(
    private authService: AuthService,
    private courseService: CourseService,
    private eventService: EventService,
    private eventFlightBreaksService: EventFlightBreaksService,
    private memberService: MemberService,
    private handicapHistoryService: HandicapHistoryService,
    private globalService: GlobalService,
    private groupService: GroupService,
    private pairingService: PairingService,
    private pairingDeleteEventService: PairingDeleteEventService,
    private pairingChangeEventService: PairingChangeEventService,
    private rsvpService: RsvpService,
    private stagedPairingService: StagedPairingService,
    private fb: FormBuilder,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private pointsTeamSizeValidator: PointsTeamSizeValidator,
  ) { }

  async ionViewWillEnter() {
    this.subscription = new Subscription;
    this.setLoading();
    const promises = [];
    this.currentUser = await this.authService.currentUser();
    this.getNavParams();
    this.subscribeToPairingDeleteEvent();
    this.pointsDescriptions = this.globalService.getPointsDescription();
    this.pointsHandicapMethods = this.globalService.getPointsHandicapMethods();
    await this.retrieveEventInfo();
    promises.push(this.getCourse());
    promises.push(this.getPlayingMembers());
    promises.push(this.checkForStagedPairing())
    this.checkTee()
    await Promise.all(promises)

    if (this.playersPlayingFromPreferredTees.length > 0) {
      this.presentPreferredTeeAlert()
    }

    await this.getLowCourseHandicap()

    if (!this.pairingId) {
      if (!this.stagedPairingExists) {
        this.populateCurrentMember();
      }
    } else {
      this.populateCurrentPlayers();
    }

    this.loader.dismiss();
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  async setLoading() {
    this.loader = await this.loadingCtrl.create({
      message: 'Loading',
      translucent: true,
      spinner: 'bubbles',
      duration: 6000,
    });

    await this.loader.present();
  }

  getNavParams() {
    this.pairingId = this.navParams.get('pairingId');
    this.isLoading = this.pairingId ? true : false;
    this.isUpdateMode = this.pairingId ? true : false;
    this.eventId = this.navParams.get('eventId');
    this.eventFormatType = this.navParams.get('eventFormatType');
  }

  retrieveEventInfo() {
    return new Promise(async (resolve, reject) => {
      this.event = await this.eventService.getByDocIdMod(this.eventId);
      await this.checkForFlights();
      resolve(this.event);
    });
  }

  subscribeToPairingDeleteEvent() {
    if (this.isUpdateMode) {
      const subscription = this.pairingDeleteEventService.getForPairing(this.pairingId)
        .valueChanges()
        .subscribe((pairingUpdateEvents: any) => {
          if (pairingUpdateEvents.length > 0) {
            if (this.deleteLoader) {
              this.deleteLoader.dismiss();
              this.modalCtrl.dismiss({
                pairing: null,
                warning: 2,
              });
            }
          }
        });
      this.subscription.add(subscription);
      const subscription2 = this.pairingChangeEventService.getForPairing(this.pairingId)
        .valueChanges()
        .subscribe((pairingChangeEvents: any) => {
          if (pairingChangeEvents.length > 0) {
            if (this.changeLoader) {
              this.changeLoader.dismiss();
              this.modalCtrl.dismiss({
                pairing: this.changedPairing,
                warning: 0,
              });
            }
          }
        });
      this.subscription.add(subscription2);
    }
  }

  dismissModalForChangeReset() {
    this.modalCtrl.dismiss({
      pairing: this.changedPairing,
      warning: 0,
    });
  }

  async resetPairing() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Pairing Reset',
      message: `You are attempting to reset your pairing. Reseting your pairing will remove the current pairing along with all scores that have already been entered. Select yes to continue with the reset. Then add your pairing and any scores as needed.`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            this.presentDoubleCheckAlert();
          }
        }, {
          text: 'No',
          handler: async () => {
            this.modalCtrl.dismiss();
          }
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
  }

  async presentDoubleCheckAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Pairing will be deleted',
      message: `You have chosen to delete your current pairing. This is a very rare choice and this is a second confirmation. If deleting your pairing is your goal, select Yes to continue or Cancel to cancel`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            this.deletePairing();
          }
        }, {
          text: 'Cancel',
          handler: async () => {
            this.modalCtrl.dismiss()
          }
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
  }

  async deletePairing() {
    this.deleteLoader = await this.loadingCtrl.create({
      message: 'Deleting your pairing',
      translucent: true,
      spinner: 'bubbles',
      duration: 20000,
    });

    await this.deleteLoader.present();

    this.pairingService.delete(this.pairingId);
  }

  async checkForFlights() {
    const { skinsUseFlights, skinsFlightDistributionOption, id } = this.event;
    if (skinsUseFlights && skinsFlightDistributionOption === 1) {
      this.eventFlightBreaks = await this.eventFlightBreaksService.getByDocId(id);
    }
  }

  async checkForStagedPairing() {
    return new Promise(async (resolve, reject) => {
      const docs = await this.stagedPairingService.getForUserAndEvent(this.currentUser, this.eventId);
      for (const doc of docs) {
        const data = doc.data();
        const { players, playerArray } = data;
        for (const player of players) {
          const { id } = player;
          await this.addMember(id);
        }
        this.selectForm.patchValue({
          'selMembers': playerArray
        })
        this.stagedPairingExists = true;
      }
      resolve(null);
    })
  }

  initializeMembers() {
    const mLength = this.members.length;
    for (let i = 0; i < mLength; i++) {
      (this.selectForm.get('members') as FormArray).removeAt(0);
    }
  }

  async getPlayingMembers() {
    return new Promise(async (resolve, reject) => {
      const pairedPlayers = await this.playersAlreadyInAPairing()

      const docs = await this.rsvpService.getRsvpThatArePlayingMod(this.eventId);
      for (const doc of docs) {
        const docData = doc.data();
        const { firstname, lastname, id, gender } = docData.member;
        if (!pairedPlayers.includes(id) || this.isUpdateMode) {
          const scores = this.populateScores();
          const tempMember = {
            firstname,
            lastname,
            id,
            gender,
            scores,
          } as any;
          this.dbMembers.push(tempMember)
        }
      }

      resolve(null)
    })
  }

  async getCourse() {
    return new Promise(async (resolve, reject) => {
      const courseData = await this.courseService.getCourseByDocIdMod(this.event.course);
      const { tees } = courseData;
      const filteredMensTees = tees.filter(t => t.gender === 'Male');
      this.mensTees = filteredMensTees.sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0);
      const filteredLadiesTees = tees.filter(t => t.gender === 'Female');
      this.ladiesTees = filteredLadiesTees.sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0);
      this.course = courseData;
      resolve(null)
    })
  }

  async savePairing() {
    this.isSubmitted = true;
    if (this.selectForm.valid) {
      this.isDisabled = true;
      const docs = await this.pairingService.getForEventAndMemberMod(this.eventId, this.currentUser);
      if (docs.length === 0 || this.isUpdateMode) {
        if (this.members.length > 1) {
          this.continueWithPairingSave();
        } else {
          this.presentOnePlayerWarning()
        }
      } else {
        this.modalCtrl.dismiss({
          pairing: null,
          warning: 1,
        })
      }
    }
  }

  async presentOnePlayerWarning() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Playing as a single',
      message: `You are attempting to save your group as a single player. This is uncommon but is sometimes valid. 
       If you want to continue, press Yes. Otherwise, press No. If you are simply trying to view Live Scoring while not playing be sure to mark yourself as not playing first`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            this.continueWithPairingSave();
          }
        }, {
          text: 'No',
          handler: async () => {
            this.modalCtrl.dismiss({
              pairing: null,
              warning: 0,
            })
          }
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
      .then();
  }

  async presentPreferredTeeAlert() {
    const names = [...this.playersPlayingFromPreferredTees];
    const nameStr = getNamesStr(names);

    const isAre = this.playersPlayingFromPreferredTees.length > 1 ? 'are' : 'is';
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Preferred Tees',
      message: `Today, ${nameStr} ${isAre} playing from their Preferred Tees (tees they have selected to play that differ from the group's tee). Likely no action is needed, just a heads up reminder for the group.`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Ok',
        },
      ]
    });

    await alert.present();
    alert.onDidDismiss()
      .then();
  }

  async checkTee() {
    if (this.event) {
      const groupData = await this.groupService.getByDocIdMod(this.event.groups[0]);
      const { tee: groupTee } = groupData;
      if (this.event && groupTee && this.event.tee !== groupTee) {
        this.teesMatch = false;
      }
    }
  }

  async continueWithPairingSave() {
    const { skinsFormat, useStablefordQuotaScoring, flightOption, birdOrBetOption, eventFormatType, doubleEagleAmount: eventDoubleEagleAmount,
      eagleAmount: eventEagleAmount, birdieAmount: eventBirdieAmount, parAmount: eventParAmount, bogeyAmount: eventBogeyAmount,
      otherAmount: eventOtherAmount, startingHole } = this.event;
    const memberData = this.selectForm.value;
    const { members, usePoints, pointsAmount, pointsDescription, pointsHandicapMethod, pointsHandicapPercentage, excludePlayersFromSkins } = memberData;

    const playerArray = [];
    for (const p of this.members.value) {
      playerArray.push(p.id)
    }
    const teamEventDetails = eventFormatType === 3 ? this.initTeamEventDetails() : {};
    let partners = usePoints ? ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''] : [];
    const holeIndex = startingHole && startingHole > 0 ? startingHole - 1 : 0;

    let newPairing = {
      playerArray,
      players: members,
      eventId: this.eventId,
      eventFormatType,
      skinsFormat,
      teamEventDetails,
      useStablefordQuotaScoring,
      flightOption,
      birdOrBetOption,
      excludePlayersFromSkins,
      startingHole: startingHole,
      holeIndex,
      currentHoleIndex: holeIndex,
      holesPlayed: 0,
      handicapSet: this.eventHandicapSet(),
      updateOpId: this.currentUser,
      createBetEvents: true,
      usePoints,
      pointsAmount,
      pointsDescription,
      pointsHandicapMethod,
      pointsHandicapPercentage,
      partners,
      proxs: [],
      twists: [],
      turns: [],
      team1: [],
      team2: [],
      autoCalc: false,
      hasTeams: false,
      selectPartners: false,
      hasProx: false,
      hasTwists: false,
      hasTurns: false,
      teamSize: 0,
      doubleEagleAmount: useStablefordQuotaScoring ? eventDoubleEagleAmount : 0,
      eagleAmount: useStablefordQuotaScoring ? eventEagleAmount : 0,
      birdieAmount: useStablefordQuotaScoring ? eventBirdieAmount : 0,
      parAmount: useStablefordQuotaScoring ? eventParAmount : 0,
      bogeyAmount: useStablefordQuotaScoring ? eventBogeyAmount : 0,
      otherAmount: useStablefordQuotaScoring ? eventOtherAmount : 0,
      createdTS: this.pairingService.getTimeStamp(),
    } as any;

    if (usePoints) {
      newPairing = this.addPointsInfo(newPairing);
    }

    if (!this.isUpdateMode) {
      newPairing.id = this.pairingService.getId();
      this.pairingId = newPairing.id;
      this.pairingService.createPairing(newPairing)
        .then(() => {
          this.checkForLowGuyChanges(newPairing)
          this.dismissModal(newPairing)
        })
        .catch(err => console.log(err));
    } else {
      newPairing.id = this.pairingId;
      this.updatePairing(newPairing);
    }
  }

  async updatePairing(pairing) {
    console.log('updating')
    const areSamePlayers = this.areSamePlayers(pairing);
    this.setChangeLoader(areSamePlayers);
    this.changedPairing = pairing;
    await this.pairingService.updatePairingDetails(pairing);
    this.checkForLowGuyChanges(pairing);
    if (areSamePlayers) {
      this.dismissModal(pairing)
    }
  }

  async setChangeLoader(bool: boolean) {
    if (!bool) {
      this.changeLoader = await this.loadingCtrl.create({
        message: 'Updating your pairing',
        translucent: true,
        spinner: 'bubbles',
        duration: 20000,
      });

      await this.changeLoader.present();
    }
  }

  areSamePlayers(pairing: any) {
    const originalPlayers = this.pairing.playerArray.sort();
    const newPlayers = pairing.playerArray.sort();
    for (let i = 0; i < originalPlayers.length; i++) {
      if (originalPlayers[i] !== newPlayers[i]) {
        return false;
      }
    }

    return true;
  }

  index(index: number) {
    const member = this.member(index);
    const { displayHandicapIndex, displayCourseHandicap } = member;
    return `${displayHandicapIndex}`;
  }

  courseDis(index: number) {
    const member = this.member(index);
    const { displayCourseHandicap } = member;
    return `${displayCourseHandicap}`;
  }

  indexCourse(index: number) {
    const member = this.member(index);
    const { displayHandicapIndex, displayCourseHandicap } = member;
    return `${displayHandicapIndex}, ${displayCourseHandicap}`;
  }

  skins(index: number) {
    const member = this.member(index);
    const { displaySkinsHandicap } = member;
    return this.event.skinsFormat > 1 ? displaySkinsHandicap : '';
  }

  name(index: number) {
    const member = this.member(index);
    return member.name;
  }

  extractName(index: number, isFirst: boolean) {
    const member = this.member(index);
    const { name } = member;
    const nameParts = name.split(' ');
    // isFirst get the first name else get lastname;
    const arrayIndex = isFirst ? 0 : 1;

    return nameParts[arrayIndex];
  }

  teeName(name, isFirst) {
    const nameParts = name.split(' ');
    // isFirst get the first name else get lastname;
    const arrayIndex = isFirst ? 0 : 1;
    return nameParts[arrayIndex];
  }

  member(index: number) {
    return (this.selectForm.get('members') as FormArray).at(index).value;
  }

  async checkForLowGuyChanges(newPairing: any) {
    if (this.formLowGuy || this.newLowSkinsGuy) {
      const pairingDocs = await this.pairingService.getForEventMod(this.event.id);
      for (const pairingDoc of pairingDocs) {
        const memId = this.formLowGuy ? this.formLowGuy.memberId : this.newLowSkinsGuy.memberId;
        const pairingData: any = pairingDoc.data();
        const { players, id: otherPairingId } = pairingData;
        if (otherPairingId !== this.pairingId) {
          for (let player of players) {
            const { playerId: pairingPlayerId, courseHandicap } = player;
            if (memId !== pairingPlayerId) {
              player.skinsHandicap = this.calcSkinsHandicap(pairingPlayerId, courseHandicap);
              const absSkinsHandicap = Math.abs(player.skinshandicap);
              player.displaySkinsHandicap = player.skinsHandicap >= 0 ? player.skinsHandicap : `+${absSkinsHandicap}`;
            }
          }
          this.pairingService.updatePlayers(otherPairingId, players)
            .then(() => this.dismissModal(newPairing))
            .catch(err => console.log(err));
        }
      }
    }
  }

  initTeamEventDetails() {
    const { teamFormat, teamSize, grossScoresToCount, netScoresToCount, teamEventAmount, teamUnevenMethod, calcTeamsInRealTime } = this.event;
    return {
      teamFormat,
      teamSize,
      grossScoresToCount,
      netScoresToCount,
      teamEventAmount,
      teamUnevenMethod,
      calcTeamsInRealTime,
    }
  }

  addPointsInfo(pairing) {
    const formData = this.selectForm.value;
    const { pointsDescription, members, team1, team2, pointsHandicapMethod, pointsHandicapPercentage } = formData;
    const modPairing = pairing;
    const pointHoles = [];
    const thePointsDescription = this.pointsDescriptions.find(p => p.value === pointsDescription);
    const { autoCalc, selectPartners, hasTeams, hasProx, hasTwists, hasTurns } = thePointsDescription;
    modPairing.autoCalc = thePointsDescription ? autoCalc : false;
    modPairing.selectPartners = thePointsDescription ? selectPartners : false;
    modPairing.hasTeams = thePointsDescription ? hasTeams : false;
    modPairing.hasProx = thePointsDescription ? hasProx : false;
    modPairing.proxs = thePointsDescription && hasProx ? this.initProxs() : [];
    modPairing.twists = hasTwists ? this.initBoolArr(true) : [];
    modPairing.turns = hasTurns ? this.initBoolArr(false) : [];
    modPairing.team1 = team1 ? team1 : [];
    modPairing.team2 = team2 ? team2 : [];
    for (let i = 0; i < 18; i++) {
      const pointHole = {
        points: 0,
      }
      pointHoles.push(pointHole);
    }
    for (let player of modPairing.players) {
      player.pointHoles = pointHoles;
    }
    if ([1].includes(pointsDescription)) {
      let partners = [];
      for (let p = 0; p < 18; p++) {
        const headsArr = [];
        const tailsArr = [];
        for (let r = 0; r < members.length; r++) {
          if (typeof window !== 'undefined' && window.crypto && window.crypto.getRandomValues) {
            const randomArray = new Uint32Array(1);
            window.crypto.getRandomValues(randomArray);
            const randomValue = randomArray[0] % 2;
            const heads = +randomValue === 0;
            if (heads) {
              headsArr.push(r)
            } else {
              tailsArr.push(r);
            }
            console.log('crypto')
          } else {
            const num = Math.round(Math.random() * 1000000).toString();
            const at = Math.round(Math.random() * num.length);
            const atInt = at > 0 ? at - 1 : at;
            const numAt = num.charAt(atInt);
            const heads = +numAt % 2 === 0;
            if (heads) {
              headsArr.push(r)
            } else {
              tailsArr.push(r);
            }
          }
        }
        const usedPartners = headsArr.length > 0 ? headsArr.join('') : tailsArr.join('');
        partners.push(usedPartners)
      }
      modPairing.partners = partners;

      if (typeof window !== 'undefined' && window.crypto && window.crypto.getRandomValues) {
        const coinFlipResults = [];

        for (let i = 0; i < 200; i++) {
          const randomArray = new Uint32Array(1);
          window.crypto.getRandomValues(randomArray);
          const randomValue = randomArray[0] % 2; // 0 or 1

          const coinSide = randomValue === 0 ? 'heads' : 'tails';
          coinFlipResults.push(coinSide);
        }
      } else {
        console.error("Your browser doesn't support the Web Crypto API.");
      }
    }

    if (pointsHandicapMethod > 1) {
      let low = -99;
      if (pointsHandicapMethod === 2) {
        for (const player of modPairing.players) {
          const { courseHandicap } = player;
          if (courseHandicap > low) {
            low = courseHandicap;
          }
        }
      }

      if (pointsHandicapMethod < 4) {
        for (const player of modPairing.players) {
          const { courseHandicap } = player;
          const pointsHandicap = pointsHandicapMethod === 2 ? player.courseHandicap - low : courseHandicap;
          player.pointsHandicap = Math.round(pointsHandicap * (pointsHandicapPercentage / 100));
        }
      }

      if (pointsHandicapMethod === 4) {
        for (let i = 0; i < this.manualHandicaps.length; i++) {
          const manualHandicap = this.manualHandicaps.at(i).value;
          const { id, handicap } = manualHandicap;
          const thePlayer = modPairing.players.find((p: any) => p.id === id);
          thePlayer.pointsHandicap = handicap * -1;
        }
      }
    }

    return modPairing;
  }

  initBoolArr(isTwist: boolean) {
    const bools = [];
    let isFirstTime = true;
    for (let i = 0; i < 18; i++) {
      if (isFirstTime && isTwist) {
        bools.push(true);
        isFirstTime = false;
      } else {
        bools.push(false);
      }
    }

    return bools;
  }

  calcSkinsHandicap(playerId: string, courseHandicap: number) {

    let skinsHandicap = 0;

    const { handicapMethod, skinsFormat, useMaxStrokes = false, maxHandicapStrokes = 99 } = this.event;

    if (skinsFormat > 1) {
      const { skinsHandicapPercentage } = this.event;
      if (handicapMethod === 1) {
        skinsHandicap = courseHandicap * -1;
      }
      if (handicapMethod === 2) {
        const { courseHandicap: lowCourseHandicap, memberId } = this.lowSkinsGuy;
        skinsHandicap = lowCourseHandicap - courseHandicap;
        if (memberId === playerId) {
          skinsHandicap = 0;
        }
      }
      skinsHandicap = skinsHandicap ? Math.round((skinsHandicap * skinsHandicapPercentage) / 100) : skinsHandicap;
    }

    if (useMaxStrokes && skinsHandicap > maxHandicapStrokes) {
      skinsHandicap = maxHandicapStrokes;
    }

    return skinsHandicap;
  }

  initProxs() {
    const proxs = [];
    for (let i = 0; i < 18; i++) {
      proxs.push('');
    }

    return proxs;
  }

  dismissModal(newPairing: any) {
    this.modalCtrl.dismiss({
      pairing: newPairing,
    });
  }

  eventHandicapSet() {
    const theTee = this.course.tees.find(((t: any) => t.id === this.event.tee));
    return theTee.handicapSet
  }

  playersAlreadyInAPairing() {
    return new Promise<any>(async (resolve, reject) => {
      const playerArray = [];
      const docs = await this.pairingService.getForEventMod(this.eventId)
      if (docs.length > 0) {
        for (const doc of docs) {
          const docData = doc.data();
          const { players } = docData;
          for (const player of players) {
            playerArray.push(player.id)
          }
        }
        resolve(playerArray)
      } else {
        resolve([])
      }
    })
  }

  populateCurrentMember() {
    const a = [];
    const user = this.currentUser;
    a.push(user);
    this.selectForm.patchValue({ selMembers: a });
    this.addMember(user);
  }

  async populateCurrentPlayers() {
    const selPlayers = [];
    const pairingData = await this.pairingService.getByDocIdMod(this.pairingId)
    this.pairing = pairingData;
    const { players, excludePlayersFromSkins } = pairingData;
    for (const player of players) {
      selPlayers.push(player.id);
      const tempMember = this.createTempMember(player)
      this.members.push(tempMember);
    }
    this.selectForm.patchValue({ selMembers: selPlayers, excludePlayersFromSkins });
    this.populatePointsInfo();
    this.isLoading = false;
  }

  createTempMember(player: any) {
    const { name, handicapIndex, displayHandicapIndex,
      courseHandicap, displayCourseHandicap, displaySkinsHandicap, skinsHandicap, scores, id, flight, includeInSkins, tee, gender } = player;
    const tempMember = this.fb.group({
      name: [name],
      handicapIndex: [handicapIndex],
      displayHandicapIndex: new FormControl(displayHandicapIndex, [
        Validators.pattern('[+]?[0-9]{0,2}[.]{1}[0-9]{1}'),
        Validators.required,
      ]),
      courseHandicap: [courseHandicap],
      displayCourseHandicap: [displayCourseHandicap],
      skinsHandicap: [skinsHandicap],
      displaySkinsHandicap: [displaySkinsHandicap],
      tee: new FormControl(tee),
      scores: [scores],
      gender: [gender],
      id: [id],
      flight: [flight],
      includeInSkins: new FormControl(includeInSkins),
    });

    return tempMember;

  }

  populateScores() {
    const scores = [];
    for (let i = 0; i < 18; i++) {
      const newHole = {
        gross: 0,
        net: 0,
        relationToParGross: 0,
        relationToParNet: 0,
        skinsNet: 0,
        pointsNet: 0,
        displayGross: '0',
        displayNet: '0',
        displayPoints: '0',
        isBob: false
      } as any;
      scores.push(newHole)
    }

    return scores;
  }


  populatePointsInfo() {
    if (this.pairing) {
      const { players, usePoints, pointsDescription, pointsAmount, team1, team2, pointsHandicapMethod, pointsHandicapPercentage } = this.pairing;
      const thePointsDescription = this.pointsDescriptions.find(p => p.value === pointsDescription);
      if (thePointsDescription) {
        const { teamSize, hasTeams } = thePointsDescription;
        this.pairingPlayers = hasTeams ? this.members.value : [];
        this.hasTeams = hasTeams;
        const validator = teamSize === 0 ? null : [Validators.required, this.pointsTeamSizeValidator.teamSizeValidator(teamSize)];
        this.team1FC.setValidators(validator);
        this.team2FC.setValidators(validator);
        this.team1FC.updateValueAndValidity();
        this.team2FC.updateValueAndValidity();
      }

      if (!this.hasTeams) {
        this.selectForm.patchValue({
          usePoints,
          pointsDescription,
          pointsAmount,
          pointsHandicapMethod,
          pointsHandicapPercentage,
        })
      } else {
        this.selectForm.patchValue({
          usePoints,
          pointsDescription,
          pointsAmount,
          team1,
          team2,
          pointsHandicapMethod,
          pointsHandicapPercentage,
        })
      }
      // load the manual array 
      if (pointsHandicapMethod === 4) {
        for (const player of players) {
          const { name, id, pointsHandicap } = player;
          const manualHandicap = this.fb.group({
            id: [id],
            name: [name],
            handicap: new FormControl([pointsHandicap], Validators.required),
          })
          this.manualHandicaps.push(manualHandicap)
        }
        for (let i = 0; i < this.members.length; i++) {
          this.manualHandicaps.at(i).get('name').disable();
        }
      }
    }
  }

  async getLowCourseHandicap() {
    const processedMembers = [];
    this.initLowSkinsGuy()

    let mLength = 0;
    mLength = this.members.length;
    for (let i = 0; i < mLength; i++) {
      const member = (this.selectForm.get('members') as FormArray).at(i).value;
      const { courseHandicap, id: memberId, handicapIndex, name } = member;
      if (courseHandicap > this.lowSkinsGuy.courseHandicap) {
        const lowSkinsGuy = {
          memberId,
          handicapIndex,
          name,
          courseHandicap,
          difference: 0,
        }
        this.lowSkinsGuy = lowSkinsGuy;
      }
      processedMembers.push(memberId);
    }

    const docs = await this.pairingService.getForEventMod(this.eventId);
    for (const doc of docs) {
      const pairingData = doc.data();
      const { players } = pairingData;
      for (const player of players) {
        const { id: memberId, handicapIndex, courseHandicap, name } = player;
        if ((courseHandicap > this.lowSkinsGuy.courseHandicap) && !(processedMembers.includes(memberId))) {
          this.setLowSkinsGuy(memberId, handicapIndex, name, courseHandicap);
          processedMembers.push(memberId)
        }
      }
    }

    const rsvpDocs = await this.rsvpService.getLowIndexRsvpMod(this.eventId, 99)
    for (const rsvpDoc of rsvpDocs) {
      const rsvpData: any = rsvpDoc.data();
      const { handicapIndex, member } = rsvpData;
      const { id: memberId, firstname, lastname } = member;
      const teeInfo = this.getTeeInfo();
      const { slope, rating, par } = teeInfo;
      const courseHandicap = Math.round(Number(handicapIndex) * (slope / 113) - (Number(rating) - par));
      if ((courseHandicap > this.lowSkinsGuy.courseHandicap) && !(processedMembers.includes(memberId))) {
        this.setLowSkinsGuy(memberId, handicapIndex, `${firstname} ${lastname}`, courseHandicap.toString())
      }
    }
  }

  setLowSkinsGuy(memberId: string, handicapIndex: number, name: string, courseHandicap: string) {
    const lowSkinsGuy = {
      memberId,
      handicapIndex,
      name,
      courseHandicap,
      difference: 0,
    }
    this.lowSkinsGuy = lowSkinsGuy;

  }

  initLowSkinsGuy() {
    this.lowSkinsGuy = {
      memberId: '',
      handicapIndex: -99,
      name: '',
      courseHandicap: -99,
      difference: 0,
    }
  }

  getTeeInfo() {
    let teeInfo;
    let par;
    const { tee } = this.event;
    const { tees, handicapSets } = this.course;
    const theTee = tees.find(t => t.id === tee);
    if (theTee) {
      const { handicapSet, slope, rating, } = theTee;
      const theHandicapSet = handicapSets.find(h => h.id === handicapSet);
      par = theHandicapSet ? theHandicapSet.par : 72;
      teeInfo = {
        slope,
        rating,
        par
      }
    }

    return teeInfo;

  }

  adjustSkinsHandicapForPairing(adjustmentAmt: number) {
    let i = 0;
    for (const player of this.members.value) {
      const newSkinsHandicap = player.skinsHandicap + adjustmentAmt;
      this.members.controls[i].get('skinsHandicap').patchValue(newSkinsHandicap);
      i++;
    }
  }

  excludePlayersFromSkinsChanged() {
    if (this.excludePlayersFromSkins == false) {
      let hasExcluded = false;
      const mLength = this.members.length;
      for (let i = 0; i < mLength; i++) {
        const member = (this.selectForm.get('members') as FormArray).at(i).value;
        const { includeInSkins } = member;
        if (!includeInSkins) {
          hasExcluded = true;
        }
      }
      if (hasExcluded) {
        this.selectForm.patchValue({ excludePlayersFromSkins: true });
        this.presentExcludedAlert();

      }
    }
  }

  async presentExcludedAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Player is excluded from skins',
      message: `You are attempting to change your option for excluding players from skins to false, but players is currently excluded from the skins game.  
        Please change all players to be included int he skins game and try again.`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Ok',
          handler: async () => { }
        },
      ]
    });

    await alert.present();
    alert.onDidDismiss()
  }

  async memberSelectionChanged() {
    if (!this.isLoading) {
      const promises = [];
      this.playersPlayingFromPreferredTees.length = 0;
      const mLength = this.members.length;
      for (let i = 0; i < mLength; i++) {
        (this.selectForm.get('members') as FormArray).removeAt(0);
      }

      const formMembers = this.selMembers.value;
      for (const memberId of formMembers) {
        const p = this.addMember(memberId);
        promises.push(p)
      }

      await Promise.all(promises)
      if (this.playersPlayingFromPreferredTees.length > 0) {
        this.presentPreferredTeeAlert()
      }
    }
  }

  async addMember(memberId: string) {
    const scoreArray = [];
    for (let i = 0; i < 18; i++) {
      if (!this.isUpdateMode) {
        scoreArray.push(this.initHole());
      } else {
        const thePlayer = this.pairing.players.find((p: any) => p.id === memberId);
        if (thePlayer != null || thePlayer !== undefined) {
          scoreArray.push(thePlayer.scores[i]);
        } else {
          scoreArray.push(this.initHole());
        }
      }
    }

    const memberData = await this.memberService.getMemberByDocIdMod(memberId);
    const { firstname, lastname, displayHandicapIndex: memDisplayHandicapIndex, handicapIndex: memHandicapIndex, id, gender = 'Male', preferredTees } = memberData;
    const handicapInfo = await this.determineHandicapIndex(id, memHandicapIndex, memDisplayHandicapIndex)
    const { retHandicapIndex: handicapIndex, retDisplayHandicapIndex: displayHandicapIndex } = handicapInfo;
    let memberTee = this.determineTee(preferredTees, memberData);
    const theTee = this.course.tees.find(((t: any) => t.id === memberTee));
    const { slope, rating } = theTee;
    const par = this.course.handicapSets.find(h => h.id = theTee.handicapSet).par
    const courseHandicap = calcCourseHandicap(handicapIndex, Number(slope), par, rating);
    const displayCourseHandicap = formatDisplayCourseHandicap(courseHandicap);
    const skinsHandicap = this.calcSkinsHandicap(id, courseHandicap);
    const absSkinsHandicap = Math.abs(skinsHandicap);
    const displaySkinsHandicap = skinsHandicap >= 0 ? skinsHandicap : `+${absSkinsHandicap}`;
    const concatName = `${firstname} ${lastname}`;
    const flight = this.findFlight(courseHandicap);
    const tempMember = this.fb.group({
      name: [concatName],
      handicapIndex: [handicapIndex],
      displayHandicapIndex: new FormControl(displayHandicapIndex, [
        Validators.pattern('[+]?[0-9]{0,2}[.]{1}[0-9]{1}'),
        Validators.required,
      ]),
      courseHandicap: [courseHandicap],
      displayCourseHandicap: [displayCourseHandicap],
      skinsHandicap: [skinsHandicap],
      displaySkinsHandicap: [displaySkinsHandicap],
      tee: [memberTee],
      scores: [scoreArray],
      id: [memberId],
      flight: [flight],
      includeInSkins: new FormControl(true, Validators.required),
      gender: [gender],
    });
    this.members.push(tempMember);
    const sorted = this.members.value.sort((a, b) => (a.name > b.name) ? 1 : -1);
    this.selectForm.patchValue({ members: sorted });
  }

  determineTee(preferredTees: any, member: any) {
    let usedTee = this.event.tee;
    if (this.teesMatch && preferredTees && preferredTees.length > 0) {
      const thePreferredTee = preferredTees.find(t => t.group === this.event.groups[0]);
      if (thePreferredTee) {
        usedTee = thePreferredTee.preferredTee;
        const { firstname, lastname } = member;
        this.playersPlayingFromPreferredTees.push(`${firstname} ${lastname}`);
      }
    }

    return usedTee;
  }

  usePointsChanged() {
    const validators = this.usePoints ? [Validators.required] : null;
    this.pointsHandicapMethodFC.setValidators(Validators.required);
    this.pointsHandicapMethodFC.updateValueAndValidity();
    this.pointsAmountFC.setValidators(validators);
    this.pointsAmountFC.updateValueAndValidity();
    this.pointsDescriptionFC.setValidators(validators);
    this.pointsDescriptionFC.updateValueAndValidity();

    if (!this.usePoints) {
      this.team1FC.setValidators(null);
      this.team2FC.setValidators(null);
      this.team1FC.updateValueAndValidity();
      this.team2FC.updateValueAndValidity();
      this.pointsHandicapMethodFC.setValidators(null);
      this.pointsHandicapMethodFC.updateValueAndValidity();
      this.pointsHandicapPercentageFC.setValidators(null);
      this.pointsHandicapPercentageFC.updateValueAndValidity();

      this.selectForm.patchValue({
        pointsDescription: null,
        pointsHandicapMethod: null,
        pointsHandicapPercentage: null,
        team1: [],
        team2: [],
      })
      this.hasTeams = false;
    }
  }

  pointsHandicapMethodChanged() {
    if (this.pointsHandicapMethod === 4) {
      for (let i = 0; i < this.members.length; i++) {
        const member = this.members.at(i);
        const { id, name } = member.value;
        const manualHandicap = this.fb.group({
          id: [id],
          name: [name],
          handicap: new FormControl(0, Validators.required),
        })
        this.manualHandicaps.push(manualHandicap)
      }
      for (let i = 0; i < this.members.length; i++) {
        this.manualHandicaps.at(i).get('name').disable();
      }
    }
    if (this.pointsHandicapMethod < 4) {
      const len = (this.manualHandicaps as FormArray).length;
      for (let i = 0; i < len; i++) {
        (this.manualHandicaps as FormArray).removeAt(0);
      }
    }
  }

  async teeSelectionChanged(index: number) {
    const { tee, handicapIndex, id: playerId } = this.members.value[index];
    const playerTee = this.course.tees.find(((t: any) => t.id === tee));
    const par = this.course.handicapSets.find(h => h.id = playerTee.handicapSet).par
    const { slope, rating } = playerTee;
    const newCourseHandicap = calcCourseHandicap(handicapIndex, slope, par, rating)
    const newSkinsHandicap = this.calcSkinsHandicap(playerId, newCourseHandicap);
    const absNewSkinsHandicap = Math.abs(newSkinsHandicap);
    const displaySkinsHandicap = newSkinsHandicap >= 0 ? newSkinsHandicap : `+${absNewSkinsHandicap}`;
    this.members.controls[index].get('courseHandicap').patchValue(newCourseHandicap);
    this.members.controls[index].get('displayCourseHandicap').patchValue(formatDisplayCourseHandicap(newCourseHandicap));
    this.members.controls[index].get('skinsHandicap').patchValue(newSkinsHandicap);
    this.members.controls[index].get('displaySkinsHandicap').patchValue(displaySkinsHandicap);

    const { memberId: lowGuyId } = this.lowSkinsGuy;
    let theLowCourseHandicap = this.lowSkinsGuy.courseHandicap
    if (!!this.formLowGuy) {
      theLowCourseHandicap = this.formLowGuy.courseHandicap;
    }
    if (newCourseHandicap > theLowCourseHandicap) {
      const difference = newCourseHandicap - theLowCourseHandicap;
      const lowSkinsGuy = {
        memberId: playerId,
        handicapIndex,
        name: this.members.controls[index].get('name').value,
        courseHandicap: newCourseHandicap,
        difference,
      }
      this.lowSkinsGuy = lowSkinsGuy;
      this.formLowGuy = this.lowSkinsGuy;
      this.newLowSkinsGuy = undefined;
      this.updateOtherPlayersInPairing(playerId);
    }
    // changed to a tee that makes no longer the low guy;
    if (newCourseHandicap < theLowCourseHandicap && playerId === lowGuyId) {
      await this.getLowCourseHandicap();
      const { memberId, courseHandicap: lgCourseHandicap } = this.lowSkinsGuy;
      const difference = newCourseHandicap - lgCourseHandicap;
      this.lowSkinsGuy.difference = difference;
      this.updateOtherPlayersInPairing(memberId);
      this.formLowGuy = undefined;
      this.newLowSkinsGuy = this.lowSkinsGuy;
      const isInGroup = this.checkIfLowGuyIsInThisGroup();
      if (isInGroup) {
        this.formLowGuy = this.lowSkinsGuy;
      }
    }
  }

  updateOtherPlayersInPairing(playerId: string) {

    for (let i = 0; i < this.members.length; i++) {
      const member = this.members.at(i);
      const { courseHandicap, id } = member.value;

      let newSkinsHandicap = this.calcSkinsHandicap(id, courseHandicap);
      if (id === playerId) {
        newSkinsHandicap = 0;
      }
      const absNewSkinsHandicap = Math.abs(newSkinsHandicap);
      const newDisplaySkinsHandicap = newSkinsHandicap >= 0 ? newSkinsHandicap : `+${absNewSkinsHandicap}`;

      this.members.controls[i].get('skinsHandicap').patchValue(newSkinsHandicap);
      this.members.controls[i].get('displaySkinsHandicap').patchValue(newDisplaySkinsHandicap);
    }
  }

  checkIfLowGuyIsInThisGroup() {
    let isInGroup = false;
    const { lgMemberId } = this.lowSkinsGuy;

    let mLength = 0;
    mLength = this.members.length;
    for (let i = 0; i < mLength; i++) {
      const member = (this.selectForm.get('members') as FormArray).at(i).value;
      const { id: memberId } = member;
      if (lgMemberId === memberId) {
        isInGroup = true;
      }
    }

    return isInGroup;
  }

  pointsDescriptionChanged() {
    const thePointsDescription = this.pointsDescriptions.find(p => p.value === this.pointsDescription);
    if (thePointsDescription) {
      const { teamSize, hasTeams } = thePointsDescription;
      this.pairingPlayers = hasTeams ? this.selectForm.value.members : [];
      this.hasTeams = hasTeams;
      if (!hasTeams) {
        this.selectForm.patchValue({
          team1: [],
          team2: [],
        })
      }
      const validator = teamSize === 0 ? null : [Validators.required, this.pointsTeamSizeValidator.teamSizeValidator(teamSize)];

      this.team1FC.setValidators(validator);
      this.team2FC.setValidators(validator);
      this.team1FC.updateValueAndValidity();
      this.team2FC.updateValueAndValidity();
    }
  }

  async determineHandicapIndex(memberId: string, index: number, displayIndex: string) {
    let retHandicapIndex = index;
    let retDisplayHandicapIndex = displayIndex;
    const { handicapAsOfDate, useHandicapAsOfDate } = this.event;

    if (useHandicapAsOfDate) {
      const asOfDate = new Date(handicapAsOfDate).toISOString();
      const handicapHistoryDocs = await this.handicapHistoryService.getForMemberIdAndDateMod(memberId, asOfDate)
      if (handicapHistoryDocs.length > 0) {
        const handicapHistory: any = handicapHistoryDocs[0].data();
        const { handicapIndex, displayHandicapIndex } = handicapHistory;
        retHandicapIndex = handicapIndex;
        retDisplayHandicapIndex = displayHandicapIndex;
      }
    }

    const retObj = {
      retHandicapIndex,
      retDisplayHandicapIndex,
    } as any;

    return retObj
  }

  initHole() {

    return {
      gross: 0,
      net: 0,
      relationToParGross: 0,
      relationToParNet: 0,
      skinsNet: 0,
      displayGross: 0,
      displayNet: 0,
      pointsNet: 0,
      displayPoints: 0,
      stablefordGross: 0,
      stablefordNet: 0,
      isBoB: false,
    }
  }

  findFlight(courseHandicap: number) {
    let retFlight = '999';

    if (this.event.skinsUseFlights) {
      const arr = this.event.skinsFlightDistributionOption === 1 ? this.eventFlightBreaks.flights : this.event.skinsFlights;
      retFlight = this.arrFindFlight(arr, courseHandicap);
    }

    return retFlight;
  }

  arrFindFlight(flights: any[], courseHandicap: number) {

    const convertedFlights = this.convertFlights(flights);
    // course handicap is positive for plus hanicap negative for chops
    const theFlight = convertedFlights.find((f: any) => courseHandicap <= f.bottom && courseHandicap >= f.top)

    return theFlight ? theFlight.name : '999';
  }

  convertFlights(flights: any[]) {
    const newFlights: any[] = [];
    for (let flight of flights) {
      let newFlight;
      const { skinsFlightDistributionOption } = this.event;
      if (skinsFlightDistributionOption === 1) {
        const { name, start, end } = flight;
        newFlight = {
          name,
          bottom: start,
          top: end,
        }
      }
      if (skinsFlightDistributionOption === 2) {
        const { name, bottom, top } = flight;
        const newBottom = bottom ? bottom.toString().includes('+') ? Number(bottom.slice(1)) : bottom * -1 : 100;
        const newTop = top ? top.toString().includes('+') ? Number(top.slice(1)) : top * -1 : -100;
        newFlight = {
          name,
          bottom: newBottom,
          top: newTop
        }
      }

      newFlights.push(newFlight);
    }

    return newFlights
  }

  cancel() {
    this.modalCtrl.dismiss(
      {
        pairing: null,
        warning: 0,
      }
    );
  }

  get selMembers() {
    return this.selectForm.get('selMembers');
  }

  get members() {
    return this.selectForm.get('members') as FormArray;
  }

  get manualHandicaps() {
    return this.selectForm.get('manualHandicaps') as FormArray;
  }

  get usePoints() {
    return this.selectForm.get('usePoints').value;
  }

  get pointsAmount() {
    return this.selectForm.get('pointsAmount').value;
  }

  get pointsAmountFC() {
    return this.selectForm.get('pointsAmount');
  }

  get pointsDescription() {
    return this.selectForm.get('pointsDescription').value;
  }

  get pointsDescriptionFC() {
    return this.selectForm.get('pointsDescription');
  }

  get team1FC() {
    return this.selectForm.get('team1');
  }

  get team2FC() {
    return this.selectForm.get('team2');
  }

  get pointsHandicapMethod() {
    return this.selectForm.get('pointsHandicapMethod').value;
  }

  get excludePlayersFromSkins() {
    return this.selectForm.get('excludePlayersFromSkins').value;
  }

  get pointsHandicapMethodFC() {
    return this.selectForm.get('pointsHandicapMethod');
  }

  get pointsHandicapPercentageFC() {
    return this.selectForm.get('pointsHandicapPercentage');
  }
}
