// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note {
  text-indent: 15 px;
  display: inline-block;
  font-weight: bold;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/add-press-modal/add-press-modal.page.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,qBAAA;EACA,iBAAA;EACA,YAAA;AAAJ","sourcesContent":[".note\n{\n    text-indent: 15 px;\n    display: inline-block;\n    font-weight: bold;\n    color: black\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
