import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Gesture, GestureController, IonItem, ModalController, NavParams, AlertController } from '@ionic/angular';
import { RsvpService } from '../services/rsvp.service';
import { Subscription } from 'rxjs';
import { StagedPairingService } from '../services/staged-pairing.service';
import { EventService } from '../services/event.service';
import { AuthService } from '../services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-build-pairings-modal',
  templateUrl: './build-pairings-modal.page.html',
  styleUrls: ['./build-pairings-modal.page.scss'],
})
export class BuildPairingsModalPage implements OnInit, AfterViewInit {
  eventId: string;
  rsvps = [];
  currentArray = [];
  stagedPairings = [];
  currentUser = '';
  subscription: Subscription = new Subscription;
  times = [];
  teamRed = [];
  myArray = Array.from(Array(30).keys());
  contentScrollActive = true;

  // Keep track of all added gestures
  gestureArray: Gesture[] = [];

  // Access the dropzones
  @ViewChild('dropzoneA') dropA: ElementRef;

  // List of all items
  @ViewChildren(IonItem, { read: ElementRef }) items: QueryList<ElementRef>;

  constructor(
    private navParams: NavParams,
    private authService: AuthService,
    private eventService: EventService,
    private rsvpService: RsvpService,
    private stagedPairingService: StagedPairingService,
    private alertCtrl: AlertController,
    private gestureCtrl: GestureController,
    private modalCtrl: ModalController,
    private changeDetectorRef: ChangeDetectorRef,
    private db: AngularFirestore,
  ) { }

  async ngOnInit() {
    this.currentUser = await this.authService.currentUser();
    this.getNavParams();

    await this.getStagedPairings();
    await this.buildTimes();
    await this.getRsvps();
  }

  ngAfterViewInit() {
    this.updateGestures();
  }

  ionViewWillClose() {
    this.subscription.unsubscribe();
  }

  getNavParams() {
    this.eventId = this.navParams.get('eventId');
  }

  async buildTimes() {
    const eventData = await this.eventService.getByDocIdMod(this.eventId);
    const { startingTime, numberOfTimes, teeTimeIncrement } = eventData;
    const times = [];
    times.push(startingTime);
    let prevTime = startingTime;
    for (let i = 1; i < numberOfTimes; i++) {
      const nextTime = this.getNextTime(prevTime, teeTimeIncrement);
      times.push(nextTime);
      prevTime = nextTime;
    }
    this.times = times;
  }

  getStagedPairings() {
    return new Promise<any>((resolve, reject) => {
      const subscription = this.stagedPairingService.getForEventAsOBS(this.eventId)
        .valueChanges()
        .subscribe(res => {
          this.stagedPairings = res;
          this.filterRsvps(this.rsvps);
          resolve(null);
        })
      this.subscription.add(subscription);
    })
  }

  async getRsvps() {
    const rsvps = [];
    const docs = await this.rsvpService.getRsvpThatArePlayingMod(this.eventId);
    for (const doc of docs) {
      const data = doc.data();
      rsvps.push(data);
    }
    if (this.stagedPairings.length > 0) {
      this.filterRsvps(rsvps);
    } else {
      this.rsvps = rsvps;
    }
  }

  filterRsvps(rsvps) {
    const arr = [];
    this.stagedPairings.forEach((r: any) => {
      arr.push(r.playerArray)
    })
    const flatArr = arr.reduce((acc: any, curVal: any) => {
      return acc.concat(curVal)
    }, []);
    const filteredRsvps = rsvps.filter(r => !flatArr.includes(r.memberAuthId));
    this.rsvps = filteredRsvps;
  }

  resetRsvp(currentIndex: number) {
    const r = this.currentArray[currentIndex];
    this.rsvps.push(r);
    this.currentArray.splice(currentIndex, 1);
  }

  async addNew() {
    const id = this.stagedPairingService.getId();
    const playerArray = [];
    const players = [];
    const createdTS = this.stagedPairingService.getTimeStamp();
    const updateOpId = this.currentUser;
    for (const p of this.currentArray) {
      const { memberAuthId, firstname, lastname } = p;
      const name = `${firstname} ${lastname}`;
      const newPlayer = {
        id: memberAuthId,
        name,
        tee: '',
        gender: 'Male',
      }
      players.push(newPlayer);
      playerArray.push(memberAuthId);
    }

    const newStagedPairing = {
      id,
      playerArray,
      players,
      eventId: this.eventId,
      createdTS,
      updateOpId,
    }

    await this.stagedPairingService.createPairing(newStagedPairing);

    this.currentArray.length = 0;
  }

  saveGroups() {
    const batch = this.db.firestore.batch();
    let i = 0;
    for (const sp of this.stagedPairings) {
      const { id: spId } = sp;
      const theTime = this.times[i];
      const spXref = this.db.firestore.collection('staged-pairing').doc(spId);
      batch.update(spXref,
        {
          'time': theTime,
        })
      i++
    }

    batch.commit()
      .then(() => this.modalCtrl.dismiss())
      .catch(error => console.log({ error }))
  }

  getNextTime(currentTime: string, teeTimeIncrement: number) {
    if (currentTime.length === 5) {
      var hour = +currentTime.substr(0, 2);
      var minutes = +currentTime.substr(3, 2);
    } else {
      var hour = +currentTime.substr(0, 1);
      var minutes = +currentTime.substr(2, 2);
    }

    if ((minutes + teeTimeIncrement) >= 60) {
      const a = 60 - minutes;
      minutes = teeTimeIncrement - a;
      if (hour !== 12) {
        hour = hour + 1;
      } else {
        hour = 1;
      }
    } else {
      minutes = minutes + teeTimeIncrement;
    }

    if (minutes < 10) {
      return `${String(hour)}:0${String(minutes)}`;
    } else {
      return `${String(hour)}:${String(minutes)}`;
    }
  }

  reorderItems(event) {
    const itemMove = this.stagedPairings.splice(event.detail.from, 1)[0];
    this.stagedPairings.splice(event.detail.to, 0, itemMove);
    event.detail.complete();
  }

  time(i: number) {
    return this.times[i];
  }

  concatNames(stagedPairing: any) {
    let concatName = '';
    const { players } = stagedPairing;

    let i = 0;
    for (const player of players) {
      const { name } = player;
      const lastname = this.extractName(name, false);
      const str = i === 0 ? lastname : `, ${lastname}`;
      concatName = concatName + str;
      i++
    }

    return concatName
  }

  extractName(fullname: string, isFirst: boolean) {
    const nameParts = fullname.split(' ');
    // isFirst get the first name else get lastname;
    const arrayIndex = isFirst ? 0 : 1;

    return nameParts[arrayIndex];
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
  // Remove and add gestures based on ViewChildren Querylist
  updateGestures() {
    this.gestureArray.map(gesture => gesture.destroy());
    this.gestureArray = [];

    // The array of ViewChildren
    const arr = this.items.toArray().filter(item => {
      return item.nativeElement.classList.contains('useMe')
    });
    for (let i = 0; i < arr.length; i++) {
      const oneItem = arr[i];
      const drag = this.gestureCtrl.create({
        el: oneItem.nativeElement,
        threshold: 1,
        gestureName: 'drag',
        onStart: ev => {
          oneItem.nativeElement.style.transition = '';
          oneItem.nativeElement.style.opacity = '0.8';
          oneItem.nativeElement.style.fontWeight = 'bold';
          // Lock the scroll behaviour of the content
          this.contentScrollActive = false;
          this.changeDetectorRef.detectChanges();
        },
        onMove: ev => {
          // Move the item with the drag move above all other items
          oneItem.nativeElement.style.transform = `translate(${ev.deltaX}px, ${ev.deltaY}px)`;
          oneItem.nativeElement.style.zIndex = 10;
          this.checkDropzoneHover(ev.currentX, ev.currentY);
        },
        onEnd: ev => {
          this.contentScrollActive = true;
          this.handleDrop(oneItem, ev.currentX, ev.currentY, i);
        }
      });
      drag.enable(true);
      this.gestureArray.push(drag);
    }

    this.items.changes.subscribe(res => {
      // Only update the gesture array if we removed an item
      // console.log('lengths', this.gestureArray.length, this.items.length)
      //if (this.gestureArray.length != this.items.length) {
        this.updateGestures();
     // }
    });
  }

  async moveToBox(s: any) {
    if (this.currentArray.length === 0) {
      const { id, playerArray } = s;
    for (const player of playerArray) {
      const docs = await this.rsvpService.getRsvpForEventAndOwnerMod(this.eventId, player);
      for (const doc of docs) {
        const rsvpData = doc.data();
        this.currentArray.push(rsvpData);
      }
    }
    this.stagedPairingService.delete(id);
    } else {
      this.presentPairingInProgressAlert()
    }  
  }

  checkDropzoneHover(x, y) {
    const dropA = this.dropA.nativeElement.getBoundingClientRect();

    if (this.isInZone(x, y, dropA)) {
      this.dropA.nativeElement.style.backgroundColor = '#5260ff';
    }
  }

  // Check if coordinates are within a dropzone rect
  isInZone(x, y, dropzone) {
    if (x < dropzone.left || x >= dropzone.right) {
      return false;
    }

    if (y < dropzone.top || y >= dropzone.bottom) {
      return false;
    }

    return true;
  }

  // Decide what to do with dropped item
  handleDrop(item, endX, endY, index) {
    const dropA = this.dropA.nativeElement.getBoundingClientRect();

    if (this.isInZone(endX, endY, dropA)) {
      // Dropped in Zone A
      item.nativeElement.remove();
      this.currentArray.push(this.rsvps[index]);
    } else {
      // Don't drop the item into a zone, simply bring it back to the initial position
      item.nativeElement.style.transition = '.2s ease-out';
      item.nativeElement.style.zIndex = 'inherit';
      item.nativeElement.style.transform = `translate(0, 0)`;
      item.nativeElement.style.opacity = '1';
      item.nativeElement.style.fontWeight = 'normal';
    }

    this.dropA.nativeElement.style.backgroundColor = 'white';

    this.changeDetectorRef.detectChanges();
  }

 
  name(i) {
    const { firstname, lastname } = this.currentArray[i];

    return `${firstname} ${lastname}`;
  }

  async presentPairingInProgressAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Pairing in progress',
      message: 'You have a pairing in progress. Please complete the current pairing or remove current players before attemping to process the current request',
      buttons: ['OK'],
      cssClass: 'custom-alert'
    });

    await alert.present();
    await alert.onDidDismiss();
  }
}

