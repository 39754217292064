import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, orderBy, getDocs, getFirestore, query } from 'firebase/firestore';


@Injectable()
export class BetTemplateService extends BaseService {

  node = 'bet-template';

  createBetTemplate(data: any) {
    return super.create(this.node, data);
  }

  getAll() {
    return this.afs.collection(this.node, ref =>
      ref.orderBy('description', 'asc'));
  }

  async getAllMod() {
    const db = getFirestore(); 
    const q = query(
      collection(db, this.node),
      orderBy('description', 'asc')
    )
    return (await getDocs(q)).docs;
  }

}