import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { getMessaging, onMessage } from 'firebase/messaging';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  showSplitPane = true;

  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'User Preferences',
      url: '/member',
      icon: 'options'
    },
    {
      title: 'New Bet',
      url: '/bet',
      icon: 'cash'
    },
    {
      title: 'New Event',
      url: '/event',
      icon: 'calendar'
    },
    {
      title: 'New Group',
      url: '/group',
      icon: 'people'
    },
    {
      title: 'Open Events',
      url: '/open-event',
      icon: 'lock-open'
    },
    {
      title: 'Daily Summary',
      url: '/daily-summary',
      icon: 'list'
    },
    {
      title: 'Your Stats',
      url: '/individual-stats',
      icon: 'stats-chart'
    },
    {
      title: 'Group Stats',
      url: '/group-stats',
      icon: 'stats-chart'
    },
    {
      title: 'Info',
      url: '/app-info',
      icon: 'information-circle'
    },
  ];
  constructor(
    private platform: Platform,
    private router: Router,
  ) {
    console.log('constructor')
    this.initializeApp();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkSplitPaneRoutes(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.listen();
    }, 2000)
  }

  ionViewWillEnter() {
    this.listen();
  } 

  initializeApp() {
    this.platform.ready().then(() => {
    });
  }

  checkSplitPaneRoutes(url: string) {
    // List the routes or components where you want to show the split-pane
    const splitPaneRoutes = ['/live-scoring', '/tabs/tab1'];

    // Check if the current route matches any of the routes where the split-pane should be shown
    this.showSplitPane = splitPaneRoutes.some(route => url.startsWith(route));
  }

  listen() {
    const messaging = getMessaging();
    console.log('listening')
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: '/firebase-logo.png'
      };

      new Notification(notificationTitle, notificationOptions);
    });
  }
}
