import { ValidatorFn, AbstractControl } from '@angular/forms';

export class CheckboxCheckedValidator {

    static minSelectedCheckboxes(min) {
        return (control: AbstractControl): { [key: string]: boolean } | null => { 
            if (control && control.value && control.value.length < min) {
                return { tooFewSelected: true };
            }
        };
    }
}
