import { RsvpService } from './../services/rsvp.service';
import { Router } from '@angular/router';
import { WorkerService } from './../services/worker-service';
import { EventSummaryWarningService } from './../services/event-summary-warning.service';
import { AuthService } from './../services/auth.service';
import { EventSummaryTransactionService } from './../services/event-summary-transaction.service';
import { EventSummaryTotalService } from './../services/event-summary-total.service';
import { NavParams, ModalController, LoadingController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { EventSummaryAdjustmentService } from '../services/event-summary-adjustment.service';

@Component({
  selector: 'app-event-results-modal',
  templateUrl: './event-results-modal.page.html',
  styleUrls: ['./event-results-modal.page.scss'],
})
export class EventResultsModalPage {

  constructor(
    private authService: AuthService,
    private navParams: NavParams,
    private eventSummaryAdjustmentService: EventSummaryAdjustmentService,
    private eventSummaryTotalService: EventSummaryTotalService,
    private eventSummaryTransactionService: EventSummaryTransactionService,
    private eventSummaryWarningService: EventSummaryWarningService,
    private rsvpService: RsvpService,
    private workerService: WorkerService,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private router: Router,

  ) { }


  subscription = new Subscription; 
  eventId;
  currentUser;
  multipleRsvps = false;
  eventSummaryTotals = [];
  showTransactionDetails = [];
  eventSummaryTransactions = [];
  eventSummaryWarnings = [];
  eventSummaryAdjustments = [];
  firstTime = true;
  loader;
  adjLoader;
  isLoading = false;

  async ionViewWillEnter() {
    console.log('fuck off')
    this.subscription = new (Subscription)();
    this.currentUser = await this.authService.currentUser();
    this.eventId = this.navParams.get('eventId');
    this.getSummaryWarnings();
    this.getSummaryTotals();
    this.getSummaryAdjustments();
    this.getYourEvents() 
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  async getSummaryTotals() {
    this.loader = await this.loadingCtrl.create({
      message: 'Loading Event Summary',
      translucent: true,
      spinner: 'bubbles',
      duration: 5000
    });
    await this.loader.present();
    const subscription = this.eventSummaryTotalService.getEventSummaryTotalForEventId(this.eventId)
      .valueChanges()
      .subscribe(res => {
        this.showTransactionDetails = [];
        this.eventSummaryTotals = res;
        if (this.firstTime = true) {
          this.showCurrentUserTrans();
          this.firstTime = false;
        }
        this.loader.dismiss();
      })
    this.subscription.add(subscription);
  }

  getSummaryWarnings() {
    const subscription = this.eventSummaryWarningService.getEventSummaryWarningsForEventId(this.eventId)
      .valueChanges()
      .subscribe( res => {
        this.eventSummaryWarnings = res;
      })
    this.subscription.add(subscription);
  }

  getSummaryAdjustments() { 
    const subscription = this.eventSummaryAdjustmentService.getEventSummaryAdjustmentForEventId(this.eventId)
      .valueChanges()
      .subscribe( async res => {
        this.eventSummaryAdjustments = res;
        console.log({res})
        if (res.length > 0) {
          this.isLoading = true;
          this.adjLoader = await this.loadingCtrl.create({
            message: 'ReLoading Event Summary',
            translucent: true,
            spinner: 'bubbles',
            duration: 10000
          });
          await this.adjLoader.present();
        }
        if (res.length === 0 && this.adjLoader != undefined) {
          this.adjLoader.dismiss();
        }
      })
    this.subscription.add(subscription);
  }

  toggleTransactionDetail(eventSummaryTotal) {
    const { eventId, memberId } = eventSummaryTotal;
    if (!this.showTransactionDetails.includes(memberId)) {
      this.showTransactionDetails.push(memberId)
      const subscription = this.eventSummaryTransactionService.getEventSummaryTotalForEventIdAndMemberId(eventId, memberId)
        .valueChanges()
        .subscribe(res => {
          this.eventSummaryTransactions = res;
        })
      this.subscription.add(subscription);
    } else {
      this.hideDetail(memberId);
    }
  }

  hideDetail(memberId: string) {
    const theIndex = this.showTransactionDetails.findIndex(t => t === memberId);;
    if (theIndex !== -1) {
      this.showTransactionDetails.splice(theIndex, 1);
    }

    const filteredArray = this.eventSummaryTransactions.filter(f => f.memberId !== memberId)
    this.eventSummaryTransactions = filteredArray;
  }

  showTransactionDetail(eventSummaryTotal) {
    const { eventId, memberId } = eventSummaryTotal;
    console.log({eventId})
    if (!this.showTransactionDetails.includes(memberId)) {
      this.showTransactionDetails.push(memberId)
      const subscription = this.eventSummaryTransactionService.getEventSummaryTotalForEventIdAndMemberId(eventId, memberId)
        .valueChanges()
        .subscribe(res => {
          console.log({res})
          this.eventSummaryTransactions = [];
          res.forEach(eventSummaryTransaction => {
            this.eventSummaryTransactions.push(eventSummaryTransaction); 
          })
        })
      this.subscription.add(subscription);
    }
  }

  async showCurrentUserTrans() {
    this.showTransactionDetails.push(this.currentUser)
    this.eventSummaryTransactions = [];
    const estSnapshots = await this.eventSummaryTransactionService.getEventSummaryTotalForEventIdAndMemberId(this.eventId, this.currentUser)
      .get()
      .toPromise()
    estSnapshots.forEach( estSnapshot => {
      const estData = estSnapshot.data();
      this.eventSummaryTransactions.push(estData);
    })
  }

  getYourEvents() {
    const formattedDate = this.getFormattedCurrentDate();    
    const subscription = this.rsvpService.getRsvpListForUserWithCurrentDate(this.currentUser, formattedDate)
      .valueChanges()
      .subscribe( rsvps => {
        if (rsvps.length > 1) {
          this.multipleRsvps = true;
        }
      })
    this.subscription.add(subscription);
  }

  transactionDescription(eventSummaryTransaction: any) {
    const { type, desc, additionalDesc } = eventSummaryTransaction;
    let description = desc;
    if (type === '3' || type === '4' || type === '5') {
      description = `${desc} - ${additionalDesc}`
    }

    return description;
  }

  generateSummaryTotals() {
    const newWorker = {} as any;
    newWorker.id = this.workerService.getId();
    newWorker.type = '9';
    newWorker.eventId = this.eventId;
    this.workerService.createEvent(newWorker)
      .then(async () => {
        this.loader = await this.loadingCtrl.create({
          message: 'Loading Event Summary',
          translucent: true,
          spinner: 'bubbles',
          duration: 10000
        });
        await this.loader.present();
      })
      .catch();

  }

  showSummaryTransactions(memberId: string) {

    return this.showTransactionDetails.includes(memberId)
  }

  showSummaryTransaction(memberId: string, evsTransactionMemberId) {
    return memberId === evsTransactionMemberId;
  }

  dailyTotals() {
    this.modalCtrl.dismiss();
    this.router.navigate(['/daily-summary']);

  }

  getFormattedCurrentDate() {
    const today = new (Date)();
    const year = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = today.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }

    const stringDate = '' + year + month + day;
    return stringDate;
  }

  return() {
    this.modalCtrl.dismiss();
  }

  est() {

    return this.eventSummaryTotals ? this.eventSummaryTotals.length : 0;
  }

  total() {
    return this.eventSummaryTotals.reduce((a, b) => a + b.total, 0)
  }
}