import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService extends BaseService {

  node = 'error';

  logError(data: any) {
    return super.create(this.node, data);
  }

}
