// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-grid {
  display: inline-block;
  width: 20%;
}

.second-grid {
  display: inline-block;
  width: 80%;
}

.fixed-box {
  width: 100%;
  background: #fff;
  height: 200px;
  z-index: 10;
}

.drop-area {
  border: 2px dashed var(--ion-color-tertiary);
  margin: 7px;
  padding: 7px;
  width: 98%;
  align-items: center;
  height: 150px;
  justify-content: center;
  -webkit-user-select: none;
          user-select: none;
  background: #fff;
}

.item-group {
  margin-top: 200px;
}

.useMe {
  top: 0;
}

.bottom-right {
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.custom-button {
  height: 12px;
  min-height: 12px;
  max-height: 12px;
  line-height: 12px;
  padding: 0;
}

.custom-icon {
  height: 12px;
  min-height: 12px;
  max-height: 12px;
  line-height: 12px;
  padding: 0;
  font-size: 125%;
}`, "",{"version":3,"sources":["webpack://./src/app/build-pairings-modal/build-pairings-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,UAAA;AACJ;;AAEA;EACI,qBAAA;EACA,UAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;AACJ;;AAEA;EACI,4CAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;EACA,aAAA;EAEA,uBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,MAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,WAAA;EACA,UAAA;AAAJ;;AAGA;EACI,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,UAAA;AAAJ;;AAGE;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,UAAA;EACA,eAAA;AAAJ","sourcesContent":[".first-grid {\n    display: inline-block;\n    width: 20%;\n}\n\n.second-grid {\n    display: inline-block;\n    width: 80%;\n}\n\n.fixed-box {\n    width: 100%;\n    background: #fff;\n    height: 200px;\n    z-index: 10;\n}\n\n.drop-area {\n    border: 2px dashed var(--ion-color-tertiary);\n    margin: 7px;\n    padding: 7px;\n    width: 98%;\n    align-items: center;\n    height: 150px;\n   \n    justify-content: center;\n    user-select: none;\n    background: #fff;\n}\n\n.item-group {\n    margin-top: 200px;\n}\n\n.useMe {\n    top: 0;\n}\n\n.bottom-right {\n    position: absolute; \n    bottom: 6px; \n    right: 6px\n}\n\n.custom-button {\n    height: 12px;\n    min-height: 12px;\n    max-height: 12px;\n    line-height: 12px;\n    padding: 0;\n  }\n\n  .custom-icon {\n    height: 12px;\n    min-height: 12px;\n    max-height: 12px;\n    line-height: 12px;\n    padding: 0;\n    font-size: 125%;\n\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
