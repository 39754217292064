import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PairingDeleteEventService extends BaseService {

  node = 'pairing-delete-event';

  getForPairing(pairingId: string) {
    console.log({pairingId})
    return this.afs.collection(this.node, ref => ref
      .where('pairingId', '==', pairingId)
      .limit(1),
    )
  }
}