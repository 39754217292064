// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rotate {
  writing-mode: vertical-rl;
  height: 9em;
  text-align: right;
  border: none;
  padding-bottom: 10px;
  font-weight: bold;
  font-family: "Arial";
  background-color: #5260ff;
  color: white;
}

.center {
  text-align: center;
}

.bottom {
  vertical-align: right;
}

.border {
  border-bottom: 2px solid #5260ff;
}

table {
  width: 100%;
}

td {
  text-align: center;
}

.padding {
  padding-left: 1em;
  padding-right: 1em;
}

.larger {
  font-size: x-large;
}

.primary {
  color: #5260ff;
}`, "",{"version":3,"sources":["webpack://./src/app/flip-wolf-modal/flip-wolf-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EACA,oBAAA;EACA,iBAAA;EACA,oBAAA;EACA,yBAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,gCAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,cAAA;AACJ","sourcesContent":[".rotate {\n    writing-mode: vertical-rl;\n    height: 9em;\n    text-align: right;\n    border: none;\n    padding-bottom: 10px;\n    font-weight: bold;\n    font-family: 'Arial';\n    background-color: #5260ff;\n    color: white;\n}\n\n.center {\n    text-align: center;\n}\n\n.bottom {\n    vertical-align: right;\n}\n\n.border {\n    border-bottom: 2px solid #5260ff;\n}\n\ntable {\n    width: 100%;\n}\n\ntd {\n    text-align: center;\n}\n\n.padding { \n    padding-left: 1em;\n    padding-right: 1em;\n}\n\n.larger {\n    font-size: x-large;\n}\n\n.primary {\n    color: #5260ff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
