import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { GroupService } from '../services/group.service';
import { AlertController, IonicSafeString, ModalController, NavParams } from '@ionic/angular';
import { CourseService } from '../services/course.services';
import { teeValidations } from '../common/validations';

@Component({
  selector: 'app-preferred-tee-modal',
  templateUrl: './preferred-tee-modal.page.html',
  styleUrls: ['./preferred-tee-modal.page.scss'],
})
export class PreferredTeeModalPage implements OnInit {

  validations = teeValidations;
  currentUser = '';
  gender = 'Male';
  groups: any[] = [];
  courseTees: any[] = [];
  isSubmitted = false;
  passedTee: any;
  memberPreferredTees: any;
  isUpdateMode = false;

  teeForm = this.fb.group({
    group: new FormControl('', Validators.required),
    groupName: new FormControl(),
    courseName: new FormControl(),
    groupTeeName: new FormControl(),
    preferredTee: new FormControl('', Validators.required),
    preferredTeeName: new FormControl(''),
  });

  constructor(
    private fb: FormBuilder,
    private courseService: CourseService,
    private groupService: GroupService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) { }

  async ngOnInit() {
    this.getNavParams();
    await this.getGroups();
    if (this.passedTee) {
      this.populateTeeInfo(this.passedTee)
    }
    this.disableInputs();
  }

  async getNavParams() {
    this.currentUser = this.navParams.get('currentUser');
    const gender = this.navParams.get('gender');
    this.gender = gender ? gender : this.gender;
    this.passedTee = this.navParams.get('tee');
    this.memberPreferredTees = this.navParams.get('memberPreferredTees');
    this.isUpdateMode = this.navParams.get('isUpdate');
  }

  async populateTeeInfo(tee: any) {
    const { group, groupName, courseName, groupTeeName, preferredTee, preferredTeeName } = tee;
    const theGroup = this.groups.find(g => g.id === group);
    if (theGroup) {
      const { primaryCourse } = theGroup;
      const courseData = await this.courseService.getCourseByDocIdMod(primaryCourse);
      const { tees } = courseData;
      this.courseTees = this.filterSortTees(tees);
    }
    this.teeForm.patchValue({
      group,
      groupName,
      courseName,
      groupTeeName,
      preferredTee,
      preferredTeeName,
    })
  }

  disableInputs() {
    this.f.courseName.disable();
    this.f.groupTeeName.disable();
  }

  async getGroups() {
    const groups = [];
    const docs = await this.groupService.getGroupListContainingUserMod(this.currentUser);
    for (const doc of docs) {
      const groupData = doc.data();
      groups.push(groupData);
    }
    this.groups = groups;
  }

  async groupChanged() {
    const theGroup = this.groups.find(g => g.id === this.f.group.value);
    if (theGroup) {
      const { courses, groupName, tee: groupTee, displayCourseNames } = theGroup;
      const courseData = await this.courseService.getCourseByDocIdMod(courses[0]);
      const { tees } = courseData;
      this.courseTees = this.filterSortTees(tees);
      const theTee = this.courseTees.find(f => f.id === groupTee);
      const teeName = theTee ? theTee.teeName : '';
      this.teeForm.patchValue({
        groupName: groupName,
        courseName: displayCourseNames,
        groupTeeName: teeName,
      })
    }
  }

  filterSortTees(tees: any[]) {

    return tees.filter((t: any) => t.gender === this.gender)
      .sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0);
  }

  teeChanged() {
    const theTee = this.courseTees.find(t => t.id === this.f.preferredTee.value);
    this.teeForm.patchValue({
      preferredTeeName: theTee.teeName,
    })
  }

  submitForm() {
    this.isSubmitted = true;
    if (this.teeForm.valid) {
      const formData = this.teeForm.value;
      const theGroup = this.groups.find(g => g.id === this.f.group.value);
      if (!this.isUpdateMode) {
        if (this.isUnique) {
          if (theGroup && theGroup.tee !== formData.preferredTee) {
            this.enableInputs();
            this.modalCtrl.dismiss({
              preferredTeeObj: this.teeForm.value,
            })
          } else {
            this.presentSameTeeAlert();
          }
        }
      } else {
        if (theGroup && theGroup.tee !== formData.preferredTee) {
          this.enableInputs();
          this.modalCtrl.dismiss({
            preferredTeeObj: this.teeForm.value,
          })
        } else {
          this.presentSameTeeAlert();
        }
      }
    }
  }

  isUnique() {
    const formData = this.teeForm.value;
    const { group } = formData;
    const index = this.memberPreferredTees.findIndex(g => g.group === group);

    return index < 0;
  }

  enableInputs() {
    this.f.courseName.enable();
    this.f.group.enable();
    this.f.groupTeeName.enable();
  }

  async presentDupGroupAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Preferred Tee Already Exists',
      message: new IonicSafeString(`You are attempting to select a preferred tee for group which already has a preferred tee.
        <br><br>To change this tee, go back to previous screen (press cancel) and press the edit button (pencil icon) and then change the tee.`),
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Ok',
        },
      ]
    });

    await alert.present();
    alert.onDidDismiss()
      .then();
  }

  async presentSameTeeAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      message: new IonicSafeString(`You are attempting to select a preferred tee that matches the tee used for the group.
        <br><br>Please select a different tee or cancel. Preferred tees are only needed when they differ from the tee specified by the group`),
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Ok',
        },
      ]
    });

    await alert.present();
    alert.onDidDismiss()
      .then();
  }

  cancel() {
    this.modalCtrl.dismiss({
      preferredTeeObj: null,
    })
  }

  buttonDesc() {
    return this.passedTee ? 'Update Preferred Tee' : 'Add Preferred Tee';
  }

  get f() {
    return this.teeForm.controls;
  }
}
