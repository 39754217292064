import { addPressValidations } from './../common/validations';
import { GlobalService } from './../services/global.service';
import { EventBetService } from './../services/event-bet.service';
import { NavParams, ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component } from '@angular/core';

@Component({
  selector: 'app-add-press-modal',
  templateUrl: './add-press-modal.page.html',
  styleUrls: ['./add-press-modal.page.scss'],
})
export class AddPressModalPage {

  validations = addPressValidations;
  eventBetId: string;
  balls = [];
  pressHoles = [];
  ballCount = 0;
  isSubmitted = false;
  betFormat: number;

  public pressForm = this.fb.group({
    ball: new FormControl(0),
    pressHole: new FormControl('', Validators.required),
  });

  constructor(
    private eventBetService: EventBetService,
    private globalService: GlobalService,
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private navParams: NavParams,

  ) { }

  async ionViewWillEnter() {
    this.eventBetId = this.navParams.get('eventBetId');
    const eventBetDoc = await this.eventBetService.getByDocId(this.eventBetId).get().toPromise();
    const eventBetData: any = eventBetDoc.data();
    this.ballCount = this.determineBallCount(eventBetData);
    this.betFormat = eventBetData.bet.betFormat;
    this.getGlobals();

  }

  getGlobals() {
    this.pressHoles = this.globalService.getPressHoles();
  }

  determineBallCount(eventBetData: any) {
    let ballCount = 0;
    const { bet } = eventBetData;
    const { betFormat } = bet;
    const autoDowns = [0, 1];
    if (autoDowns.includes(betFormat)) {
      const { balls } = eventBetData;
      ballCount = balls.length;
      const descs = ['first', 'second', 'third', 'fourth', 'fifth'];
      for (let i = 0; i < balls.length; i++) {
        const ballDesc = {
          value: i,
          desc: descs[i],
        } as any;
        this.balls.push(ballDesc);
      }
      if (balls.length > 1) {
        this.pressForm.patchValue( { ball: null});
        this.ballFC.setValidators([Validators.required]);
      }
    }

    return ballCount;
  }

  addPress() {
    this.isSubmitted = true;
    if (this.pressForm.valid) {
      const ph: any = this.pressHole;
      const holeIndex = ph - 1;
      let sideDesc = ph < 10 ? 'front' : 'back';
      if ([7].includes(this.betFormat)) {
        sideDesc = 'back';
      }
      if ([8].includes(this.betFormat)) {
        sideDesc = 'total';
      }
      const ball = this.ball;

      const returnParams = {
        holeIndex,
        sideDesc,
        ball,
        eventBetId: this.eventBetId,
      }

      this.modalCtrl.dismiss({
        returnParams
      });
    }
  }

  get pressHole() {
    return this.pressForm.get('pressHole').value;
  }

  get ball() {
    return this.pressForm.get('ball').value;
  }

  get ballFC() {
    return this.pressForm.get('ball');
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
