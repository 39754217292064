import { NavParams, ModalController } from '@ionic/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage {

  msg: string;
  showOk: boolean

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,

  ) {
  this.msg = this.navParams.get('msg');
    this.showOk = this.navParams.get('showOk')
  }

  positive() {
    this.modalCtrl.dismiss({
      responseBool: true
    });
  }

  negative() {
    this.modalCtrl.dismiss({
      responseBool: false
    });
  }


}
