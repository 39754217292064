import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';

@Injectable()
export class GroupService extends BaseService {

  node = 'group';

  createGroup(data: any) {
    return super.create(this.node, data);
  }

  updateGroup(id, group) {
    return super.update(this.node, id, group);
  }

  getByDocId(id) {
    return this.afs.collection('group').doc(id);
  }

  async getByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  getGroupListForUser(user) {
    return this.afs.collection('group', ref => ref.where('groupAuthId', '==', user));
  }

  getGroupListContainingUser(user) {
    return this.afs.collection('group', ref => ref.where('members', 'array-contains', user));
  }

  async getGroupListContainingUserMod(user) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('members', 'array-contains', user),
      where('isActive','==', true),
    );

    return (await getDocs(q)).docs;
  }

  async getAllGroupsContainingUserMod(user: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('members', 'array-contains', user),
    );

    return (await getDocs(q)).docs;
  }

  addMember(id, member) {
    return this.afs.collection('group').doc(id).update({ 'members': firebase.firestore.FieldValue.arrayUnion(member) });
  }

  removeMember(id, member) {
    return this.afs.collection('group').doc(id)
      .update({ 'members': firebase.firestore.FieldValue.arrayRemove(Object.assign({}, member)) });
  }

  toggleGroupStatus(id: string, status) {
    return this.afs.collection(this.node).doc(id)
      .update({
        isActive: status,
      });
  }

}

