import { WorkerService } from './../services/worker-service';
import { EventSummaryTotalService } from './../services/event-summary-total.service';
import { CtpDetailService } from './../services/ctp-detail.service';
import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavParams, ModalController } from '@ionic/angular';
import { RsvpService } from '../services/rsvp.service';
import { FormBuilder, FormArray, FormControl } from '@angular/forms';
import { EventSummaryAdjustmentService } from '../services/event-summary-adjustment.service';

@Component({
  selector: 'app-ctp-modal',
  templateUrl: './ctp-modal.page.html',
  styleUrls: ['./ctp-modal.page.scss'],
})
export class CtpModalPage {
  subscription: Subscription;
  eventId: string;
  ctpArray: [];
  playingRsvps = [];
  viewEntered = false;

  public ctpForm = this.fb.group({
    ctps: this.fb.array([])
  });

  constructor(
    private ctpDetailService: CtpDetailService,
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private eventSummaryAdjustmentService: EventSummaryAdjustmentService,
    private eventSummaryTotalService: EventSummaryTotalService,
    private rsvpService: RsvpService,
    private workerService: WorkerService,
  ) { }

  ionViewWillEnter() {

    this.subscription = new (Subscription)();
    this.eventId = this.navParams.get('eventId');
    this.ctpArray = this.navParams.get('ctpArray');
    this.retrieveEligibleMembers();
    this.populateCtpFormArray();
  }

  ionViewDidEnter() {
    this.viewEntered = true;
  }

  retrieveEligibleMembers() {

    this.buildTempMember('None', '', 'n/a');
    const subsription = this.rsvpService.getRsvpThatArePlaying(this.eventId)
      .valueChanges()
      .subscribe((rsvps: any) => {
        for (const rsvp of rsvps) {
          const { firstname, lastname, memberAuthId } = rsvp;
          this.buildTempMember(firstname, lastname, memberAuthId);
        }
      });
    this.subscription.add(subsription);
  }

  buildTempMember(firstname: string, lastname: string, id: string) {
    const tempMember = {} as any;
    tempMember.firstname = firstname;
    tempMember.lastname = lastname;
    tempMember.id = id;
    this.playingRsvps.push(tempMember);
  }


  populateCtpFormArray() {
    const detSubscription = this.ctpDetailService.getDetailForEvent(this.eventId)
      .valueChanges()
      .subscribe((ctpDetails: any) => {
        const ctpsLength = this.ctps.length;
        for (let i = 0; i < ctpsLength; i++) {
          this.ctps.removeAt(0);
        }
        for (const ctpDetail of ctpDetails) {
          for (const ctp of ctpDetail.ctps) {
            const { ctpHoleNumber, ctpPlayerId, ctpFirstname, ctpLastName } = ctp;
            const formCtpHole = this.fb.group({
              ctpHoleNumber: new FormControl(ctpHoleNumber),
              ctpPlayerId: new FormControl(ctpPlayerId),
              ctpFirstname: new FormControl(ctpFirstname),
              ctpLastname: new FormControl(ctpLastName),
              id: new FormControl()
            });
            this.ctps.push(formCtpHole);
          }

        }

        for (const ctpHole of this.ctpArray) {
          const theCtpHole = this.ctps.value.find((c: any) => c.ctpHoleNumber === ctpHole);

          if (theCtpHole === null || theCtpHole === undefined) {
            console.log('building motherfucker');
            const newHole = {} as any;
            newHole.number = ctpHole;
            const formCtpHole = this.fb.group({
              ctpHoleNumber: new FormControl(ctpHole),
              ctpPlayerId: new FormControl(),
              ctpFirstname: new FormControl(),
              ctpLastname: new FormControl(),
              id: new FormControl()
            });
            this.ctps.push(formCtpHole);
          }
        }
      });

    this.subscription.add(detSubscription);

  }

  async submitForm() {
    const data: any = this.ctpForm.value;
    const { ctps } = data;
    data.id = this.eventId;
    data.eventId = this.eventId;
    let i = 0;
    for (const ctp of ctps) {
      const { ctpPlayerId } = ctp;
      const theCtp = this.playingRsvps.find(r => r.id === ctpPlayerId);
      if (theCtp != null) {
        const { firstname, lastname } = theCtp;
        data.ctps[i].ctpFirstname = firstname;
        data.ctps[i].ctpLastname = lastname;
      }
      i++;
    }
    await this.ctpDetailService.createCtpDetail(data)
    const estSnapshots = await this.eventSummaryTotalService.getEventSummaryTotalForEventId(this.eventId)
      .get()
      .toPromise()
    if (estSnapshots.size > 0) {
      const newEventSummaryAdjustment = {} as any;
      newEventSummaryAdjustment.id = this.eventSummaryAdjustmentService.getId();
      newEventSummaryAdjustment.eventId = this.eventId;
      this.eventSummaryAdjustmentService.createAdjustment(newEventSummaryAdjustment);
      const newWorker = {} as any;
      newWorker.id = this.workerService.getId();
      newWorker.type = '9';
      newWorker.eventId = this.eventId;
      this.workerService.createEvent(newWorker)
    }

    this.modalCtrl.dismiss();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  get ctps() {
    return this.ctpForm.get('ctps') as FormArray;
  }
}
