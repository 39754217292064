import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { registrationValidations } from '../common/validations';
import { GlobalService } from '../services/global.service';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.page.html',
  styleUrls: ['./register-modal.page.scss'],
})
export class RegisterModalPage {

  validations = registrationValidations;
  displayName: string;
  firstname: string;
  lastname: string;
  email: string;
  editInfo = false;
  isSubmitted = false;
  genders: any[];
  ghinLastname = '';
  isFirstnameDisabled = true;
  isLastnameDisabled = true;
  isEmailDisabled = true;

  registerForm = this.fb.group({
    email: new FormControl('', [Validators.required, Validators.email]),
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    handicapIdentifier: new FormControl(),
    handicapIndex: new FormControl(),
    displayHandicapIndex: new FormControl('', [Validators.required, Validators.pattern('[+]?[0-9]{0,2}[.]{1}[0-9]{1}')]),
    gender: new FormControl('', Validators.required),
    receiveEmailNotifications: new FormControl(true),
    receivePushNotifications: new FormControl(true),
    id: new FormControl(null)
  });

  constructor(
    private fb: FormBuilder,
    private alertCtrl: AlertController,
    private https: HttpService,
    private globalService: GlobalService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private toastCtrl: ToastController,
  ) { }

  ionViewWillEnter() {
    this.getNavParams();
    this.patchValues();
    this.disableFields();
    this.getGlobalValues();
    this.presentInitialAlert();

  }

  getNavParams() {
    this.displayName = this.navParams.get('displayName');
    this.firstname = this.navParams.get('firstname');
    this.lastname = this.navParams.get('lastname');
    this.email = this.navParams.get('email');
  }

  patchValues() {
    this.registerForm.patchValue({
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
    })
    console.log('this.form', this.registerForm.value);
  }

  disableFields() {
    const attributeArr = ['firstname', 'lastname', 'email'];
    attributeArr.forEach(att => {
      this.disableAttribute(att);
    })
  }

  disableAttribute(attribute: string) {
    this.registerForm.get(attribute).disable();
  }

  async presentInitialAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Additional information required',
      message: `Thank you for registering using your Facebook credentials! Please fill in the additional items to complete your registration.`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Ok',
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
      .then();

  }

  getGlobalValues() {
    this.genders = this.globalService.getGenders();
  }

  submitForm() {
    this.isSubmitted = true;
    if (this.registerForm.valid) {
      console.log('lastname', this.registerForm.get('lastname').value)
      console.log('ghin last', this.ghinLastname)
      if (this.ghinLastname !== '' && this.ghinLastname === this.registerForm.get('lastname').value) {
        this.modalCtrl.dismiss({
          memberData: this.registerForm.getRawValue(),
        })
      } else {
        this.presentMismatchAlert();
      }
    }
  }

  async handicapIdentifierChanged() {

    this.ghinLastname = '';
    const hi = this.registerForm.get('handicapIdentifier').value;
    if (hi) {
      const memberInfoGhin = await (await this.https.getGolferInfo(hi)).toPromise();
      for (const a of Object.keys(memberInfoGhin)) {
        const { last_name } = memberInfoGhin[a]
        this.ghinLastname = last_name;
      }
    }
  }

  async presentMismatchAlert() {

    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Last name mismatch',
      message: `Your lastname does not match the lastname associated with the GHIN Number you have provided. Please check the values you have entered.  Do you want to continue?`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            const toast = await this.toastCtrl.create({
              message: 'Your handicap index will not automatically update each day. You can update your information later under User Preferences',
              duration: 6500,
              position: 'middle',
              color: 'primary',
            });
            toast.present();
            this.modalCtrl.dismiss({
              memberData: this.registerForm.value,
            })
          }
        }, {
          text: 'No',
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
      .then();

  }

  async cancel() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Incomplete Information',
      message: `You are missing necessary information. Are you sure you want to continue?`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            const toast = await this.toastCtrl.create({
              message: 'You are missing crucial information. You can update this information later under User Preferences',
              duration: 8500,
              position: 'middle'
            });
            toast.present();
            this.modalCtrl.dismiss();
          }
        }, {
          text: 'No',
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
      .then();
  }

  toggleAttribute(attribute: string) {
    this.enableAttribute(attribute)
  }

  enableAttribute(attribute: string) {
    this.registerForm.get(attribute).enable();
  }
}
