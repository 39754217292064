import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import {  and, collection, doc, getDoc, getDocs, getFirestore, onSnapshot, or, query, where } from 'firebase/firestore';

@Injectable()
export class EventService extends BaseService {

  node = 'event';

  createEvent(data) {
    return super.create(this.node, data);
  }

  updateEvent(id, data) {
    return super.update(this.node, id, data);
  }

  getByDocId(id) {
    return this.afs.collection('event').doc(id);
  }

  async getByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  getEventListForUserWithCurrentDate(user, currentDate) {
    // make join on event-playing-count
    return this.afs.collection('event', ref =>
      ref.where('eventAuthId', '==', user)
        .where('compareDate', '>=', currentDate)
        .orderBy('compareDate', 'asc'))
      .valueChanges()
      .pipe(
        this.leftJoin(this.afs, 'id', 'event-playing-count')
      );
  }

  getPrevEventsForAdminAndDate(user: string, currentDate: string, limit: number) {
      // make join on event-playing-count
      return this.afs.collection('event', ref =>
        ref.where('admins', 'array-contains', user)
          .where('compareDate', '<', currentDate)
          .orderBy('compareDate', 'desc')
          .limit(limit))
        .valueChanges()
        .pipe(
          this.leftJoin(this.afs, 'id', 'event-playing-count')
        );
  }

  getEventsForAdminsAndDate(user: string, currentDate: string) {
    // make join on event-playing-count
    return this.afs.collection('event', ref =>
      ref.where('admins', 'array-contains', user)
        .where('compareDate', '>=', currentDate)
        .orderBy('compareDate', 'asc'))
      .valueChanges()
      .pipe(
        this.leftJoin(this.afs, 'id', 'event-playing-count')
      );
  }

  getEventListForUser(user: string) {
    // make join on event-playing-count
    return this.afs.collection('event', ref =>
      ref.where('eventAuthId', '==', user)
        .orderBy('compareDate', 'desc'))
      .valueChanges()
      .pipe(
        this.leftJoin(this.afs, 'id', 'event-playing-count')
      );
  }

  getEventListForAdmins(user: string, limit: number) {
    // make join on event-playing-count
    return this.afs.collection('event', ref =>
      ref.where('admins', 'array-contains', user)
        .orderBy('compareDate', 'desc')
        .limit(limit))
      .valueChanges()
      .pipe(
        this.leftJoin(this.afs, 'id', 'event-playing-count')
      );
  }

  getEventListForDateAndGroup(date, group) {
    return this.afs.collection('event', ref =>
      ref.where('groups', 'array-contains', group)
        .where('compareDate', '==', date)
    );
  }


  getOpenEventsForCourseWithPlayingCount(course: string, date: string) {
    return this.afs.collection('event', ref =>
      ref.where('course', '==', course)
        .where('openToOthers', '==', true)
        .where('compareDate', '==', date)
    ).
      valueChanges()
      .pipe(
        this.leftJoin(this.afs, 'id', 'event-playing-count')
      )
  }

  getOpenEventsForToday(date: string) {
    return this.afs.collection('event', ref =>
      ref.where('openToOthers', '==', true)
        .where('compareDate', '==', date)
    )
  }

  getOpenEventsForCourse(course: string, date: string) {
    return this.afs.collection('event', ref =>
      ref.where('course', '==', course)
        .where('openToOthers', '==', true)
        .where('compareDate', '==', date)
    );
  }

  updateWaitlist(id: string, waitlist) {
    return this.afs.collection('event').doc(id).update({ 'waitlist': waitlist });
  }

  updateStatus(id: string, status: string) {
    return this.afs.collection('event').doc(id).update({ 'status': status });
  }

  addWaitlist(id, member) {
    return this.afs.collection('event').doc(id)
      .update({ 'waitlist': firebase.firestore.FieldValue.arrayUnion(Object.assign({}, member)) });
  }

  removeFromWaitlist(id, rsvpId) {
    return this.afs.collection('event').doc(id)
      .update({ 'waitlist': firebase.firestore.FieldValue.arrayRemove(rsvpId) });
  }

  delete(id) {
    return this.afs.collection(this.node).doc(id).delete();
  }

}
