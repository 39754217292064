import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MassUpdateEventService extends BaseService {

  node = 'mass-update-event';

  getForEvent(eventId: string) {
    return this.afs.collection(this.node, ref => ref.where('eventId', '==', eventId ));
  }
}

