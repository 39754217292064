import { collection, getDocs, getFirestore, limit, orderBy, query, where, } from 'firebase/firestore';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PlayerStatDetailService extends BaseService {

    node = 'player-stat-detail';


    getForMember(memberId, limit: number) {
        return this.afs.collection(this.node, ref => ref
            .where('playerId', '==', memberId)
            .orderBy('compareDate', 'desc')
            .limit(limit)
        );
    }

    async getForMemberMod(playerId: string, lim: number) {
        const db = getFirestore();
        const q = query(
            collection(db, this.node),
            where('playerId', '==', playerId),
            orderBy('compareDate', 'desc'), 
            limit(lim)
        )

        return (await getDocs(q)).docs;
    }
    
    getForAndGroupMember(memberId: string, groupId: string) {
        return this.afs.collection(this.node, ref => ref
            .where('playerId', '==', memberId)
            .where('group', '==', groupId)
            .limit(20)
        );
    }

    getForGroup(groupId: string) {
        console.log({ groupId })
        return this.afs.collection(this.node, ref => ref
            .where('group', '>', '')
        );
    }
}