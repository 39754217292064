import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PointsTeamSizeValidator, TeamSizeValidator } from './../common/helpers/validators';
import { FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { AuthService } from './../services/auth.service';
import { MemberService } from './../services/member.service';
import { RsvpService } from './../services/rsvp.service';
import { PairingService } from './../services/pairing-service';
import { CourseService } from './../services/course.services';
import { EventService } from './../services/event.service';
import { NavParams, LoadingController, ModalController, AlertController } from '@ionic/angular';
import { Component } from '@angular/core';
import { formatDisplayCourseHandicap, calcCourseHandicap } from '../common/helpers/reuse-functions';
import { GlobalService } from '../services/global.service';
import { pairingValidations } from '../common/validations';
import { Subscription } from 'rxjs';
import { PairingDeleteEventService } from '../services/pairing-delete-event.service';
import { PairingChangeEventService } from '../services/pairing-change-event.service';

@Component({
  selector: 'app-team-modal-page',
  templateUrl: './team-modal-page.page.html',
  styleUrls: ['./team-modal-page.page.scss'],
})
export class TeamModalPagePage {
  validations = pairingValidations;
  course: any;
  currentUser;
  event: any;
  eventId: string;
  dbMembers = [];
  isLoading: boolean;
  isUpdateMode: boolean;
  ladiesTees: any[];
  mensTees: any[];
  pairing: any;
  pairingId: string;
  submitDisabled = false;
  lowSkinsGuy: any;
  formLowGuy: any;
  newLowSkinsGuy: any;
  pointsDescriptions: any;
  pairingPlayers = [];
  hasTeams = false;
  isSubmitted = false;
  pointsHandicapMethods = [];
  playersPlayingFromPreferredTees = [];
  eventFlightBreaks: any;
  subscription: Subscription;
  changedPairing: any;
  deleteLoader: any;
  changeLoader: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private courseService: CourseService,
    private eventService: EventService,
    private pairingService: PairingService,
    private memberService: MemberService,
    private pairingDeleteEventService: PairingDeleteEventService,
    private pairingChangeEventService: PairingChangeEventService,
    private rsvpService: RsvpService,
    private navParams: NavParams,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private teamSizeValidator: TeamSizeValidator,
    private pointsTeamSizeValidator: PointsTeamSizeValidator,
    private globalService: GlobalService,
    private db: AngularFirestore,
  ) { }

  selectForm = this.fb.group({
    teams: this.fb.array([]),
    usePoints: new FormControl(false),
    pointsDescription: new FormControl(),
    pointsAmount: new FormControl(),
    pointsHandicapMethod: new FormControl(),
    pointsHandicapPercentage: new FormControl(100, Validators.required),
    team1: new FormControl(),
    team2: new FormControl(),
    manualHandicaps: this.fb.array([]),
  });

  async ionViewWillEnter() {
    this.subscription = new Subscription;
    this.isUpdateMode = false;
    this.currentUser = await this.authService.currentUser();
    this.getNavParams();
    this.subscribeToPairingEvents();
    this.pointsDescriptions = this.globalService.getPointsDescription();
    this.pointsHandicapMethods = this.globalService.getPointsHandicapMethods();
    const a = this.retrieveEventInfo();
    const b = this.getPlayingMembers();
    await Promise.all([a, b]);
    this.getLowCourseHandicap()
    if (this.pairingId != null) {
      this.populateCurrentPlayers();
    } else {
      this.buildInitialForm();
      this.populateCurrentMember();
    }
    this.isLoading = false;
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  getNavParams() {
    this.pairingId = this.navParams.get('pairingId');
    this.eventId = this.navParams.get('eventId');
    this.isLoading = this.pairingId ? true : false;
    this.isUpdateMode = this.pairingId ? true : false;
  }

  async getPlayingMembers() {
    const loader = await this.loadingCtrl.create({
      message: 'Loading',
      translucent: true,
      spinner: 'bubbles',
      duration: 6000,
    });

    await loader.present();
    const pairedPlayers = await this.playersAlreadyInAPairing()
    const rsvpDocs = await this.rsvpService.getRsvpThatArePlayingMod(this.eventId);
    for (const rsvpDoc of rsvpDocs) {
      const rsvpData: any = rsvpDoc.data();
      const { firstname, lastname, id } = rsvpData.member;
      if (!pairedPlayers.includes(id) || this.isUpdateMode) {
        const memberData = await this.memberService.getMemberByDocIdMod(id);
        const { handicapIndex } = memberData;
        const tempMember = {
          firstname,
          lastname,
          handicapIndex,
          id, 
          scores: this.populateScores(),
        } as any;
        this.dbMembers.push(tempMember);
      }
    }
    loader.dismiss();
  }

  subscribeToPairingEvents() {
    if (this.isUpdateMode) {
      const subscription = this.pairingDeleteEventService.getForPairing(this.pairingId)
        .valueChanges()
        .subscribe((pairingUpdateEvents: any) => {
          if (pairingUpdateEvents.length > 0) {
            if (this.deleteLoader) {
              this.deleteLoader.dismiss();
              this.modalCtrl.dismiss({
                pairing: null,
                warning: 2,
              });
            }
          }
        });
      this.subscription.add(subscription);
      const subscription2 = this.pairingChangeEventService.getForPairing(this.pairingId)
        .valueChanges()
        .subscribe((pairingChangeEvents: any) => {
          if (pairingChangeEvents.length > 0) {
            if (this.changeLoader) {
              this.changeLoader.dismiss();
              this.modalCtrl.dismiss({
                pairing: this.changedPairing,
                warning: 0,
              });
            }
          }
        });
      this.subscription.add(subscription2);
    }
  }

  async resetPairing() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Pairing Reset',
      message: `You are attempting to reset your pairing. Reseting your pairing will remove the current pairing along with all scores that have already been entered. Select yes to continue with the reset. Then add your pairing and any scores as needed.`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            this.presentDoubleCheckAlert();
          }
        }, {
          text: 'No',
          handler: async () => {
            this.modalCtrl.dismiss();
          }
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
  }

  async presentDoubleCheckAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Pairing will be deleted',
      message: `You have chosen to delete your current pairing. This is a very rare choice and this is a second confirmation. If deleting your pairing is your goal, select Yes to continue or Cancel to cancel`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            this.deletePairing();
          }
        }, {
          text: 'Cancel',
          handler: async () => {
            this.modalCtrl.dismiss()
          }
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
  }

  async deletePairing() {
    this.deleteLoader = await this.loadingCtrl.create({
      message: 'Deleting your pairing',
      translucent: true,
      spinner: 'bubbles',
      duration: 20000,
    });

    await this.deleteLoader.present();

    this.pairingService.delete(this.pairingId);
  }

  async getLowCourseHandicap() {

    const processedMembers = [];
    this.lowSkinsGuy = {
      memberId: '',
      handicapIndex: -99,
      name: '',
      courseHandicap: -99,
      difference: 0,
    }

    const { course, tee } = this.event;
    const courseData: any = await this.courseService.getCourseDataByDocId(course)
    const { tees, handicapSets } = courseData;
    const theTee = tees.find(t => t.id === tee);
    if (theTee) {
      var { handicapSet, slope, rating } = theTee;
      const theHandicapSet = handicapSets.find(h => h.id === handicapSet);
      if (theHandicapSet) {
        var { par } = theHandicapSet;
      }
    }

    const tLength = this.teams().length;
    for (let t = 0; t < tLength; t++) {
      const team = this.teams().at(t).value;
      const { members } = team;
      const mLength = members.length;
      for (let m = 0; m < mLength; m++) {
        const member = this.member(t, m);
        const { courseHandicap, id: memberId, handicapIndex, name } = member;
        if (courseHandicap > this.lowSkinsGuy.courseHandicap) {
          const lowSkinsGuy = {
            memberId,
            handicapIndex,
            name,
            courseHandicap,
            difference: 0,
          }
          this.lowSkinsGuy = lowSkinsGuy;
        }
        processedMembers.push(memberId);
      }
    }

    const pairingDocs = await this.pairingService.getForEventMod(this.eventId);
    for (const pairingDoc of pairingDocs) {
      const pairingData: any = pairingDoc.data();
      const { players } = pairingData;
      for (const player of players) {
        const { id: memberId, handicapIndex, courseHandicap, name } = player;
        if ((courseHandicap > this.lowSkinsGuy.courseHandicap) && !(processedMembers.includes(memberId))) {
          const lowSkinsGuy = {
            memberId,
            handicapIndex,
            name,
            courseHandicap,
          }
          this.lowSkinsGuy = lowSkinsGuy;
        }
        processedMembers.push(memberId)
      }
    }

    const rsvpDocs = await this.rsvpService.getLowIndexRsvpMod(this.eventId, 99);
    for (const rsvpDoc of rsvpDocs) {
      const rsvpData: any = rsvpDoc.data();
      const { handicapIndex, member } = rsvpData;
      const { id: memberId, firstname, lastname } = member;
      const courseHandicap = Math.round(Number(handicapIndex) * (slope / 113) - (Number(rating) - par));
      if ((courseHandicap > this.lowSkinsGuy.courseHandicap) && !(processedMembers.includes(memberId))) {
        const lowSkinsGuy = {
          memberId,
          handicapIndex,
          name: `${firstname} ${lastname}`,
          courseHandicap,
          difference: 0,
        }
        this.lowSkinsGuy = lowSkinsGuy;
      }
    }
  }

  teamSize() {
    return this.event ? this.event.teamSize : 99;
  }

  async populateCurrentPlayers() {
    const pairingData = await this.pairingService.getByDocIdMod(this.pairingId)
    this.pairing = pairingData;
    const { teams, usePoints } = pairingData;
    let index = 0;
    for (let team1 of teams) {
      const teamMembersArr = [];
      const team = this.fb.group({
        teamMembers: new FormControl(),
        members: this.fb.array([]),
      })
      this.teams().push(team)
      const { members } = team1;
      for (let member of members) {
        const { name, handicapIndex, displayHandicapIndex, courseHandicap, displayCourseHandicap,
          scores, id, skinsHandicap, displaySkinsHandicap, flight, includeInSkins, gender, isHelper, tee } = member;
        if (!isHelper) {
          teamMembersArr.push(id);
          const tempMember = this.fb.group({
            name: [name],
            handicapIndex: [handicapIndex],
            displayHandicapIndex: new FormControl(displayHandicapIndex, [
              Validators.pattern('[+]?[0-9]{0,2}[.]{1}[0-9]{1}'),
              Validators.required,
            ]),
            courseHandicap: [courseHandicap],
            displayCourseHandicap: [displayCourseHandicap],
            skinsHandicap: [skinsHandicap],
            displaySkinsHandicap: [displaySkinsHandicap],
            tee: [tee],
            scores: [scores],
            id: [id],
            flight: [flight],
            includeInSkins: [includeInSkins],
            gender: [gender],
          });
          this.members(index).push(tempMember)
        }
      }
      this.teams().at(index).patchValue({ teamMembers: teamMembersArr })
      index++
    }
    if (usePoints) {
      const { players, pointsAmount, pointsDescription, team1, team2, pointsHandicapMethod, pointsHandicapPercentage } = pairingData;
      this.selectForm.patchValue({
        usePoints,
        pointsAmount,
        pointsDescription,
        pointsHandicapMethod,
        pointsHandicapPercentage,
        team1,
        team2,
      });

      if (pointsHandicapMethod === 4) {
        for (const player of players) {
          const { name, id, pointsHandicap } = player;
          const manualHandicap = this.fb.group({
            id: [id],
            name: [name],
            handicap: new FormControl([pointsHandicap], Validators.required),
          })
          this.manualHandicaps.push(manualHandicap)
        }
        for (let i = 0; i < this.members.length; i++) {
          this.manualHandicaps.at(i).get('name').disable();
        }
      }
    }
    this.isLoading = false;
  }

  playersAlreadyInAPairing(): any {
    return new Promise<any>(async (resolve, reject) => {
      const playerArray = [];
      if (!this.isUpdateMode) {
        const pairingDocs = await this.pairingService.getForEventMod(this.eventId);
        for (const doc of pairingDocs) {
          const pairingData = doc.data();
          const { players } = pairingData;
          for (const player of players) {
            playerArray.push(player.id);
          }
        }
        resolve(playerArray);
      } else {
        resolve([]);
      }
    });
  }

  populateCurrentMember() {
    const currentMemberArr = [];
    currentMemberArr.push(this.currentUser);
    this.teams().at(0).patchValue({ teamMembers: currentMemberArr })
  }

  async retrieveEventInfo() {
    this.event = await this.eventService.getByDocIdMod(this.eventId);
    this.course = await this.courseService.getCourseByDocIdMod(this.event.course);
    this.mensTees = this.course.tees.filter(t => t.gender === 'Male');
    this.ladiesTees = this.course.tees.filter(t => t.gender === "Female");
  }

  buildInitialForm() {
    const fa = new FormArray([]);
    const { teamSize } = this.event;
    const numberOfTeams = 4 / teamSize;
    for (let i = 0; i < numberOfTeams; i++) {
      const team = this.fb.group({
        teamMembers: new FormControl(),
        members: this.fb.array([]),
      })
      this.teams().push(team)
    }
    this.selectForm.setValidators([
      this.teamSizeValidator.maxNumberOfTeamMembers(this.teamSize()),
    ])
  }

  async savePairing() {
    this.isSubmitted = true;
    if (this.selectForm.valid) {
      this.submitDisabled = true;
      const pairingDocs = await this.pairingService.getForEventAndMemberMod(this.event.id, this.currentUser);
      if (pairingDocs.length === 0 || this.isUpdateMode) {
        this.continueWithPairingSave();
      } else {
        this.modalCtrl.dismiss({
          pairing: null,
          warning: 1
        });
      }
    }
  }

  async continueWithPairingSave() {

    const { birdOrBetOption, eventFormatType, flightOption, id: eventId, skinsFormat, useStablefordQuotaScoring, teamSize,
      doubleEagleAmount, eagleAmount, birdieAmount, parAmount, bogeyAmount, otherAmount } = this.event;

    const playerArray = [];
    const players = [];
    const formData = this.selectForm.value;
    const { teams, usePoints, pointsDescription, pointsAmount, pointsHandicapMethod, pointsHandicapPercentage } = formData;
    teams.forEach((team: any) => {
      const { members, teamMembers } = team;
      members.forEach(member => {
        const player = this.initPlayer(member);
        players.push(player);
        playerArray.push(member.id);
      })
      if (teamSize > members.length) {
        const member = this.initExtraMember(members[0].scores);

        members.push(member);
        teamMembers.push(member.id);
      }
    })

    let partners = usePoints ? ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''] : [];
    const id = !this.isUpdateMode ? this.pairingService.getId() : this.pairingId;

    let newPairing = {
      birdOrBetOption,
      currentHoleIndex: 0,
      eventFormatType,
      eventId,
      flightOption,
      holeIndex: 0,
      holesPlayed: 0,
      id,
      playerArray,
      players,
      skinsFormat,
      startingHole: 1,
      useStablefordQuotaScoring,
      doubleEagleAmount,
      eagleAmount,
      birdieAmount,
      parAmount,
      bogeyAmount,
      otherAmount,
      teams,
      createBetEvents: true,
      handicapSet: this.eventHandicapSet(),
      teamEventDetails: this.teamEventDetails(),
      createdTS: this.pairingService.getTimeStamp(),
      usePoints,
      pointsAmount,
      pointsDescription,
      pointsHandicapMethod,
      pointsHandicapPercentage,
      autoCalc: false,
      hasTeams: false,
      selectPartners: false,
      hasProx: false,
      hasTwists: false,
      hasTurns: false,
      teamSize: 0,
      partners: partners,
      proxs: [],
      twists: [],
      turns: [],
      team1: [],
      team2: [],

    } as any;


    if (usePoints) {
      newPairing = this.addPointsInfo(newPairing);
    }

    const batch = this.db.firestore.batch();
    const pairingXref = this.db.firestore.collection('pairing').doc(newPairing.id);
    batch.set(pairingXref, newPairing);

    if (this.formLowGuy || this.newLowSkinsGuy) {
      const pairingDocs = await this.pairingService.getForEventMod(this.event.id);
      for (const pairingDoc of pairingDocs) {
        const memId = this.formLowGuy ? this.formLowGuy.memberId : this.newLowSkinsGuy.memberId;
        const pairingData: any = pairingDoc.data();
        const { players, id: otherPairingId, teams, autoCalc, selectPartners, partners, hasTeams, hasProx, hasTwists,
          hasTurns, teamSize, twists, turns, proxs, team1, team2, } = pairingData;
        if (otherPairingId !== this.pairingId) {
          for (let player of players) {
            const { playerId: pairingPlayerId, courseHandicap } = player;
            if (memId !== pairingPlayerId) {
              player.skinsHandicap = this.calcSkinsHandicap(pairingPlayerId, courseHandicap);
              player.displaySkinsHandicap = player.skinsHandicap;
            }
          }
          if (teams) {
            for (const team of teams) {
              const { members } = team;
              for (const member of members) {
                const { id: pairingMemberId, courseHandicap: memCourseHandicap } = member;
                if (memId !== pairingMemberId) {
                  member.skinsHandicap = this.calcSkinsHandicap(pairingMemberId, memCourseHandicap);
                  member.displaySkinsHandicap = member.skinsHandicap;
                }
              }
            }
          }
          const pRef = this.db.firestore.collection('pairing').doc(otherPairingId);
          batch.update(pRef, {
            'players': players,
            'teams': teams,
            'usePoints': usePoints,
            'pointsAmount': pointsAmount,
            'pointsDescription': pointsDescription,
            'autoCalc': autoCalc,
            'selectPartners': selectPartners,
            'partners': partners,
            'hasTeams': hasTeams,
            'hasProx': hasProx,
            'hasTwists': hasTwists,
            'hasTurns': hasTurns,
            'teamSize': teamSize,
            'twists': twists,
            'turns': turns,
            'proxs': proxs,
            'team1': team1,
            'team2': team2,
          })
        }
      }
    }

    this.setChangeLoader(newPairing);
   
    await batch.commit()

    if (!this.isUpdateMode || this.isUpdateMode && this.areSamePlayers(newPairing)) {
      this.modalCtrl.dismiss({
        pairing: newPairing,
        warning: 0,
      });
    }
  }

  async setChangeLoader(pairing: any) {
    if (this.isUpdateMode) {
      if (!this.areSamePlayers(pairing)) {
        this.changeLoader = await this.loadingCtrl.create({
          message: 'Updating your Pairing',
          translucent: true,
          spinner: 'bubbles',
          duration: 20000,
        });
    
        await this.changeLoader.present();
    
        this.changedPairing = pairing;
      }
    }
  }

  areSamePlayers(pairing: any) {
    const originalPlayers = this.pairing.playerArray.sort();
    const newPlayers = pairing.playerArray.sort();
    for (let i = 0; i < originalPlayers.length; i++) {
      if (originalPlayers[i] !== newPlayers[i]) {
        return false;
      }
    }
    
    return true;
  }


  eventHandicapSet() {
    const theTee = this.course.tees.find(((t: any) => t.id === this.event.tee));

    return theTee.handicapSet
  }

  async presentPreferredTeeAlert() {
    const names = [...this.playersPlayingFromPreferredTees];
    const nameStr = this.getNamesStr(names);

    const isAre = this.playersPlayingFromPreferredTees.length > 1 ? 'are' : 'is';
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Preferred Tees',
      message: `Today, ${nameStr} ${isAre} playing from their Preferred Tees (tees they have selected to play that differ from the group's tee). Likely no action is needed, just a heads up reminder for the group.`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Ok',
        },
      ]
    });

    await alert.present();

    alert.onDidDismiss()
      .then();
  }

  getNamesStr(names) {
    let nameStr = '';
    for (let i = 0; i < names.length; i++) {
      const curStr = names[i];
      const str = i === 0 ? curStr : i !== names.length - 1 ? `, ${curStr}` : names.length > 2 ? `, and ${curStr}` : `and ${curStr}`;
      nameStr = nameStr.concat(str);
    }

    return nameStr;
  }

  addPointsInfo(pairing) {
    const formData = this.selectForm.value as any;
    const { pointsDescription, team1, team2, pointsHandicapMethod, pointsHandicapPercentage } = formData;
    const modPairing = pairing;
    const pointHoles = [];
    const thePointsDescription = this.pointsDescriptions.find(p => p.value === pointsDescription);
    const { autoCalc, selectPartners, hasTeams, hasProx, hasTwists, hasTurns } = thePointsDescription;
    modPairing.autoCalc = thePointsDescription ? autoCalc : false;
    modPairing.selectPartners = thePointsDescription ? selectPartners : false;
    modPairing.hasTeams = thePointsDescription ? hasTeams : false;
    modPairing.hasProx = thePointsDescription ? hasProx : false;
    modPairing.proxs = thePointsDescription && hasProx ? this.initProxs() : [];
    modPairing.twists = hasTwists ? this.initBoolArr(true) : [];
    modPairing.turns = hasTurns ? this.initBoolArr(false) : [];
    modPairing.team1 = team1 ? team1 : [];
    modPairing.team2 = team2 ? team2 : [];
    for (let i = 0; i < 18; i++) {
      const pointHole = {
        points: 0,
      }
      pointHoles.push(pointHole);
    }
    for (let player of modPairing.players) {
      player.pointHoles = pointHoles;
    }
    if ([1].includes(pointsDescription)) {
      let flipWolfPartners = [];

      for (let p = 0; p < 18; p++) {
        const headsArr = [];
        const tailsArr = [];
        let n = 0;
        for (let i = 0; i < this.teams().length; i++) {
          const z = this.teams().at(i).value;
          for (let r = 0; r < z.members.length; r++) {
            const num = Math.round(Math.random() * 1000000).toString();
            const at = Math.round(Math.random() * num.length);
            const atInt = at > 0 ? at - 1 : at;
            const numAt = num.charAt(atInt);
            const heads = +numAt % 2 === 0;
            if (heads) {
              headsArr.push(n)
            } else {
              tailsArr.push(n);
            }
            n++
          }
        }
        const usedPartners = headsArr.length > 0 ? headsArr.join('') : tailsArr.join('');
        flipWolfPartners.push(usedPartners)
      }

      modPairing.partners = flipWolfPartners;
    }

    if (pointsHandicapMethod > 1) {
      let low = -99;
      if (pointsHandicapMethod === 2) {
        for (const player of modPairing.players) {
          const { courseHandicap } = player;
          if (courseHandicap > low) {
            low = courseHandicap;
          }
        }
      }

      if (pointsHandicapMethod < 4) {
        for (const player of modPairing.players) {
          const { courseHandicap } = player;
          const pointsHandicap = pointsHandicapMethod === 2 ? player.courseHandicap - low : courseHandicap;
          player.pointsHandicap = Math.round(pointsHandicap * (pointsHandicapPercentage / 100));
        }
      }

      if (pointsHandicapMethod === 4) {
        for (let i = 0; i < this.manualHandicaps.length; i++) {
          const manualHandicap = this.manualHandicaps.at(i).value;
          const { id, handicap } = manualHandicap;
          const thePlayer = modPairing.players.find((p: any) => p.id === id);
          thePlayer.pointsHandicap = handicap * -1;
        }
      }
    }

    return modPairing;
  }

  initPlayer(member: any) {
    const { id, courseHandicap, displayCourseHandicap, displaySkinsHandicap, flight, gender, handicapIndex, includeInSkins, name, skinsHandicap, scores, tee } = member;
    const player = {
      courseHandicap,
      displayCourseHandicap,
      displaySkinsHandicap,
      flight,
      gender,
      handicapIndex,
      includeInSkins,
      name,
      id,
      skinsHandicap,
      scores,
      tee,
      isHelper: false,
    } as any;

    return player;
  }

  initExtraMember(scores: any) {
    const member = {
      id: this.pairingService.getId(),
      courseHandicap: 0.0,
      displayCourseHandicap: 0.0,
      displaySkinsHandicap: 0.0,
      flight: 999,
      gender: 'Male',
      handicapIndex: 0.0,
      includeInSkins: false,
      name: 'Rotating',
      skinsHandicap: 0.0,
      scores,
      isHelper: true,
      tee: this.event.tee,
    } as any;

    return member;
  }

  initBoolArr(isTwist: boolean) {
    const bools = [];
    let isFirstTime = true;
    for (let i = 0; i < 18; i++) {
      if (isFirstTime && isTwist) {
        bools.push(true);
        isFirstTime = false;
      } else {
        bools.push(false);
      }
    }

    return bools;
  }

  initProxs() {
    const proxs = [];
    for (let i = 0; i < 18; i++) {
      proxs.push('');
    }

    return proxs;
  }

  teamEventDetails() {
    const { eventFormatType } = this.event;
    if (eventFormatType === '3') {
      const { teamFormat, teamSize, grossScoresToCount, netScoresToCount, teamEventAmount, teamUnevenMethod, calcTeamsInRealTime } = this.event;
      const teamEventDetails = {
        teamFormat,
        teamSize,
        grossScoresToCount,
        netScoresToCount,
        teamEventAmount,
        teamUnevenMethod,
        calcTeamsInRealTime,
      }
      return teamEventDetails;
    } else {
      return undefined;
    }
  }

  populateScores() {
    const scores = [];
    for (let i = 0; i < 18; i++) {
      const newHole = {
        gross: 0,
        net: 0,
        relationToParGross: 0,
        relationToParNet: 0,
        skinsNet: 0,
        pointsNet: 0,
        displayGross: '0',
        displayNet: '0',
        displayPoints: '0',
        isBob: false
      }

      scores.push(newHole);
    }

    return scores;
  }

  async memberSelectionChanged(index: number) {
    if (!this.isLoading) {
      const promises = [];
      this.playersPlayingFromPreferredTees.length = 0;
      const mLength = this.members(index).length;
      for (let i = 0; i < mLength; i++) {
        (this.members(index).removeAt(0));
      }
      const formMembers = this.getTeamMembers(index);
      for (const memberId of formMembers) {
        const p = this.addMember(memberId, index);
        promises.push(p);
      }

      await Promise.all(promises)
      if (this.playersPlayingFromPreferredTees.length > 0) {
        this.presentPreferredTeeAlert()
      }
    }
  }

  async addMember(memberId: string, index: number) {
    const scoreArray = [];
    for (let i = 0; i < 18; i++) {
      if (!this.isUpdateMode) {
        scoreArray.push(this.initHole());
      } else {
        const thePlayer = this.pairing.players.find((p: any) => p.id === memberId);
        if (thePlayer != null || thePlayer !== undefined) {
          scoreArray.push(thePlayer.scores[i]);
        } else {
          scoreArray.push(this.initHole());
        }
      }
    }

    const memberData = await this.memberService.getMemberByDocIdMod(memberId);
    const { firstname, lastname, displayHandicapIndex, id, gender, handicapIndex, preferredTees } = memberData;
    const memberTee = this.determineTee(preferredTees, memberData);
    const theTee = this.course.tees.find(((t: any) => t.id === memberTee));
    const { slope, rating } = theTee;
    const par = this.course.handicapSets.find(h => h.id = theTee.handicapSet).par;
    const courseHandicap = calcCourseHandicap(handicapIndex, Number(slope), par, rating);
    const displayCourseHandicap = formatDisplayCourseHandicap(courseHandicap);
    const skinsHandicap = this.calcSkinsHandicap(id, courseHandicap);
    const displaySkinsHandicap = skinsHandicap;
    const concatName = `${firstname} ${lastname}`;
    const flight = this.findFlight(courseHandicap);
    const tempMember = this.fb.group({
      name: [concatName],
      handicapIndex: [handicapIndex],
      displayHandicapIndex: new FormControl(displayHandicapIndex, [
        Validators.pattern('[+]?[0-9]{0,2}[.]{1}[0-9]{1}'),
        Validators.required,
      ]),
      courseHandicap: [courseHandicap],
      displayCourseHandicap: [displayCourseHandicap],
      skinsHandicap: [skinsHandicap],
      displaySkinsHandicap: [displaySkinsHandicap],
      tee: [memberTee],
      scores: [scoreArray],
      id: [memberId],
      flight: [flight],
      includeInSkins: new FormControl(true, Validators.required),
      gender: [gender],
    });

    this.members(index).push(tempMember)
  }

  async teeSelectionChanged(teamIndex: number, memberIndex: number) {
    const curMember = this.member(teamIndex, memberIndex);
    const { tee, handicapIndex, id: playerId, name } = curMember;
    const playerTee = this.course.tees.find(((t: any) => t.id === tee));
    const par = this.course.handicapSets.find(h => h.id = playerTee.handicapSet).par
    const { slope, rating } = playerTee;
    const newCourseHandicap = calcCourseHandicap(handicapIndex, slope, par, rating)
    const newSkinsHandicap = this.calcSkinsHandicap(playerId, newCourseHandicap);
    const displaySkinsHandicap = newSkinsHandicap;
    this.memberControl(teamIndex, memberIndex).get('courseHandicap').patchValue(newCourseHandicap);
    this.memberControl(teamIndex, memberIndex).get('displayCourseHandicap').patchValue(formatDisplayCourseHandicap(newCourseHandicap));
    this.memberControl(teamIndex, memberIndex).get('skinsHandicap').patchValue(newSkinsHandicap);
    this.memberControl(teamIndex, memberIndex).get('displaySkinsHandicap').patchValue(displaySkinsHandicap);

    const { memberId: lowGuyId } = this.lowSkinsGuy;
    let theLowCourseHandicap = this.lowSkinsGuy.courseHandicap
    if (!!this.formLowGuy) {
      theLowCourseHandicap = this.formLowGuy.courseHandicap;
    }
    if (newCourseHandicap > theLowCourseHandicap) {
      const difference = newCourseHandicap - theLowCourseHandicap;
      const lowSkinsGuy = {
        memberId: playerId,
        handicapIndex,
        name,
        courseHandicap: newCourseHandicap,
        difference,
      }
      this.lowSkinsGuy = lowSkinsGuy;
      this.formLowGuy = this.lowSkinsGuy;
      this.newLowSkinsGuy = undefined;
      this.updateOtherPlayersInPairing(playerId);
    }
    // changed to a tee that makes no longer the low guy;
    if (newCourseHandicap < theLowCourseHandicap && playerId === lowGuyId) {
      await this.getLowCourseHandicap();
      const { memberId, courseHandicap: lgCourseHandicap } = this.lowSkinsGuy;
      const difference = newCourseHandicap - lgCourseHandicap;
      this.lowSkinsGuy.difference = difference;
      this.updateOtherPlayersInPairing(memberId);
      this.formLowGuy = undefined;
      this.newLowSkinsGuy = this.lowSkinsGuy;
      const isInGroup = this.checkIfLowGuyIsInThisGroup();
      if (isInGroup) {
        this.formLowGuy = this.lowSkinsGuy;
      }
    }
  }

  determineTee(preferredTees: any, member: any) {
    let usedTee = this.event.tee;
    if (preferredTees && preferredTees.length > 0) {
      const thePreferredTee = preferredTees.find(t => t.group === this.event.groups[0]);
      if (thePreferredTee) {
        usedTee = thePreferredTee.preferredTee;
        const { firstname, lastname } = member;
        this.playersPlayingFromPreferredTees.push(`${firstname} ${lastname}`);
      }
    }

    return usedTee;
  }

  usePointsChanged() {
    const validators = this.usePoints ? [Validators.required] : null;
    this.pointsHandicapMethodFC.setValidators(Validators.required);
    this.pointsHandicapMethodFC.updateValueAndValidity();
    this.pointsAmountFC.setValidators(validators);
    this.pointsAmountFC.updateValueAndValidity()
    this.pointsDescriptionFC.setValidators(validators)
    this.pointsDescriptionFC.updateValueAndValidity()

    if (!this.usePoints) {
      this.team1FC.setValidators(null);
      this.team2FC.setValidators(null);
      this.team1FC.updateValueAndValidity();
      this.team2FC.updateValueAndValidity();
      this.pointsHandicapMethodFC.setValidators(null);
      this.pointsHandicapMethodFC.updateValueAndValidity();
      this.pointsHandicapPercentageFC.setValidators(null);
      this.pointsHandicapPercentageFC.updateValueAndValidity();
      this.selectForm.patchValue({
        pointsDescription: null,
        pointsHandicapMethod: null,
        pointsHandicapPercentage: null,
        team1: [],
        team2: [],
      })
      this.hasTeams = false;
    }
  }

  pointsDescriptionChanged() {
    const thePointsDescription = this.pointsDescriptions.find(p => p.value === this.pointsDescription);
    if (thePointsDescription) {
      const { teamSize, hasTeams } = thePointsDescription;
      const arr = [];
      for (const teams of this.selectForm.get('teams').value as any) {
        for (const team of teams.teamMembers) {
          const theMember = this.dbMembers.find(m => m.id === team);
          if (theMember) {
            const { firstname, lastname } = theMember;
            const name = `${firstname} ${lastname}`;
            const player = {
              id: team,
              name,
            }
            arr.push(player)
          }
        }
      }
      this.pairingPlayers = hasTeams ? arr : [];
      this.hasTeams = hasTeams;
      const validator = teamSize === 0 ? null : [Validators.required, this.pointsTeamSizeValidator.teamSizeValidator(teamSize)];
      this.team1FC.setValidators(validator);
      this.team2FC.setValidators(validator);
    }
  }

  pointsHandicapMethodChanged() {
    if (this.pointsHandicapMethod === 4) {

      const teams = this.teams().value;
      for (const team of teams) {
        const { members } = team
        for (const member of members) {
          const { id, name } = member;
          if (member) {
            const manualHandicap = this.fb.group({
              id: [id],
              name: [name],
              handicap: new FormControl(0, Validators.required),
            })
            this.manualHandicaps.push(manualHandicap)
          }
        }
      }
    }
    if (this.pointsHandicapMethod < 4) {
      const len = (this.manualHandicaps as FormArray).length;
      for (let i = 0; i < len; i++) {
        (this.manualHandicaps as FormArray).removeAt(0);
      }
    }
  }

  checkIfLowGuyIsInThisGroup() {

    let isInGroup = false;
    const { lgMemberId } = this.lowSkinsGuy;
    const teamLength = this.teams().length;

    for (let t = 0; t < teamLength; t++) {
      const { members } = this.teams().at(t).value;
      const memberLength = members.length;
      for (let m = 0; m < memberLength; m++) {
        const member = this.member(t, m);
        const { id: memberId } = member;
        if (lgMemberId === memberId) {
          isInGroup = true;
        }
      }
    }

    return isInGroup;
  }

  updateOtherPlayersInPairing(playerId: string) {
    const teamLength = this.teams().length;

    for (let t = 0; t < teamLength; t++) {
      const { members } = this.teams().at(t).value;
      const memberLength = members.length;
      for (let m = 0; m < memberLength; m++) {
        const member = this.member(t, m);
        const { courseHandicap, id } = member;

        let newSkinsHandicap = this.calcSkinsHandicap(id, courseHandicap);
        if (id === playerId) {
          newSkinsHandicap = 0;
        }
        const newDisplaySkinsHandicap = newSkinsHandicap;
        this.memberControl(t, m).get('skinsHandicap').patchValue(newSkinsHandicap);
        this.memberControl(t, m).get('displaySkinsHandicap').patchValue(newDisplaySkinsHandicap);
      }
    }
  }

  calcSkinsHandicap(playerId: string, courseHandicap: number) {
    let skinsHandicap = 0;

    const { handicapMethod, skinsFormat, useMaxStrokes, maxHandicapStrokes } = this.event;

    if (skinsFormat > 1) {
      if (handicapMethod === 1) {
        skinsHandicap = courseHandicap * -1;
      }
      if (handicapMethod === 2) {
        const { courseHandicap: lowCourseHandicap, memberId } = this.lowSkinsGuy;
        skinsHandicap = lowCourseHandicap - courseHandicap;
        if (memberId === playerId) {
          skinsHandicap = 0;
        }
      }
    }

    if (useMaxStrokes && skinsHandicap > maxHandicapStrokes) {
      skinsHandicap = maxHandicapStrokes;
    }

    return skinsHandicap;
  }

  initHole() {
    const newHole = {
      gross: 0,
      net: 0,
      relationToParGross: 0,
      relationToParNet: 0,
      skinsNet: 0,
      pointsNet: 0,
      displayGross: 0,
      displayNet: 0,
      displayPoints: '0',
      stablefordGross: 0,
      stablefordNet: 0,
      isBoB: false,
      isAdjustment: false,
    }

    return newHole;
  }

  eventTeePar() {
    return this.course.handicapSets.find(h => h.id = this.eventTee().handicapSet).par
  }

  eventTee() {
    const theTee = this.course.tees.find(((t: any) => t.id === this.event.tee));
    return theTee;
  }

  findFlight(courseHandicap: number) {
    let retFlight = '999';

    if (this.event.skinsUseFlights) {
      const arr = this.event.skinsFlightDistributionOption === 1 ? this.eventFlightBreaks.flights : this.event.skinsFlights;
      retFlight = this.arrFindFlight(arr, courseHandicap);
    }

    return retFlight;
  }

  arrFindFlight(flights: any[], courseHandicap: number) {

    const convertedFlights = this.convertFlights(flights);
    // course handicap is positive for plus hanicap negative for chops
    const theFlight = convertedFlights.find((f: any) => courseHandicap <= f.bottom && courseHandicap >= f.top)

    return theFlight ? theFlight.name : '999';
  }

  convertFlights(flights: any[]) {
    const newFlights: any[] = [];
    for (let flight of flights) {
      let newFlight;
      const { skinsFlightDistributionOption } = this.event;
      if (skinsFlightDistributionOption === 1) {
        const { name, start, end } = flight;
        newFlight = {
          name,
          bottom: start,
          top: end,
        }
      }
      if (skinsFlightDistributionOption === 2) {
        const { name, bottom, top } = flight;
        const newBottom = bottom ? bottom.toString().includes('+') ? Number(bottom.slice(1)) : bottom * -1 : 100;
        const newTop = top ? top.toString().includes('+') ? Number(top.slice(1)) : top * -1 : -100;
        newFlight = {
          name,
          bottom: newBottom,
          top: newTop
        }
      }

      newFlights.push(newFlight);
    }

    return newFlights
  }

  teamDescription(index: number) {
    let desc = '';
    if (index !== undefined && index !== null) {
      if (index === 0) {
        desc = 'Your Team';
      }
      if (index === 1) {
        desc = 'Second Team'
      }
      if (index === 2) {
        desc = 'Third Team'
      }
      if (index === 3) {
        desc = 'Fourth Team'
      }
    }

    return desc;
  }

  allMembers() {
    const members = [];
    const length = this.teams().length;
    for (let i = 0; i < length; i++) {
      const teamMembers = this.members(i).value;
      teamMembers.forEach(teamMember => {
        members.push(teamMember);
      })
    }

    return members;
  }

  cancel() {
    this.modalCtrl.dismiss({
      pairing: null,
      warning: 0,
    });

  }

  getTeamMembers(index) {
    return this.teams().at(index).get('teamMembers').value;
  }

  teams() {
    return this.selectForm.get('teams') as FormArray;
  }

  member(teamIndex, memberIndex) {

    return (this.teams().at(teamIndex).get('members') as FormArray).at(memberIndex).value
  }

  memberControl(teamIndex: number, memberIndex: number) {
    return (this.teams().controls[teamIndex].get('members') as FormArray).controls[memberIndex]
  }

  members(index) {
    return this.teams().at(index).get('members') as FormArray;
  }

  teamMembers(index) {
    return this.teams().at(index).get('teamMembers') as FormArray;
  }

  get usePoints() {
    return this.selectForm.get('usePoints').value;
  }

  get usePointsFC() {
    return this.selectForm.get('usePoints').value;
  }

  get pointsAmount() {
    return this.selectForm.get('pointsAmount').value;
  }

  get pointsAmountFC() {
    return this.selectForm.get('pointsAmount');
  }

  get pointsDescription() {
    return this.selectForm.get('pointsDescription').value;
  }

  get pointsDescriptionFC() {
    return this.selectForm.get('pointsDescription');
  }

  get team1FC() {
    return this.selectForm.get('team1');
  }

  get team2FC() {
    return this.selectForm.get('team2');
  }

  get pointsHandicapMethod() {
    return this.selectForm.get('pointsHandicapMethod').value;
  }

  get pointsHandicapMethodFC() {
    return this.selectForm.get('pointsHandicapMethod');
  }

  get pointsHandicapPercentageFC() {
    return this.selectForm.get('pointsHandicapPercentage');
  }

  get manualHandicaps() {
    return this.selectForm.get('manualHandicaps') as FormArray;
  }
}
