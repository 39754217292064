import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService extends BaseService {

  node = 'notification';

  createNotification(data) {
    return super.create(this.node, data);
  }

  getUnreadForOwner(ownerId: string) {
    return this.afs.collection(
      this.node,
      ref => ref
        .where('owner', '==', ownerId)
        .where('isRead', '==', false)
    );
  }

  getForOwnerAndDays(ownerId, beginDate: Date) {
    return this.afs.collection(
      this.node,
      ref => ref
        .where('owner', '==', ownerId)
        .where('createdTS', '>', beginDate)
        .orderBy('createdTS', 'desc')
    );
  }

  updateIsReadStatus(id: string, newStatus) {
    return this.afs.collection(this.node).doc(id)
      .update({ isRead: newStatus });
  }
}
