import { Component, OnInit } from '@angular/core';
import { changeValidations } from '../common/validations';
import { AlertController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MemberService } from '../services/member.service';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.page.html',
  styleUrls: ['./delete-account-modal.page.scss'],
})
export class DeleteAccountModalPage implements OnInit {
  validations = changeValidations;
  currentUser: string;
  isSubmitted = false;

  constructor(
    private alertCtrl: AlertController,
    private authService: AuthService,
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private memberService: MemberService,
    private toastCtrl: ToastController,
   
  ) { }

  changeForm = this.fb.group({
    currentEmail: new FormControl(),
    password: new FormControl('', Validators.required),
  });

  async ngOnInit() {
    const email = this.navParams.get('email');
    this.currentUser = await this.authService.currentUser();
    this.changeForm.patchValue({
      currentEmail: email
    });
    this.currentEmailFC.disable();
  }

  async submitForm() {
    if (this.changeForm.valid) {
      this.isSubmitted = true;
      const data = this.changeForm.value;
      const { password } = data;
      const curEmail = this.currentEmail;
      try {
        await this.authService.deleteAccount(curEmail, password);
        await this.memberService.deleteUser(this.currentUser);
        this.makeToast();
        this.modalCtrl.dismiss();
      }

      catch (error) {
        this.presentAlert(error.message);
        console.log({error});
      }
    }
  }

  async makeToast() {
    const toast = await this.toastCtrl.create({
      message: `Your account has been removed. When you want to start using this application again, please register again.`,
      duration: 4000,
      position: 'middle',
      color: 'primary',
    });
    toast.present();
  }

  async presentAlert(errMessage: string) {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      message: `Current email and password are not correct. ${errMessage}`,
      cssClass: 'custom-alert',
      buttons: ['OK']
    });

    await alert.present();
  }

  cancel() {
    this.modalCtrl.dismiss({
      returnParams: null,
    });
  }

  get currentEmail() {
    return this.changeForm.get('currentEmail').value;
  }

  get currentEmailFC() {
    return this.f.currentEmail;
  }

  get f() {
    return this.changeForm.controls;
  }

}
