import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage } from '@capacitor/storage';
// import 'firebase/auth';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, UserCredential, FacebookAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { throwIfEmpty } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: Observable<firebase.User>;

  constructor(
    private auth: Auth,
  ) {
    Storage.migrate();
    Storage.removeOld();
    //private _firebaseAuth: AngularFireAuth) {
    // this.user = _firebaseAuth.authState;
  }

  authState = new BehaviorSubject(false);

  async registerUser(value): Promise<UserCredential> {
    try {
      const { email, password } = value;
      const credentials = await createUserWithEmailAndPassword(this.auth, email, password);
      this.authState.next(true);
      await this.setUser2(credentials.user.uid);
      return credentials;
    } catch (err) {
      throwIfEmpty(err)
    }
  }

  async registerFacebookUser() {
    const provider = new FacebookAuthProvider;
    try {
      const credentials = await signInWithPopup(this.auth, provider)

      // const c = FacebookAuthProvider.credentialFromResult(credentials);
      // const accessToken = c.accessToken

      return credentials;
    } catch (error) {
      console.log({ error })
    }
  }

  async changeEmail(email: string, password: string, newEmail: string) {

    const userCredential = await firebase.auth()
      .signInWithEmailAndPassword(email, password);

    console.log({ userCredential });
    userCredential.user?.updateEmail(newEmail);
  }

  async deleteAccount(email: string, password: string) {
    
    const userCredential = await firebase.auth()
      .signInWithEmailAndPassword(email, password);
    this.removeUser();
    userCredential.user?.delete();
  }

  async loginUser(value): Promise<UserCredential> {
    const { email, password } = value;
    try {
      const credentials = await signInWithEmailAndPassword(this.auth, email, password);
      this.setUser2(credentials.user.uid);
      this.authState.next(true);
      return credentials;
    } catch (err) {
      throw (err)
    }
  }

  async setUser2(uid: string) {
    return await Storage.set(
      {
        key: 'currentUser',
        value: uid,
      }
    )
  }

  async currentUser() {
    const authCurrentUser = this.auth.currentUser;
    return authCurrentUser ? authCurrentUser.uid : (await Storage.get({ key: 'currentUser' })).value;

  }

  async logoutUser() {

    try {
      await signOut(this.auth);
      await this.removeUser();
      this.authState.next(false);
    } catch (err) {
      console.log('bad return tryinig to logout user', err);
      throw (err);
    }
  }

  async removeUser() {
    return await Storage.remove(
      {
        key: 'currentUser',
      }
    )
  }

  sendPasswordResetEmail(email: string) {
    firebase.auth().sendPasswordResetEmail(email)
  }

  isAuthenticated() {
    return this.authState.value;
  }
}
