

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpService {

  constructor(
    private http: HttpClient,
  ) { }


  searchByCourseString(str: string) {

    const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/findCourses?courseName=${str}`

    return this.http.get(url)
  }

  async getHandicapHistories(handicapIdentifier: string) {

    const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/getHandicapHistories?handicapIdentifier=${handicapIdentifier}`

    return this.http.get(url)
  }

  async getGolferInfo(handicapIdentifier: string) {

    try {
      const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/getGHINInfor?handicapIdentifier=${handicapIdentifier}`;
      const z = await this.http.get(url);
      console.log({ z })
      return z
      return this.http.get(url)
    }
    catch (error) {
      console.log({ error })
    }

  }
}



