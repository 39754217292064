import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-skin-ctp-summary-modal',
  templateUrl: './skin-ctp-summary-modal.page.html',
  styleUrls: ['./skin-ctp-summary-modal.page.scss'],
})
export class SkinCtpSummaryModalPage implements OnInit {
  skinCtpDetails = [];
  
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    this.getNavParams();
  }

  getNavParams() {
    this.skinCtpDetails = this.navParams.get('skinCtpDetails');
  }

  headerDesc() {
    return this.hasCtps ? 'Skins/CTP Summary' : 'Skins Summary';
  }

  hasCtps() {
    return this.skinCtpDetails && this.skinCtpDetails.length > 0 && this.skinCtpDetails.includes(s => s.type === 2);
  }

  return() {
    this.modalCtrl.dismiss();
  }
}
