import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class UnpostedScoreService extends BaseService {

  node = 'unposted-scores';

  getall() {

    return this.afs.collection(this.node);
  }
}