import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HomeGuard  {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { };
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise<boolean>(async (resolve, reject) => {
 
      const currentUser = await this.authService.currentUser()
     
      if (currentUser === null || currentUser === undefined) {
        resolve(true);
      } else {
        this.router.navigate(['dashboard']);
        resolve(false);
      }
    }); 
  } 
}



