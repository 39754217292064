import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class EventSkinService extends BaseService {

    node = 'event-skin';

      getByDocId(id) {
        return this.afs.collection(this.node).doc(id);
      }

      getListForEvent(eventId: string) {
        return this.afs.collection(this.node, ref =>
            ref.where('eventId', '==', eventId)
            .orderBy('holeIndex', 'asc'));
      }
}
