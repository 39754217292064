import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';

@Injectable()
export class EventBetService extends BaseService {

    node = 'event-bet';

      getByDocId(id) {
        return this.afs.collection(this.node).doc(id);
      }

      async getByDocIdMod(id: string) {
        const db = getFirestore();
        const docRef = doc(db, this.node, id);
        const docSnap = await getDoc(docRef);
        return docSnap.data();
      }
    
      getEventBetListForUserAndEvent(user: string, eventId: string) {
        return this.afs.collection(this.node, ref => 
            ref.where('eventId', '==', eventId)
             .where('players', 'array-contains', user));
      }

      getForEvent(eventId: string) {
        return this.afs.collection(this.node, ref => 
            ref.where('eventId', '==', eventId));
      }

      delete(id: string) {
        return this.afs.collection(this.node).doc(id).delete();
    }

    getEventBetListForBetId(betId: string) {
      return this.afs.collection(this.node, ref => 
        ref.where('betId', '==', betId));
    }

    async getEventBetListForBetIdMod(betId: string) {
      const db = getFirestore();
      const q = query(
        collection(db, this.node),
        where('betId', '==', betId),
      );
  
      return (await getDocs(q)).docs;
    }

    updateBalls(eventBetId: string, balls: []) {
        return this.afs.collection(this.node).doc(eventBetId).update({ 'balls': balls });
    }

    updateSides(eventBetId: string, sides: []) {
      return this.afs.collection(this.node).doc(eventBetId).update({ 'sides': sides });
  }

    async test(betId, eventId) {
      
        const db = getFirestore();
        const q = query(
          collection(db, 'event-bet-detail'),
          where('betId', '==', betId),
          where('eventId', '==', eventId)
        );
    
        return (await getDocs(q)).docs;
    }
}
