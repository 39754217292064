import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PairingAddedEventService extends BaseService {

    node = 'pairing-added-event';

    getForPairingId(pairingId: string) {
        return this.afs.collection(this.node, ref => ref
            .where('pairingId', '==', pairingId)
        )
    }

    deletePairingAddedEvent(pairingId: string) {
        return this.afs.collection(this.node).doc(pairingId).delete();
    }
}