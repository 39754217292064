import { Component } from '@angular/core';
import { Platform  } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'User Preferences',
      url: '/member',
      icon: 'options'
    },
/*     {
      title: 'Your Dashboard',
      url: '/dashboard',
      icon: 'beer'
    }, */
    {
      title: 'New Bet',
      url: '/bet',
      icon: 'cash'
    },
    {
      title: 'New Event',
      url: '/event',
      icon: 'calendar'
    },
    {
      title: 'New Group',
      url: '/group',
      icon: 'people'
    },
    /*   {
        title: 'New Course',
        url: '/course',
        icon: 'add'
      }, */
    {
      title: 'Open Events',
      url: '/open-event',
      icon: 'lock-open'
    },
    /*  {
     title: 'Bet Summary',
     url: '/bet-summary',
     icon: 'add'
   }, */
    {
      title: 'Daily Summary',
      url: '/daily-summary',
      icon: 'list'
    },
    {
      title: 'Your Stats',
      url: '/individual-stats',
      icon: 'stats-chart'
    },
    {
      title: 'Group Stats',
      url: '/group-stats',
      icon: 'stats-chart'
    },
    {
      title: 'Info',
      url: '/app-info',
      icon: 'information-circle'
    },
  ];
  constructor(
    private platform: Platform,
    //private splashScreen: SplashS,
    // private statusBar: StatusBarOriginal,
  ) {
    this.initializeApp()
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      //this.splashScreen.hide();
    });
  }
}
