import { collection, getDocs, getFirestore, limit, orderBy, query, where } from '@angular/fire/firestore';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';

@Injectable()
export class PairingUpdateEventService extends BaseService {

  node = 'pairing-update-event';

  getForPairingAndUser(pairingId: string, userId: string) {
    return this.afs.collection(this.node, ref => ref
      .where('pairing', '==', pairingId)
      .where('updateOpId', '==', userId)
      .orderBy('updateTS', 'desc')
      .limit(1),
    )
  }

  getForPairingIdMod(pairingId: string) {
    const db = getFirestore();
    const q = query(collection(db, this.node), where('pairing', '==', pairingId), orderBy('updateTS', 'desc'), limit(1));
    return from(getDocs(q));

  }

  delete(id: string) {
    return this.afs.collection(this.node).doc(id).delete();
  }
}