import { Injectable } from '@angular/core';

export interface Types {
  value: string;
  desc: string;
}

export interface NumberTypes {
  value: number;
  desc: string;
}
export interface PointTypes {
  value: number;
  desc: string;
  autoCalc: boolean;
  selectPartners: boolean,
  hasTeams: boolean,
  hasProx: boolean,
  hasTwists: boolean,
  hasTurns: boolean,
  teamSize: number,
}
@Injectable()
export class GlobalService {

  constructor(

  ) { }

  public eventFormatTypes: Types[] =
    [
      { value: '1', desc: 'Individual Scoring' },
      { value: '2', desc: 'Two Man Net Swing' },
      { value: '3', desc: 'Team Event'}
    ];

  public eventTeeTimeTypes: Types[] =
    [
      { value: '1', desc: 'Tee Times' },
      { value: '2', desc: 'Shotgun' }
    ];

  public playersPerGroupTypes: Types[] =
    [ 
      { value: '2', desc: '2' },
      { value: '3', desc: '3' },
      { value: '4', desc: '4' },
      { value: '5', desc: '5' }
    ];

  public betFormats: NumberTypes[] =
    [
      { value: 0, desc: 'Auto two-down press - std ' },
      { value: 1, desc: 'Auto press - non-std' },
      { value: 2, desc: 'Match play - front, back, and total'},
      { value: 3, desc: 'Match play - front and back'},
      { value: 8, desc: 'Match play - total'},
      { value: 4, desc: 'Nassau'},
      { value: 7, desc: 'Per hole'},
      { value: 5, desc: 'Vegas'},
      { value: 6, desc: 'Vegas with max'}
    ];

  public numberOfPressesOptions: NumberTypes[] = 
    [
      { value: 0, desc: 'Limited'},
      { value: 1, desc: 'Unlimited'},
    ]

  public numberOfBalls: NumberTypes[] =
    [
      { value: 1, desc: '1' },
      { value: 2, desc: '2' },
      { value: 3, desc: '3' },
      { value: 4, desc: '4' },
      { value: 5, desc: '5' },
      { value: 6, desc: '6' },
      { value: 7, desc: '7' },
      { value: 8, desc: '8' },
    ];

    public scoresToCount: NumberTypes[] =
    [
      { value: 1, desc: '1' },
      { value: 2, desc: '2' },
      { value: 3, desc: '3' },
      { value: 4, desc: '4' },
      { value: 5, desc: '5' },
    ];

  public autoDownNumbers: NumberTypes[] =
    [   
      { value: 1, desc: '1' },
      { value: 2, desc: '2' },
      { value: 3, desc: '3' },
      { value: 18, desc: 'None' },
    ];

  public betNumberOfHoles: NumberTypes[] =
    [
      { value: 9, desc: '9' },
      { value: 18, desc: '18' },
    ];

    public skinsFormats: NumberTypes[] =
    [
      { value: 0, desc: 'None' },
      { value: 1, desc: 'Gross' },
      { value: 2, desc: 'Net' },
      { value: 3, desc: 'Gross caps net' },
      { value: 4, desc: 'Gross caps net only birdie or better' },
    ];

    public handicapMethods: NumberTypes[] =
    [
      { value: 1, desc: 'Full' },
      { value: 2, desc: 'Based on low' },
    ];

    public betHandicapMethods: NumberTypes[] =
    [
      { value: 1, desc: 'Gross' },
      { value: 2, desc: 'Net' },
      // { value: 2, desc: 'Net - based on low handicap' },
     // { value: 3, desc: 'Net - full handicaps' },
      { value: 4, desc: 'Manual' },
    ];

    public betTypes: NumberTypes[] =
    [
      { value: 1, desc: 'Offer' },
    //  { value: 2, desc: 'One-time' },
      { value: 3, desc: 'Standing' },
    ];

    public eventHandicapMethods: NumberTypes[] =
    [
      { value: 1, desc: 'Gross' },
      { value: 2, desc: 'Net' },
    ];

    public pointsHandicapMethods: NumberTypes[] =
    [
      { value: 1, desc: 'Gross' },
      { value: 2, desc: 'Net - based on low' },
      { value: 3, desc: 'Net - full handicaps' },
      { value: 4, desc: 'Custom' },
    ];

  public skinsOptions: NumberTypes[] =
    [
      { value: 1, desc: 'Flat amount' },
      { value: 2, desc: 'Per skin'}
    ];

    public ctpOptions: NumberTypes[] =
    [
      { value: 1, desc: 'None' },
      { value: 2, desc: 'As a skin' },
      { value: 3, desc: 'Flat amount' },
      { value: 4, desc: 'Per CTP'}
    ]; 

    public monsterOptions: NumberTypes[] =
    [
      { value: 1, desc: 'None' },
      { value: 2, desc: 'As a skin' },
      { value: 3, desc: 'Flat amount' },
      { value: 4, desc: 'Per monster'}
    ];

    
    public birdOrBetOptions: NumberTypes[] =
    [
      { value: 1, desc: 'None' },
      { value: 2, desc: 'As a skin' },
      { value: 3, desc: 'Flat amount' },
      { value: 4, desc: 'Per birdie'}
    ];

    public vegasMaxOptions: NumberTypes[] =
    [
      { value: 2, desc: 'Double' },
      { value: 3, desc: 'Triple' },
      { value: 4, desc: 'Quad' },
    ];

    public flightOptions: NumberTypes[] =
    [
      { value: 1, desc: 'None' },
      { value: 2, desc: 'Number of flights' },
      { value: 3, desc: 'Max players per flight' },
    ];

    public skinsFlightDistributionOptions: NumberTypes[] = 
    [
      { value: 1, desc: 'Equal number' },
      { value: 2, desc: 'Handicap range'},
    ]

    public leaderboardOptions: NumberTypes[] =
    [
      { value: 1, desc: 'Show all scores' },
      { value: 2, desc: 'Show number of holes played' },
    ];

    public scorecardOptions: NumberTypes[] =
    [
      { value: 1, desc: 'Gross' },
      { value: 2, desc: 'Net' },
      { value: 3, desc: 'Skins'},
      { value: 4, desc: 'Points' },
    ];

    public scoreTypeOptionsAll: NumberTypes[] =
  [
    { value: 1, desc: 'Gross' },
    { value: 2, desc: 'Net' },
    { value: 3, desc: 'Quota' },
    { value: 4, desc: 'Stableford - gross'},
    { value: 5, desc: 'Stableford - net'},
    { value: 6, desc: 'Skins'},
  ];

  public scoreTypeOptionsBasic: NumberTypes[] =
  [
    { value: 1, desc: 'Gross' },
    { value: 2, desc: 'Net' },
    { value: 6, desc: 'Skins'},
  ];

  public auto3and1PressOptions: NumberTypes[] =
  [
    { value: 1, desc: 'Immediately' },
    { value: 2, desc: 'Last hole' },
  ];

  public genders: Types[] = 
  [
    {value: 'Male', desc: 'Male'},
    {value: 'Female', desc: 'Female'}
  ]

  public teamFormats: NumberTypes[] =
  [
    { value: 1, desc: 'Best ball' },
    { value: 2, desc: 'Shamble' },
    { value: 3, desc: 'Scramble' },
    { value: 4, desc: 'Quota'},
  ];

  public teamScoresToCount: NumberTypes[] =
  [
    { value: 0, desc: 'None' },
    { value: 1, desc: '1' },
    { value: 2, desc: '2' },
    { value: 3, desc: '3' },
    { value: 4, desc: '4' },
  ];

  public teamSizes: NumberTypes[] =
  [
    { value: 1, desc: '1' },
    { value: 2, desc: '2' },
    { value: 3, desc: '3' },
    { value: 4, desc: '4' },
    { value: 5, desc: '5' },
  ];

  public pressHoles: NumberTypes[] =
  [
    { value: 1, desc: '1' },
    { value: 2, desc: '2' },
    { value: 3, desc: '3' },
    { value: 4, desc: '4' },
    { value: 5, desc: '5' },
    { value: 6, desc: '6' },
    { value: 7, desc: '7' },
    { value: 8, desc: '8' },
    { value: 9, desc: '9' },
    { value: 10, desc: '10' },
    { value: 11, desc: '11' },
    { value: 12, desc: '12' },
    { value: 13, desc: '13' },
    { value: 14, desc: '14' },
    { value: 15, desc: '15' },
    { value: 16, desc: '16' },
    { value: 17, desc: '17' },
    { value: 18, desc: '18' },
  ];

  public teamUnevenMethods: NumberTypes[] =
  [
    { value: 0, desc: 'None' },
    { value: 1, desc: 'Rotate players' },
  ];

  public pointsDescription: PointTypes[] =
  [
   // { value: 0, desc: 'Generic', autoCalc: false, selectPartners: false, hasTeams: false, hasProx: false, hasTwists: false, hasTurns: false, teamSize: 0 },
    { value: 1, desc: 'Flip Wolf', autoCalc: true, selectPartners: true, hasTeams: false, hasProx: false, hasTwists: false, hasTurns: false, teamSize: 0 },
  //  { value: 2, desc: 'Left Right', autoCalc: true, selectPartners: true, hasTeams: false, hasProx: false, hasTwists: false, hasTurns: false, teamSize: 0 },
    { value: 3, desc: 'Nine Point', autoCalc: true, selectPartners: false, hasTeams: false, hasProx: false, hasTwists: false, hasTurns: false, teamSize: 0 },
    { value: 4, desc: 'Scotch', autoCalc: true, selectPartners: false, hasTeams: true, hasProx: true, hasTwists: true, hasTurns: true, teamSize: 2 },
    { value: 5, desc: 'Wolf', autoCalc: true, selectPartners: true, hasTeams: false, hasProx: false, hasTwists: false, hasTurns: false, teamSize: 0 },
    { value: 6, desc: 'Wolf-Low and Total', autoCalc: true, selectPartners: true, hasTeams: false, hasProx: false, hasTwists: false, hasTurns: false, teamSize: 0},
  ];

  public billingUpdateOptions: NumberTypes[] =
  [
    { value: 1, desc: 'Update Expiration Date' },
    { value: 2, desc: 'Add New Card' },
    { value: 3, desc: 'Change Plan' },
  ];

  getBetNumberOfHoles() {
    return this.betNumberOfHoles;
  }

  getAutoDownNumbers() {
    return this.autoDownNumbers;
  }

  getNumberOfBalls() {
    return this.numberOfBalls;
  }

  getBetFormats() {
    return this.betFormats;
  }

  getNumberOfPressesOptions() {
    return this.numberOfPressesOptions;
  }
  
  getBetTypes() {
    return this.betTypes;
  }

  getStates() {
    return this.statesArray;
  }

  getEventTeeTimeTypes() {
    return this.eventTeeTimeTypes;
  }

  getEventFormatTypes() {
    return this.eventFormatTypes;
  }

  getPlayersPerGroupTypes() {
    return this.playersPerGroupTypes;
  }

  getSkinsFormats() {
    return this.skinsFormats;
  }

  getHandicapMethods() {
    return this.handicapMethods;
  }

  getBetHandicapMethods() {
    return this.betHandicapMethods;
  }

  getskinsOptions() {
    return this.skinsOptions;
  }

  getSkinsFlightDistributionOptions() {
    return this.skinsFlightDistributionOptions;
  }

  getCtpOptions() {
    return this.ctpOptions;
  }

  getMonsterOptions() {
    return this.monsterOptions;
  }

  getBirdOrBetOptions() {
    return this.birdOrBetOptions;
  }

  getVegasMaxOptions() {
    return this.vegasMaxOptions;
  }

  getScoresToCount() {
    return this.scoresToCount;
  }

  getFlightOptions() {
    return this.flightOptions;
  }

  getLeaderbardOptions() {
    return this.leaderboardOptions;
  }

  getScorecardOptions() {
    return this.scorecardOptions;
  }
  
  getScoreTypeOptionsAll() {
    return this.scoreTypeOptionsAll;
  }

  getScoreTypeOptionsBasic() {
    return this.scoreTypeOptionsBasic;
  }

  getEventHandicapMethods() {
    return this.eventHandicapMethods;
  }

  getPointsHandicapMethods() {
    return this.pointsHandicapMethods;
  }

  getAuto3and1PressOptions() {
    return this.auto3and1PressOptions;
  }

  getGenders() {
    return this.genders;
  }

  getTeamFormats() {
    return this.teamFormats;
  }

  getTeamScoresToCount() {
    return this.teamScoresToCount;
  }

  getTeamSizes() {
    return this.teamSizes;
  }

  
  getTeamUnevenMethods() {
    return this.teamUnevenMethods;
  }

  getPressHoles() {
    return this.pressHoles;
  }

  getPointsDescription() {
    return this.pointsDescription;
  }

  getBillingUpdateOptions() {

    return this.billingUpdateOptions;
  }

  public statesArray: Types[] =
    [
      { value: '0', desc: 'Alabama' },
      { value: '1', desc: 'Alaska' },
      { value: '2', desc: 'Arizona' },
      { value: '3', desc: 'Arkansas' },
      { value: '4', desc: 'California' },
      { value: '5', desc: 'Colorado' },
      { value: '6', desc: 'Connecticut' },
      { value: '7', desc: 'Deleware' },
      { value: '8', desc: 'Florida' },
      { value: '9', desc: 'Georgia' },
      { value: '10', desc: 'Hawaii' },
      { value: '11', desc: 'Idaho' },
      { value: '12', desc: 'Illinois' },
      { value: '13', desc: 'Indiana' },
      { value: '14', desc: 'Iowa' },
      { value: '15', desc: 'Kansas' },
      { value: '16', desc: 'Kentucky' },
      { value: '17', desc: 'Louisiana' },
      { value: '18', desc: 'Maine' },
      { value: '19', desc: 'Maryland' },
      { value: '20', desc: 'Massachusetts' },
      { value: '21', desc: 'Michigan' },
      { value: '22', desc: 'Minnesota' },
      { value: '23', desc: 'Mississippi' },
      { value: '24', desc: 'Missouri' },
      { value: '25', desc: 'Montana' },
      { value: '26', desc: 'Nebraska' },
      { value: '27', desc: 'Nevada' },
      { value: '28', desc: 'New Hampshire' },
      { value: '29', desc: 'New Jersey' },
      { value: '30', desc: 'New Mexico' },
      { value: '31', desc: 'New York' },
      { value: '32', desc: 'North Carolina' },
      { value: '33', desc: 'North Dakota' },
      { value: '34', desc: 'Ohio' },
      { value: '35', desc: 'Oklahoma' },
      { value: '36', desc: 'Oregon' },
      { value: '37', desc: 'Pennsylvania' },
      { value: '38', desc: 'Rhode Island' },
      { value: '39', desc: 'South Carolina' },
      { value: '40', desc: 'South Dakota' },
      { value: '41', desc: 'Tennessee' },
      { value: '42', desc: 'Texas' },
      { value: '43', desc: 'Utah' },
      { value: '44', desc: 'Vermont' },
      { value: '45', desc: 'Virginia' },
      { value: '46', desc: 'Washington' },
      { value: '47', desc: 'West Virginia' },
      { value: '48', desc: 'Wisconsin' },
      { value: '49', desc: 'Wyoming' }
    ];
}
