import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, orderBy, getDocs, getFirestore, query, where, limit } from 'firebase/firestore';

@Injectable()
export class HandicapHistoryService extends BaseService {

  node = 'handicap-history';

  async getForMemberIdAndDateMod(memberId: string, asOfDate: string) {
    const db = getFirestore(); 
    const q = query(
      collection(db, this.node),
      where('memberId', '==', memberId),
      where('revDate', '<=', asOfDate),
      orderBy('revDate', 'desc'),
      limit(1)
    )
    return (await getDocs(q)).docs;

  }
  getForMemberIdAndDate(memberId: string, asOfDate: string) {

    return this.afs.collection(this.node, ref =>
      ref.where('memberId', '==', memberId)
        .where('revDate', '<=', asOfDate)
        .orderBy('revDate', 'desc')
        .limit(1));
  }
}