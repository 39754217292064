import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, getDocs, getFirestore, orderBy, query, where, } from 'firebase/firestore';

@Injectable()
export class PcourseMemberService extends BaseService {

  node = 'pcourse-member';

  async getForCourse(course: string) {
      const db = getFirestore(); 
      const q = query(
        collection(db, this.node),
        where('course', '==', course),
        orderBy('firstname', 'asc'),
        orderBy('lastname', 'asc'),
      )
      return (await getDocs(q)).docs;
  }

}
