import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class EventSummaryAdjustmentService extends BaseService {

  node = 'event-summary-adjustment';

  createAdjustment(data) {
    return super.create(this.node, data);
  }

  getEventSummaryAdjustmentForEventId(eventId: string) {
    return this.afs.collection(this.node,
      ref => ref.where('eventId', '==', eventId));
  }
}