import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { IonInput, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-other-score-modal',
  templateUrl: './other-score-modal.page.html',
  styleUrls: ['./other-score-modal.page.scss'],
})
export class OtherScoreModalPage {
  @ViewChild('input') input!: IonInput;

  isSubmitted = false;
  validations = {
    score: [
      { type: 'required', message: 'A score is required' },
    ],
  }

  otherScoreForm = this.fb.group({
    score: new FormControl('', Validators.required),
  });

  
  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
  ) { }

  ionViewDidEnter() {
    this.input.setFocus();
  }

  submitForm() {
    if (this.otherScoreForm.valid) {
      this.isSubmitted = true;
      const data = this.otherScoreForm.value;
      this.modalCtrl.dismiss({
        otherScore: data.score.toString(),
      })
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  get score() {
    return this.otherScoreForm.get('score');
  }
}
