export const groupValidations = {
    groupName: [
      { type: 'required', message: 'A group name is required' },
    ],
    courses: [
      { type: 'required', message: 'A course is required' },
    ],
    tee: [
      { type: 'required', message: 'A tee is required' },
    ],
    eventDate: [
      { type: 'required', message: 'An event date is required' },
    ],
    eventTeeTimeType: [
      { type: 'required', message: 'Please select tee time or shotgun' },
    ],
    numberOfTimes: [
      { type: 'required', message: 'Number of tee times is required' },
    ],
    eventFormatType: [
      { type: 'required', message: 'Event format is required' },
    ],
    startingTime: [
      { type: 'required', message: 'The starting time is required' },
    ],
    invites: [
      { type: 'validEmailAddress', message: 'At least one email address is incorrect, please correct' },
    ],
    newInvites: [
      { type: 'validEmailAddress', message: 'At least one email address is incorrect, please correct' },
    ],
    handicapMethod: [
      { type: 'required', message: 'Handicap method is required' },
    ],
    skinsHandicapPercentage: [
      { type: 'required', message: 'Handicap percentage is  required' },
      { type: 'netBetween1and100', message: 'Handicap percentage must between 1 and 100' },
    ],
    maxHandicapStrokes: [
      { type: 'required', message: 'Max handicap strokes is required' },
      { type: 'greaterThanZero', message: 'Max handicap strokes must be greated than zero' },
    ],
    skinsOption: [
      { type: 'required', message: 'Skins option is required' },
    ],
    skinsAmount: [
      { type: 'required', message: 'Skins amount is required' },
      { type: 'greaterThanZero', message: 'Skins amount must be greater than zero' },
    ],
    ctpAmount: [
      { type: 'required', message: 'A closest to the pin throw in amount is required' },
      { type: 'greaterThanZero', message: 'The closest to the pin throw in amount must be greater than zero' },
    ],
    monsterAmount: [
      { type: 'required', message: 'Monster amount is required' },
      { type: 'greaterThanZero', message: 'Monster amount must be greater than zero' },
    ],
    birdOrBetAmount: [
      { type: 'required', message: 'Amount is required' },
      { type: 'greaterThanZero', message: 'Amount must be greater than zero' },
    ],
    foMaxNumberOfPlayers: [
      { type: 'required', message: 'Max number of players is required' },
      { type: 'greaterThanZero', message: 'Max number of players must be greater than zero' },
    ],
    foNumberOfFlights: [
      { type: 'required', message: 'Number of flights is required' },
      { type: 'greaterThanZero', message: 'Number of flights must be greater than zero' },
    ],
    skinsNumberOfFlights: [
      { type: 'required', message: 'Number of flights for skins is required' },
      { type: 'greaterThanOne', message: 'Number of flights for skins must be greater than one' },
    ],
    eventHandicapPercentage: [
      { type: 'required', message: 'Event handicap percentage is required' },
      { type: 'between1and100', message: 'Event handicap percentage must between 1 and 100' },
    ],
    doubleEagleAmount: [
      { type: 'required', message: 'A value for double eagle amount is required' },
    ],
    eagleAmount: [
      { type: 'required', message: 'A value for eagle amount is required' },
    ],
    birdieAmount: [
      { type: 'required', message: 'A value for birdie amount is required' },
    ],
    parAmount: [
      { type: 'required', message: 'A value for par amount is required' },
    ],
    bogeyAmount: [
      { type: 'required', message: 'A value for bogey amount is required' },
    ],
    otherAmount: [
      { type: 'required', message: 'A value for other amount is required' },
    ],
    teamFormat: [
      { type: 'required', message: 'Team format must be selected' },
    ],
    teamSize: [
      { type: 'required', message: 'Team size must be selected' },
      { type: 'greaterThanZero', message: 'Team size must be greater than zero' },
    ],
    grossScoresToCount: [
      { type: 'required', message: 'Gross scores to count must be selected' },
    ],
    netScoresToCount: [
      { type: 'required', message: 'Net scores to count must be selected' },
    ],
    teamUnevenMethod: [
      { type: 'required', message: 'Team method for uneven number of players is required' },
    ],
    teeTimeIncrement: [
      { type: 'required', message: 'Tee time increment is required' },
    ],
    group: [
      { type: 'required', message: 'Group is required for Standing bet' },
    ],
    lowGrossBuyIn: [
      { type: 'required', message: 'Buy in for low gross is required' },
      { type: 'min', message: 'Buy in for low gross must be greater than 0' },
    ],
    numberOfLowGross: [
      { type: 'required', message: 'Low gross count is required' },
      { type: 'min', message: 'Low gross count must be greater than 0' },
    ],
    lowNetBuyIn: [
      { type: 'required', message: 'Buy in for low net is required' },
      { type: 'min', message: 'Buy in for low net must be greater than 0' },
    ],
    numberOfLowNet: [
      { type: 'required', message: 'Low net count is required' },
      { type: 'min', message: 'Low net count must be greater than 0' },
    ],
    percentage: [
      { type: 'required', message: 'Percentage is required' },
    ],
    excludedStrokeHoles: [
      { type: 'required', message: 'At least one skin stroke hole exclusion is required' },
    ],
    additionalCTPHoles: [
      { type: 'required', message: 'At least one additional CTP is required' },
    ],
    otherCourses: [
      { type: 'required', message: 'At least one course is required, if you only need a primary course, click the Use Multiple Courses toggle above to off' },
    ],
    startingHole: [
      { type: 'required', message: 'The usual starting hole is required' },
      { type: 'between1and18', message: 'Starting hole must be between 1 and 18' },
    ],
    skinsFlightDistributionOption: [
      { type: 'required', message: 'A skins flight distribution option is required' },
    ],
    name: [
      { type: 'required', message: 'A flight name is required' },
    ],
    bottom: [
      { type: 'required', message: 'A low handicap value is required' },
    ],
    top: [
      { type: 'required', message: 'A high hanidicap value is required' },
    ],
  };
  
  export const userValidations = {
    firstname: [
      { type: 'required', message: 'A first name is required' },
    ],
    lastname: [
      { type: 'required', message: 'A last name is required' },
    ],
    email: [
      { type: 'email', message: 'Please enter a valid email' },
    ],
    gender: [
      { type: 'gender', message: 'Gender is required' },
    ],
    displayHandicapIndex: [
      { type: 'pattern', message: 'Please enter a valid GHIN index' },
    ],
  };
  
  export const teeValidations = {
    preferredTee: [
      { type: 'required', message: 'A preferred tee is required' },
    ],
  };

  export const betValidations = {
    event: [
      { type: 'required', message: 'An event is required for this bet' },
    ],
    vegasMax: [
      { type: 'required', message: 'A max score is required for bet format of vegas with max' },
    ],
    team1members: [
      { type: 'required', message: 'Team 1 members are required' },
      { type: 'tooFewSelected', message: 'At least 2 team members are required for selected bet format' },
    ],
    team2members: [
      { type: 'required', message: 'An opponent(s) is required' },
      { type: 'tooFewSelected', message: 'At least 2 team members are required for selected bet format' },
    ],
    strokes: [
      { type: 'required', message: 'Please enter handicap strokes' },
    ],
    betAmount: [
      { type: 'required', message: 'Please enter a bet amount' },
      { type: 'greaterThanZero', message: 'Please enter a bet amount greater than zero' },
    ],
    maxNumberOfPresses: [
      { type: 'required', message: 'Please enter a value for max number of presses - typically 1' },
      { type: 'greaterThanZero', message: 'Please enter a max number of presses greater than zero' },
    ],
    auto3and1PressOption: [
      { type: 'required', message: 'Please select and option for auto 3 and 1 press option timing' },
    ],
    group: [
      { type: 'required', message: 'Please select group for this bet' },
    ],
  };
  
  export const eventValidations = {
    groups: [
      { type: 'required', message: 'At least one group is required' },
    ],
    course: [
      { type: 'required', message: 'Course is required' },
    ],
    tee: [
      { type: 'required', message: 'Tee is required' },
    ],
    eventDate: [
      { type: 'required', message: 'Event date is required' },
    ],
    eventTeeTimeType: [
      { type: 'required', message: 'Please select tee time or shotgun' },
    ],
    numberOfTimes: [
      { type: 'required', message: 'Number of tee times is required' },
      { type: 'greaterThanZero', message: 'Number of times must be greater than zero' },
    ],
    eventFormatType: [
      { type: 'required', message: 'Event format is required' },
    ],
    startingTime: [
      { type: 'required', message: 'The starting time is required' },
      { type: 'pattern', message: 'Please enter a valid starting time'},
    ],
    skinsOption: [
      { type: 'required', message: 'Skins option is required' },
    ],
    skinsAmount: [
      { type: 'required', message: 'Skins amount is required' },
      { type: 'greaterThanZero', message: 'Skins amount must be greater than zero' },
    ],
    handicapMethod: [
      { type: 'required', message: 'Handicap method is required' },
    ],
    maxHandicapStrokes: [
      { type: 'required', message: 'Max handicap strokes is required' },
      { type: 'greaterThanZero', message: 'Max handicap strokes must be greated than zero' },
    ],
    skinsHandicapPercentage: [
      { type: 'required', message: 'Handicap percentage is required' },
      { type: 'between1and100', message: 'Handicap percentage must between 1 and 100' },
    ],
    eventHandicapPercentage: [
      { type: 'required', message: 'Event handicap percentage is required' },
      { type: 'between1and100', message: 'Event handicap percentage must between 1 and 100' },
    ],
    ctpAmount: [
      { type: 'required', message: 'A closest to the pin throw in amount is required' },
      { type: 'greaterThanZero', message: 'The closest to the pin throw in amount must be greater than zero' },
    ],
    monsterAmount: [
      { type: 'required', message: 'Monster in amount is required' },
      { type: 'greaterThanZero', message: 'Monster amount must be greater than zero' },
    ],
    birdOrBetAmount: [
      { type: 'required', message: 'Amount is required' },
      { type: 'greaterThanZero', message: 'Amount must be greater than zero' },
    ],
    foMaxNumberOfPlayers: [
      { type: 'required', message: 'Max number of players is required' },
      { type: 'greaterThanZero', message: 'Max number of players must be greater than zero' },
    ],
    foNumberOfFlights: [
      { type: 'required', message: 'Number of flights is required' },
      { type: 'greaterThanZero', message: 'Number of flights must be greater than zero' },
    ],
    skinsNumberOfFlights: [
      { type: 'required', message: 'Number of flights for skins is required' },
      { type: 'greaterThanOne', message: 'Number of flights for skins must be greater than one' },
    ],
    doubleEagleAmount: [
      { type: 'required', message: 'A value for double eagle amount is required' },
    ],
    eagleAmount: [
      { type: 'required', message: 'A value for eagle amount is required' },
    ],
    birdieAmount: [
      { type: 'required', message: 'A value for birdie amount is required' },
    ],
    parAmount: [
      { type: 'required', message: 'A value for par amount is required' },
    ],
    bogeyAmount: [
      { type: 'required', message: 'A value for bogey amount is required' },
    ],
    otherAmount: [
      { type: 'required', message: 'A value for other amount is required' },
    ],
    teamFormat: [
      { type: 'required', message: 'Team format must be selected' },
    ],
    teamSize: [
      { type: 'required', message: 'Team size must be selected' },
      { type: 'greaterThanZero', message: 'Team size must be greater than zero' },
    ],
    grossScoresToCount: [
      { type: 'required', message: 'Gross scores to count must be selected' },
    ],
    netScoresToCount: [
      { type: 'required', message: 'Net scores to count must be selected' },
    ],
    teamUnevenMethod: [
      { type: 'required', message: 'Team method for uneven number of players is required' },
    ],
    teamEventAmount: [
      { type: 'required', message: 'Amount is required for the team event' },
      { type: 'greaterThanZero', message: 'Team amount must be greater than zero' },
    ],
    teeTimeIncrement: [
      { type: 'required', message: 'Tee time increment is required' },
    ],
    lowGrossBuyIn: [
      { type: 'required', message: 'Buy in for low gross is required' },
      { type: 'min', message: 'Buy in for low gross must be greater than 0' },
    ],
    numberOfLowGross: [
      { type: 'required', message: 'Low gross count is required' },
      { type: 'min', message: 'Low gross count must be greater than 0' },
    ],
    lowNetBuyIn: [
      { type: 'required', message: 'Buy in for low net is required' },
      { type: 'min', message: 'Buy in for low net must be greater than 0' },
    ],
    numberOfLowNet: [
      { type: 'required', message: 'Low net count is required' },
      { type: 'min', message: 'Low net count must be greater than 0' },
    ],
    percentage: [
      { type: 'required', message: 'Percentage is required' },
    ],
    playersPerGroup: [
      { type: 'required', message: 'Players per group is required' },
    ],
    excludedStrokeHoles: [
      { type: 'required', message: 'At least one skin stroke hole exclusion is required' },
    ],
    additionalCTPHoles: [
      { type: 'required', message: 'At least one additional CTP is required' },
    ],
    startingHole: [
      { type: 'required', message: 'The usual starting hole is required' },
      { type: 'between1and18', message: 'Starting hole must be between 1 and 18' },
    ],
    skinsFlightDistributionOption: [
      { type: 'required', message: 'A skins flight distribution Option is required' },
    ],
    skinsFlightSeperator: [
      { type: 'required', message: 'A skins flight dividing handicap is required' },
    ],
    name: [
      { type: 'required', message: 'A flight name is required' },
    ],
    bottom: [
      { type: 'required', message: 'A low handicap value is required' },
    ],
    top: [
      { type: 'required', message: 'A high hanidicap value is required' },
    ],
  };
  
  export const openEventValidations = {
    course: [
      { type: 'required', message: 'Please select a course' },
    ],
    event: [
      { type: 'required', message: 'Please select an event to join' },
      { type: 'rsvpExists', message:'You already have an invite(rsvp) for this event, please goto your dashboard to update your playing status' },
    ],
  };
  
  export const courseValidations = {
    'teeTimeIncrement': [
      { type: 'required', message: 'You must enter a value' },
      { type: 'between7and15', message: 'Value must be between 7 and 15' },
      { type: 'pattern', message: 'Value must be a number' }
    ],
    'yardage': [
      { type: 'required', message: 'A value for yardage is required' },
      { type: 'pattern', message: 'Yardage must be a number' }
    ],
    'courseName': [
      { type: 'required', message: 'Course name is required' },
    ],
    'city': [
      { type: 'required', message: 'City name is required' },
    ],
    'state': [
      { type: 'required', message: 'State name is required' },
    ],
    'par': [
      { type: 'required', message: 'A value for par is required' },
      { type: 'pattern', message: 'Par must be between 3 and 6' },
    ],
    'handicap': [
      { type: 'required', message: 'A value for handicap is required' },
      { type: 'between1and18', message: 'Handicap must be between 1 and 18' },
  
    ],
    'teeName': [
      { type: 'required', message: 'A name for the tee is required' },
    ],
    'rating': [
      { type: 'required', message: 'A value for course rating is required' },
    ],
    'slope': [
      { type: 'required', message: 'A value for slope is required' },
      { type: 'pattern', message: 'Slope must be a number; typically between 100 and 150' },
    ],
    'handicapSet': [
      { type: 'required', message: 'A value for handicap set is required' },
    ],
    'hsName': [
      { type: 'required', message: 'A value for handicap set name is required' },
    ],
  };
  
  export const rsvpValidations = {
    'guestFirstname': [
      { type: 'required', message: 'First name is required' },
    ],
    'guestLastname': [
      { type: 'required', message: 'Last name is required' },
    ],
    'guestDisplayHandicapIndex': [
      { type: 'pattern', message: 'Please enter a valid handicap index - if an even number, include .0 at the end' },
    ],
  };
  
  export const registrationValidations = {
    firstname: [
      { type: 'required', message: 'A first name is required' },
    ],
    lastname: [
      { type: 'required', message: 'A last name is required' },
    ],
    gender: [
      { type: 'required', message: 'Gender is required' },
    ],
    email: [
      { type: 'required', message: 'An email address is required' },
      { type: 'email', message: 'Please enter a valid email address' },
    ],
    password: [
      { type: 'required', message: 'A password is required' },
    ],
    displayHandicapIndex: [
      { type: 'required', message: 'A handicap index is required, enter 0.0 if you only play scratch games' },
      { type: 'pattern', message: 'Please enter a valid handicap index' },
    ],
  }
  
  export const changeValidations = {
    
    newEmail: [
      { type: 'required', message: 'An email address is required' },
      { type: 'email', message: 'Please enter a valid email address' },
    ],
    password: [
      { type: 'required', message: 'A password is required' },
    ],
  }

  export const addPressValidations = {
    ball: [
      { type: 'required', message: 'Please select a which ball for which this press is required' },
    ],
    pressHole: [
      { type: 'required', message: 'please select a hole to start the press' },
    ],
  }

  export const pairingValidations = {
    displayHandicapIndex: [
      { type: 'pattern', message: 'Please enter a valid handicap index' },
      { type: 'required', message: 'Handicap index is required' },
    ],
    pointsDescription: [
      { type: 'required', message: 'Points description is required' },
    ],
    pointsAmount: [
      { type: 'required', message: 'Points amount is required' },
    ],
    team1: [
      { type: 'required', message: 'Please select team members' },
      { type: 'teamSizeError', message: 'Please enter the correct number of players for this team' },
    ],
    team2: [
      { type: 'required', message: 'Please select team members' },
      { type: 'teamSizeError', message: 'Please enter the correct number of players for this team' },
    ],
    pointsHandicapMethod: [
      { type: 'required', message: 'Please select a handicap method' },
    ],
    pointsHandicapPercentage: [
      { type: 'required', message: 'Please provide a handicap percentage' },
    ],
    team1selMembers: [
      { type: 'required', message: 'Team 1 players are required' },
      { type: 'insufficientSelections', message: 'Team 1 requires two players to be selected' },
    ],
    team2selMembers: [
      { type: 'required', message: 'Team 2 players are required' },
      { type: 'insufficientSelections', message: 'Team 2 requires two players to be selected' },
    ],
  }