import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';

@Injectable()
export class MemberFavoriteService extends BaseService {

  node = 'member-favorite';

  createMemberFavorite(data: any) {
    return super.create(this.node, data);
  }

  getForMemberId(memberId: string) {

    return this.afs.collection(this.node, ref => ref.where('memberId', '==', memberId));
  }

  getForFavoritePlayerId(playerId: string) {

    return this.afs.collection(this.node, ref => ref.where('favoritePlayerId', '==', playerId));
  }

  delete(id: string) {
    return this.afs.collection(this.node).doc(id).delete();
  }

}
