import { Event } from './../model-classes/event';

export function isUsingLowIndex(event: Event) {
  const { skinsFormat, handicapMethod } = event;

  let usesLowHandicapIndexBool = false;
  if (skinsFormat !== null && handicapMethod !== null) {
    if (skinsFormat > 1 && handicapMethod === 2) {
      usesLowHandicapIndexBool = true;
    }
  }

  return usesLowHandicapIndexBool;
}

export function betHandicapDescription(betHandicapMethod: number, team1Handicaps: any[], team2Handicaps: any[]) {
  let desc = ``;

  if (betHandicapMethod != null) {
    if (betHandicapMethod === 1) {
      desc = `Handicap: Even`;
    } else {
      if (betHandicapMethod === 2) {
        desc = `Net off of the low handicap for the bet`;
      } else {
        if (betHandicapMethod === 3) {
          desc = `Net using full handicaps`;
        } else {
          if (betHandicapMethod === 4) {
            let team1ManualDesc = ``;
            let firstTime = true;
            for (const team1Handicap of team1Handicaps) {
              const { name, strokes } = team1Handicap;
              if (firstTime) {
                team1ManualDesc = team1ManualDesc + `${name}(${strokes})`;
                firstTime = false;
              } else {
                team1ManualDesc = team1ManualDesc + `/${name}(${strokes})`;
              }
              let team2ManualDesc = ``;
              let firstTimeT2 = true;
              for (const team2Handicap of team2Handicaps) {
                const { name: t2name, strokes: t2strokes } = team2Handicap;
                if (firstTimeT2) {
                  team2ManualDesc = team2ManualDesc + `${t2name}(${t2strokes})`;
                  firstTimeT2 = false;
                } else {
                  team2ManualDesc = team2ManualDesc + `/${t2name}(${t2strokes})`;
                }
              }
              desc = `Net ${team1ManualDesc} vs. ${team2ManualDesc}`;
            }
          }
        }
      }
    }
  }
  return desc;
}

export function  calcCourseHandicap(handicapIndex: number, slope: number, par: number, rating: string) {
  let courseHandicap = 0;

  courseHandicap = Math.round(Number(handicapIndex) * (slope / 113) - (Number(rating) - par))
    return courseHandicap;
}

export function formatDisplayCourseHandicap(courseHandicap: number) {
  let displayCourseHandicap = (courseHandicap * -1).toString();
  if (courseHandicap > 0) {
    if (Number.isInteger(courseHandicap)) {
      displayCourseHandicap = `+${courseHandicap}.0`;
    } else {
      displayCourseHandicap = `+${courseHandicap}`;
    }
  }

  return displayCourseHandicap;
}

export function getFormattedCurrentDate() {
  const today = new (Date)();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = today.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }

  const stringDate = '' + year + month + day;
  return stringDate;
}

export function fieldSorter(fields) {
  return (a, b) => {
    return fields
      .map(o => {
        let dir = 1;
        if (o[0] === '-') {
          dir = -1;
          o = o.substring(1);
        }
        if (a[o] > b[o]) {
          return dir;
        }
        if (a[o] < b[o]) {
          return -dir;
        }
        return 0;
      })
      .reduce(function firstNonZeroValue(p, n) {
        return p ? p : n;
      }, 0);
  };
}

export function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export function capHyphens(str: string) {
  const splitStr = str.toLowerCase().split('-');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join('-');

}

export function emailToLowercase(inviteString: string) {
  return inviteString.toLowerCase();
}

export function formatHandicapIndexToNum(handicapIndex: string) {

  let numHandicapIndex = 0;
  if (handicapIndex.slice(0, 1) === '+') {
    numHandicapIndex = parseFloat(handicapIndex.slice(1, 4));
  } else {
    numHandicapIndex = (parseFloat(handicapIndex.slice(0, 4))) * -1;
  }

  return numHandicapIndex;
}

export async function determineHandicapIndex(memberId: string, index: number, displayIndex: string, service: any, event: any) {
  let retHandicapIndex = index;
  let retDisplayHandicapIndex = displayIndex;
  const { handicapAsOfDate, useHandicapAsOfDate } = event;

  if (useHandicapAsOfDate) {
    const asOfDate = new Date(handicapAsOfDate).toISOString();
    const handicapHistoryDocs = await service.getForMemberIdAndDateMod(memberId, asOfDate)
    if (handicapHistoryDocs.length > 0) {
      const handicapHistory: any = handicapHistoryDocs[0].data();
      const { handicapIndex, displayHandicapIndex } = handicapHistory;
      retHandicapIndex = handicapIndex;
      retDisplayHandicapIndex = displayHandicapIndex;
    }
  }

  const retObj = {
    retHandicapIndex,
    retDisplayHandicapIndex,
  } as any;

  return retObj
}

export function getNamesStr(names) {
  let nameStr = '';
  for (let i = 0; i < names.length; i++) {
    const curStr = names[i];
    const str = i === 0 ? curStr : i !== names.length - 1 ? `, ${curStr}` : names.length > 2 ? `, and ${curStr}` : `and ${curStr}`;
    nameStr = nameStr.concat(str);
  }

  return nameStr;
}