import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';

@Injectable()
export class GroupInviteService extends BaseService {

  node = 'group-invite';

  createGroupInvite(data: any) {
    return super.create(this.node, data);
  }

  delete(id) {
    return this.afs.collection('group-invite').doc(id).delete();
  }

  getForEmailAddress(emailAddress) {
    return this.afs.collection('group-invite', ref => ref.where('emailAddress', '==', emailAddress));
  }

  async getForEmailAddressMod(emailAddress: string) {
    const db = getFirestore(); 
    const q = query(
      collection(db, this.node),
      where('emailAddress', '==', emailAddress)
    )
    
    return (await getDocs(q)).docs;
  }

  getForGroup(group) {
    return this.afs.collection('group-invite', ref => ref.where('group', '==', group));
  }

  async getForGroupMod(group: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('group', '==', group)
    ) 

    return (await getDocs(q)).docs;
  }
}

