// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --min-height: .5pxpx;
}

ion-label {
  --min-height: .5px;
}

h5 {
  padding: 0px;
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/create-pairing-modal/create-pairing-modal.page.scss"],"names":[],"mappings":"AAAA;EACG,oBAAA;AACH;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,WAAA;AAAJ","sourcesContent":["ion-item {\n   --min-height: .5pxpx;\n\n}\n\nion-label { \n    --min-height: .5px;    \n}\n\nh5 {\n    padding: 0px;\n    margin : 0px;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
