import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class TeamScoreService extends BaseService {

    node = 'team-score';

    getForEventSortRelationToPar(eventId) {
        return this.afs.collection(this.node, ref => ref
            .where('eventId', '==', eventId)
            .orderBy('relationToParGross', 'asc')
        );
    }

    getForEventQuota(eventId) {
        return this.afs.collection(this.node, ref => ref
            .where('eventId', '==', eventId)
            .orderBy('totalQuota', 'desc')
        );
    }

   updateMembersAndTeamScores(id: string, members: [], teamScores: [], relationToPar) {
        return this.afs.collection(this.node).doc(id)
            .update({
                members: members,
                teamScores: teamScores,
                relationToPar,
            });
    } 

}
