import { NumberTypes, GlobalService } from './../services/global.service';
import { CourseService } from './../services/course.services';
import { NavParams } from '@ionic/angular';
import { EventService } from './../services/event.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-event-info-modal',
  templateUrl: './event-info-modal.page.html',
  styleUrls: ['./event-info-modal.page.scss'],
})
export class EventInfoModalPage {
  eventId: string;
  event: any;
  course: any;
  teeDescription: string;

  constructor(
    private eventService: EventService,
    private courseService: CourseService,
    private globalService: GlobalService,
    private navParams: NavParams) { }

  async ionViewWillEnter() {
    this.eventId = this.navParams.get('eventId');
    this.getEvent()
  }

  async getEvent() {
    const eventSnapshot = await this.eventService.getByDocId(this.eventId).get().toPromise();
    this.event = eventSnapshot.data();
    const { course } = this.event;
    const courseSnapshot = await this.courseService.getCourseByDocId(course).get().toPromise();
    const z = courseSnapshot.data();
    this.course = JSON.parse(JSON.stringify(z))
    this.teeDescription = this.getTeeDescription(); 
  }

  getTeeDescription() {
    let teeDesc = ''
    if (this.course) {
      const { tees } = this.course;
      const theTee = tees.find(t => t.id === this.event.tee);
      if (theTee !== undefined) {
        teeDesc = theTee.teeName;
      }
    }

    return teeDesc;
  }

  getSkinsDescription() {
    let skinDesc = ''
    if (this.event !== undefined && this.event !== null) {
      const { skinsFormat, skinsOption, skinsAmount } = this.event;

      const skinsFormats = this.globalService.getSkinsFormats();
      const skinsFormatDescription = skinsFormats[skinsFormat].desc;

      if (skinsOption === 1) {
        skinDesc = `${skinsFormatDescription}: $${skinsAmount} buy in`;
      }

    }

    return skinDesc;
  }

  getSkinsHandicapDescription() {
    let skinsHandicapDesc = ''
    if (this.event !== undefined && this.event !== null) {
      const { skinsFormat, handicapMethod, skinsHandicapPercentage } = this.event;
      if (skinsFormat > 1) {
        // full
        if (handicapMethod === 1) {
          skinsHandicapDesc = `${skinsHandicapPercentage}% of course handicap`
        }
        // based on low
        if (handicapMethod === 2) {
          skinsHandicapDesc = `${skinsHandicapPercentage}% of course handicap based on low player in the group`
        }
      }
    }

    return skinsHandicapDesc;
  }

  getStrokesOnPar3s() {
    let strokesOnPar3sDesc = ''
    if (this.event !== undefined && this.event !== null) {
      const { strokesOnPar3s, skinsFormat } = this.event;
      if (skinsFormat > 1) {
        if (strokesOnPar3s) {
          strokesOnPar3sDesc = `Allow strokes on par 3s`
        } else {
          strokesOnPar3sDesc = `Strokes are not allowed on par 3s`
        }
      }
    }
 
    return strokesOnPar3sDesc;
  }

  getCtpDescription() {
    let ctpDesc = ''
    if (this.event !== undefined && this.event !== null) {
      const { ctpOption, ctpAmount } = this.event;
      if (ctpOption === 2) {
        ctpDesc = `As skin`;
      }
      if (ctpOption === 3) {
        ctpDesc = `$${ctpAmount} buy in`;
      }
      if (ctpOption === 4) {
        ctpDesc = `$${ctpAmount} per ctp per person`
      }
    }

    return ctpDesc;
  }
}
