// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h6 {
  padding-left: 10px;
}

.padding-left {
  padding-left: 10px;
}

.product-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.para {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 90%;
}

.larger-font {
  font-size: 125%;
}

.button-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.center {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/change-billing-modal/change-billing-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":["h6 {\n    padding-left: 10px;\n}\n\n.padding-left {\n    padding-left: 10px;\n}\n\n.product-card {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.para {\n    padding-left: 10px;\n    padding-right: 5px;\n    padding-top: 0px;\n    padding-bottom: 0px;\n    font-size: 90%\n}\n\n.larger-font {\n    font-size: 125%\n}\n\n.button-padding {\n    padding-left: 10px;\n    padding-right: 10px;\n}\n\n.center { \n    text-align: center;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
