import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorLogService extends BaseService {

    node = 'error-log';

    createErrorLog(data) {
        return super.create(this.node, data);
    }
}