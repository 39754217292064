import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { EventBetService } from '../services/event-bet.service';
import { NotificationService } from '../services/notication.service';
import { MemberService } from '../services/member.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-delete-press-modal',
  templateUrl: './delete-press-modal.page.html',
  styleUrls: ['./delete-press-modal.page.scss'],
})
export class DeletePressModalPage implements OnInit {

  currentUser = '';
  presses = [];
  eventBet: any;
  autoPresses = [0, 1];
  otherBetFormats = [2, 3, 4, 5, 6, 7, 8];

  constructor(
    private authService: AuthService,
    private eventBetService: EventBetService,
    private memberService: MemberService,
    private notificationService: NotificationService,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private navParams: NavParams,
  ) { }

  async ngOnInit() {
    const eventBetId = this.navParams.get('eventBetId');
    this.getEventBet(eventBetId);
    this.currentUser = await this.authService.currentUser();
  }

  async getEventBet(eventBetId: string) {
    const eventBet = await this.eventBetService.getByDocIdMod(eventBetId);
    this.eventBet = eventBet;
    const pressArr: any[] = [];
    const { balls, bet } = eventBet;
    const { betFormat } = bet;

    if (this.autoPresses.includes(betFormat)) {
      let b = 0;
      for (const ball of balls) {
        const { sides } = ball;
        let s = 0;
        for (const side of sides) {
          const { presses } = side;
          let p = 0;
          for (const pr of presses) {
            const { betStartedHoleIndex } = pr;
            const press = {
              startingHole: betStartedHoleIndex + 1,
              betFormat,
              b,
              p,
              s,
            }
            pressArr.push(press)
            p++
          }
          s++
        }
        b++
      }
      this.presses = pressArr;
    }

    if (this.otherBetFormats.includes(betFormat)) {
      const { sides } = eventBet;
      let s = 0;
      for (const side of sides) {
        const { presses } = side;
        let p = 0;
        for (const pr of presses) {
          const { betStartedHoleIndex } = pr;
          const press = {
            startingHole: betStartedHoleIndex + 1,
            betFormat,
            p,
            s,
          }
          pressArr.push(press)
          p++
        }
        s++
      }
      this.presses = pressArr;
    }
  }

  async removePress(press: any) {
    const { betFormat } = press;
    if (this.autoPresses.includes(betFormat)) {
      const { b, s, p } = press;
      this.eventBet.balls[b].sides[s].presses.splice(p, 1);
      await this.eventBetService.updateBalls(this.eventBet.id, this.eventBet.balls);
    }

    if (this.otherBetFormats.includes(betFormat)) {
      const { s, p } = press;
      this.eventBet.sides[s].presses.splice(p, 1);
      await this.eventBetService.updateSides(this.eventBet.id, this.eventBet.sides);
    }

    const currentUser = await this.authService.currentUser();
    const pressRemovedByData = await this.memberService.getMemberByDocIdMod(currentUser);
    const { firstname, lastname } = pressRemovedByData;
    const pressRemovedBy = `${firstname} ${lastname}`;

    const { players } = this.eventBet.bet;
    for ( const player of players) {
      const newNote = this.createNote(player, pressRemovedBy);
      await this.notificationService.createNotification(newNote);
    }

    await this.presentToast();
    this.modalCtrl.dismiss();
  }

  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Your press has been deleted.',
      duration: 5000,
      position: 'middle',
      color: 'primary',
    });
    toast.present();
  }

  createNote(player: string, pressRemovedBy: string) {
    const { bet } = this.eventBet;
    const {team1name, team2name } = bet;
    const teamsDesc = `${team1name}/${team2name}`;
    const title = 'Press Removed';
    
    const newNote = {
      id: this.notificationService.getId(),
      type: 10,
      teamsDesc,
      pressRemovedBy,
      title,
      owner: player,
      isRead: false,
    }

    return newNote;
  }

  async getMemberData() {
    return await this.memberService.getByDocIdMod(this.currentUser)
  }
  
  cancel() {
    this.modalCtrl.dismiss();
  }
}
