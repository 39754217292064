import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, getDocs, getFirestore, query, where, } from 'firebase/firestore';

@Injectable()
export class BillingTransactionService extends BaseService {

    node = 'billing-transaction';

    async getAllForMember(memberId: string) {
        const db = getFirestore();
        const q = query(
            collection(db, this.node),
            where('memberId', '==', memberId),
        )

        return (await getDocs(q)).docs;
    }
}