import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { defer, of, combineLatest } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/compat/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

@Injectable()
export class BaseService {

  constructor(
    public afs: AngularFirestore,
    public http: HttpClient,
    ) { }

  getId() {
    return this.afs.createId();
  }

  getTimeStamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  create(node: string, object: any) {
    object.createdTS = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection(node).doc(object.id).set(object)
  }

  update(node: string, id: string, data: any) {
    return this.afs.collection(node).doc(id).set(data, { merge: true });
  }

  leftJoin(afs: AngularFirestore, field, collection, limit = 100) {
    return source =>
      defer(() => {
        // Operator state
        let collectionData;

        // Track total num of joined doc reads
        let totalJoins = 0;

        return source.pipe(
          switchMap(data => {
            // Clear mapping on each emitted val ;
            // Save the parent data state
            collectionData = data as any[];

            const reads$ = [];
            for (const doc of collectionData) {
              // Push doc read to Array
              if (doc[field]) {
                // Perform query on join key, with optional limit
                const q = ref => ref.where(field, '==', doc[field]).limit(limit);

                reads$.push(afs.collection(collection, q).valueChanges());
              } else {
                reads$.push(of([]));
              }
            }

            return combineLatest(reads$);
          }),
          map(joins => {
            return collectionData.map((v, i) => {
              totalJoins += joins[i].length;
              return { ...v, [collection]: joins[i] || null };
            });
          })
        );
      });
  };

  docJoin = (
    afs: AngularFirestore,
    paths: { [key: string]: string }
  ) => {
    return source =>
      defer(() => {
        let parent;
        const keys = Object.keys(paths);

        return source.pipe(
          switchMap(data => {
            // Save the parent data state
            parent = data;

            // Map each path to an Observable
            const docs$ = keys.map(k => {
              const fullPath = `${paths[k]}/${parent[k]}`;
              return afs.doc(fullPath).valueChanges();
            });

            // return combineLatest, it waits for all reads to finish
            return combineLatest(docs$);
          }),
          map(arr => {
            // We now have all the associated douments
            // Reduce them to a single object based on the parent's keys
            const joins = keys.reduce((acc, cur, idx) => {
              return { ...acc, [cur]: arr[idx] };
            }, {});

            // Return the parent doc with the joined objects
            return { ...parent, ...joins };
          })
        );
      });
  }

}
