// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: collapse;
  width: 100%;
  padding-top: 15px;
}

tr th {
  font-size: 0.9em;
  vertical-align: middle;
  font-weight: bold;
}

tr td {
  align-items: center;
  vertical-align: center;
  padding: 0px;
}

thead {
  background-color: var(--ion-color-tertiary);
  padding-top: 5px;
  color: white;
  font-size: 130%;
}

ion-select {
  font-size: 0.75em;
  padding: 2px;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.firstname {
  text-align: center;
}

.lastname {
  text-align: center;
  font-size: 110%;
  font-weight: 700;
}

p {
  margin: 0em;
  text-align: center;
}

h5 {
  padding-left: 15px;
  font-size: 87%;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/set-group-modal/set-group-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;EACA,iBAAA;AACJ;;AAEE;EACE,gBAAA;EACA,sBAAA;EACA,iBAAA;AACJ;;AAEE;EACE,mBAAA;EACA,sBAAA;EACA,YAAA;AACJ;;AAEE;EACE,2CAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAEE;EACE,iBAAA;EACC,YAAA;AACL;;AAGE;EACE,iBAAA;AAAJ;;AAGE;EACE,kBAAA;AAAJ;;AAGE;EACE,kBAAA;AAAJ;;AAGE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,WAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,kBAAA;EACA,cAAA;EACA,iBAAA;AAAJ","sourcesContent":["table {\n    border-collapse: collapse;\n    width: 100%;\n    padding-top: 15px;\n  }\n  \n  tr th {\n    font-size: .9em;\n    vertical-align: middle;\n    font-weight: bold;\n  }\n  \n  tr td {\n    align-items: center;\n    vertical-align: center;\n    padding: 0px;\n  }\n  \n  thead {\n    background-color: var(--ion-color-tertiary); \n    padding-top: 5px;\n    color: white;\n    font-size: 130%;\n  }\n\n  ion-select {\n    font-size: .75em;\n     padding: 2px;\n\n  }\n\n  .bold {\n    font-weight: bold;\n  }\n  \n  .center {\n    text-align: center;\n  }\n  \n  .firstname {\n    text-align: center;\n  }\n  \n  .lastname {\n    text-align: center;\n    font-size: 110%;\n    font-weight: 700;\n  }\n\n  p { \n    margin: 0em;\n    text-align: center\n  }\n\n  h5 {\n    padding-left: 15px;\n    font-size: 87%;\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
