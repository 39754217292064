// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --ion-background-color: var(--ion-color-light);
}

ion-label {
  white-space: normal !important;
}

.float-right {
  float: right;
}

.amount-format {
  text-align: end;
}

.card-border {
  border-style: ridge;
  border: 1px solid blue;
}`, "",{"version":3,"sources":["webpack://./src/app/bet-modal/bet-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,8CAAA;AACJ;;AAEA;EAEI,8BAAA;AAAJ;;AAIA;EACI,YAAA;AADJ;;AAIA;EACI,eAAA;AADJ;;AAIA;EACI,mBAAA;EACA,sBAAA;AADJ","sourcesContent":["ion-content {\n    --ion-background-color: var(--ion-color-light);\n}\n\nion-label{\n\n    white-space:normal !important;\n    \n}\n\n.float-right {\n    float: right\n}\n\n.amount-format {\n    text-align: end; \n}\n\n.card-border {\n    border-style: ridge;\n    border: 1px solid blue;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
