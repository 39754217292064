// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal {
  --background: rgba(44, 39, 45, 0.2);
  --height: 52%;
  --width: 85%;
}
ion-modal::part(content) {
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
}`, "",{"version":3,"sources":["webpack://./src/app/guest-modal/guest-modal.page.scss"],"names":[],"mappings":"AACI;EACI,mCAAA;EACA,aAAA;EACA,YAAA;AAAR;AAEQ;EACE,kCAAA;UAAA,0BAAA;AAAV","sourcesContent":["\n    ion-modal {\n        --background: rgba(44, 39, 45, 0.2);\n        --height: 52%;\n        --width: 85%;\n      \n        &::part(content) {\n          backdrop-filter: blur(1px);\n        }\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
