import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class EventSummaryWarningService extends BaseService {

  node = 'event-summary-warning';

  getEventSummaryWarningsForEventId(eventId: string) {
    return this.afs.collection(this.node,
      ref => ref.where('eventId', '==', eventId))
  }
}