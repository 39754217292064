import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { StagedPairingService } from '../services/staged-pairing.service';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-tee-sheet',
  templateUrl: './tee-sheet.page.html',
  styleUrls: ['./tee-sheet.page.scss'],
})
export class TeeSheetPage implements OnInit {

  eventId = '';
  event: any;
  stagedPairings = [];

  constructor(
    private eventService: EventService,
    private stagedPairingService: StagedPairingService,
    private navParams: NavParams,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.getNavParams();
    this.loadInitialData();
  }

  getNavParams() {
    this.eventId = this.navParams.get('eventId');
  }

  async loadInitialData() {

    const loader = await this.loadingCtrl.create({
      message: 'Loading',
      translucent: true,
      spinner: 'bubbles',
      duration: 5000
    });
    await loader.present();

    this.event = await this.eventService.getByDocIdMod(this.eventId)
    const stagedPairingDocs = await this.stagedPairingService.getForEvent(this.eventId);
    const stagedPairings = [];
    for (const doc of stagedPairingDocs) {
      const data = doc.data();
      stagedPairings.push(data);
    }
    this.stagedPairings = stagedPairings;

    loader.dismiss();
  }

  players(stagedPairing: any) {
    const { players } = stagedPairing;

    return players.reduce((accumulator, currentValue, currentIndex) => {
      if (currentIndex === players.length - 1) {
        return accumulator + currentValue.name; // Last element, no comma
      } else {
        return accumulator + currentValue.name + ", "; // Add comma for all except last
      }
    }, "");
  }

  return() {
    this.modalCtrl.dismiss();
  }
}
