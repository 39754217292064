import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DeviceService extends BaseService {

  node = 'devices';

  addDevice(data: any) {
    return super.create(this.node, data);
  }

}