import { BetTemplateService } from './../services/bet-template.service';
import { BetPairingModalPage } from './../bet-pairing-modal/bet-pairing-modal.page';
import { ModalPage } from './../modal/modal.page';
import { betValidations } from './../common/validations';
import { BetOfferService } from './../services/bet-offer.service';
import { EventService } from './../services/event.service';
import { GlobalService } from './../services/global.service';
import { MemberService } from './../services/member.service';
import { BetService } from './../services/bet.service';
import { FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ToastController, LoadingController, AlertController, NavParams, ModalController, IonSelect } from '@ionic/angular';
import { generateToast } from '../common/helpers/toasts';
import { RsvpService } from '../services/rsvp.service';
import { Member } from '../common/model-classes/member';
import { CheckboxCheckedValidator } from '../bet/bet.page.validators';
import { isNonStd2DownRequired } from '../common/helpers/dialog-msg';
import { greaterThanZero } from '../common/helpers/validators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-bet-modal',
  templateUrl: './bet-modal.page.html',
  styleUrls: ['./bet-modal.page.scss'],
})
export class BetModalPage {

  @ViewChild('addSelect') addOpponentRef: IonSelect;

  validations = betValidations;
  currentUser;
  eventData: any;
  eventId: string;
  dbRsvps: any[] = [];
  dbTeam1Members: any[] = [];
  dbTeam2Members: any[] = [];
  betFormats: any[];
  scoresToCounts: any[];
  betTypes: any[];
  autoDownNumbers: any[];
  numberOfBallsGT: any[];
  betNumberOfHolesGT: any[];
  betHandicapMethods: any[];
  vegasMaxOptions: any[];
  eventDate: string;
  compareEventDate: string;
  displayCourses: string;
  displayGroups: string;
  member: Member;
  isSubmitted = false;
  isManualHandicap = false;
  numberOfPressesOptions: any[];
  auto3and1PressOptions: any[];
  betTemplates = [];
  ctrlOptions = [];
  framework: any;
  groups = [];
  additionalOpponentCount = 0;
  loader: any;

  public betForm = this.fb.group({
    betTypeInd: new FormControl(2, Validators.required),
    team1members: new FormControl('', Validators.required),
    team2members: new FormControl('', Validators.required),
    betHandicapMethod: new FormControl(1, Validators.required),
    betHandicapPercentage: new FormControl(100, Validators.required),
    betFormat: new FormControl(0, Validators.required),
    autoDownNumber: new FormControl(2, Validators.required),
    numberOfBalls: new FormControl(1, Validators.required),
    betNumberOfHoles: new FormControl(9, Validators.required),
    numberOfPressesOption: new FormControl(1, Validators.required),
    maxNumberOfPresses: new FormControl(99, [Validators.required, greaterThanZero()]),
    useSecondScoreAsTieBreaker: new FormControl(false, Validators.required),
    auto3and1Press: new FormControl(false, Validators.required),
    auto3and1PressOption: new FormControl(),
    auto3and1PressOrWorse: new FormControl(false),
    scoresToCount: new FormControl(1),
    event: new FormControl(),
    id: new FormControl(null),
    team1Handicaps: this.fb.array([]),
    team2Handicaps: this.fb.array([]),
    additionalOpponents: this.fb.array([]),
    vegasMax: new FormControl(),
    compareEventDate: new FormControl(),
    betAmount: new FormControl('', [Validators.required, greaterThanZero()]),
  });

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public betService: BetService,
    private betTemplateService: BetTemplateService,
    public betOfferService: BetOfferService,
    public eventService: EventService,
    public globalService: GlobalService,
    public memberService: MemberService,
    public rsvpService: RsvpService,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    public toastCtrl: ToastController,
    private navParams: NavParams,
    private db: AngularFirestore,
  ) { }

  async ionViewWillEnter() {
    const promises = [];
    await this.setLoading();
    this.currentUser = await this.authService.currentUser();
    this.setInitialPlayer();
    this.eventId = this.navParams.get('eventId');
    this.getGlobalEntries();
    promises.push(this.setCompareDate(this.eventId));
    promises.push(this.getMembersForEvent(this.eventId));
    promises.push(this.getBetTemplates());
    await Promise.all(promises)
    this.dismissLoader();
  }

  getGlobalEntries() {
    this.betFormats = this.globalService.getBetFormats();
    this.betTypes = this.globalService.getBetTypes();
    this.autoDownNumbers = this.globalService.getAutoDownNumbers();
    this.numberOfBallsGT = this.globalService.getNumberOfBalls();
    this.betNumberOfHolesGT = this.globalService.getBetNumberOfHoles();
    this.betHandicapMethods = this.globalService.getBetHandicapMethods();
    this.vegasMaxOptions = this.globalService.getVegasMaxOptions();
    this.scoresToCounts = this.globalService.getScoresToCount();
    this.numberOfPressesOptions = this.globalService.getNumberOfPressesOptions();
    this.auto3and1PressOptions = this.globalService.getAuto3and1PressOptions();
  }

  setInitialPlayer() {
    this.betForm.patchValue({
      event: this.eventId,
      // @ts-ignore
      team1members: [this.currentUser],
    });
  }

  async setLoading() {
    this.loader = await this.loadingCtrl.create({
      message: 'Loading Members',
      translucent: true,
      spinner: 'bubbles',
      duration: 5000
    });

    await this.loader.present();
  }

  async dismissLoader() {
    await this.loader.dismiss();
  }

  async getMembersForEvent(eventId) {
    this.dbTeam1Members = [];
    this.dbTeam2Members = [];
    const docs = await this.rsvpService.getRsvpThatArePlayingMod(eventId);
    for (const doc of docs) {
      const rsvpData = doc.data();
      const { member } = rsvpData;
      const { firstname, lastname, id: memberId } = member;
      const listMember = {
        firstname,
        lastname,
        member: memberId,
      }
      this.dbTeam1Members.push(listMember);
      this.dbTeam2Members.push(listMember);
    }
  }

  async getBetTemplates() {

    const docs = await this.betTemplateService.getAllMod();
    for (const doc of docs) {
      const docData = doc.data();
      this.betTemplates.push(docData);
      const { description: text, id: value } = docData;
      const mappedBetTemplate = {
        text,
        value,
      } as any
      this.ctrlOptions.push(mappedBetTemplate);
    }
  }

  async saveBet() {
    this.isSubmitted = true;
    if (this.betForm.valid) {
      const data = this.betForm.value;
      const warnings = await this.checkForWarnings(data);
      if (warnings.length === 0) {
        this.completeSavingOfBet();
      } else {
        this.presentWarningModal(warnings);
      }
    }
  }

  groupSelectionChanged() {
    console.log('wtf...am I supposed to do something here groupSelectionChanged()')
  }

  async checkForWarnings(betData: any) {

    const warnings = [];
    const dupFound = await this.checkForDuplicateBets(betData)
    if (dupFound) {
      warnings.push(2);
    }

    const { betFormat, team1members, team2members, numberOfBalls } = betData;
    if (betFormat === 0 && team1members.length + team2members.length > 5 && numberOfBalls === 1) {
      warnings.push(1);
    }

    return warnings;
  }

  async checkForDuplicateBets(betData: any) {
    let duplicateFound = false;
    const { betFormat: curBetFormat, betHandicapMethod: curBetHandicapMethod, autoDownNumber: curAutoDownNumber, numberOfBalls: curNumberOfBalls,
      betNumberOfHoles: curBetNumberOfHoles, team1members: curTeam1members, team2members: curTeam2members } = betData;
    const betSnapshots = await this.betService.getbetListForUserFromPlayerArray(this.currentUser, this.getFormattedCurrentDate()).get().toPromise();
    betSnapshots.forEach(betSnapshot => {
      const betSnapshotData: any = betSnapshot.data();
      const { players, betFormat, betHandicapMethod, autoDownNumber, numberOfBalls, betNumberOfHoles } = betSnapshotData;
      let allPlayersFound = true;
      for (const team1member of curTeam1members) {
        if (!players.includes(team1member)) {
          allPlayersFound = false;
        }
      }
      if (allPlayersFound) {
        for (const team2member of curTeam2members) {
          if (!players.includes(team2member)) {
            allPlayersFound = false;
          }
        }
      }
      if (allPlayersFound && curBetFormat === betFormat && curBetHandicapMethod === betHandicapMethod
        && (curTeam1members.length + curTeam2members.length) === players.length
        && ((betFormat === 0 || betFormat === 1) && curAutoDownNumber === autoDownNumber || betFormat > 1)
        && ((betFormat === 0 || betFormat === 1) && curNumberOfBalls === numberOfBalls || betFormat > 1)
        && ((betFormat === 0 || betFormat === 1) && curBetNumberOfHoles === betNumberOfHoles || betFormat > 1)) {
        duplicateFound = true;
      }
    })

    return duplicateFound;
  }

  async presentWarningModal(warnings: any[]) {

    for (const warning of warnings) {
      let msg = isNonStd2DownRequired
      if (warning === 2) {
        msg = `This bet already exists, do you want to continue?`
      }
      const modal = await this.modalCtrl.create({
        component: ModalPage,
        componentProps: {
          msg: msg,
          showOk: false,
        }
      })

      await modal.present();

      await modal.onDidDismiss()
        .then(async (data: any) => {
          const { responseBool } = data.data;
          if (responseBool) {
            this.completeSavingOfBet();
          }
        })
    }
  }

  completeSavingOfBet() {
    const data: any = this.betForm.value;
    data.betAuthId = this.currentUser;
    const team1detailArray = [];
    let team1name = '';

    for (const member of data.team1members) {
      const theMember = this.dbTeam1Members.find(m => m.member === member);
      const { firstname, lastname } = theMember;
      const tempMember = {
        firstname,
        lastname,
        id: member,
      } as any;

      team1detailArray.push(tempMember);
    }

    let i = 0;
    const sortedTeam1members = team1detailArray.sort((a, b) => a.lastname < b.lastname ? -1 : a.lastname > b.lastname ? 1 : 0);
    for (const sortMember of sortedTeam1members) {
      i++;
      const { firstname, lastname } = sortMember;
      const initial = firstname.charAt(0);
      const combName = `${initial}. ${lastname}`;
      if (i === 1) {
        team1name = combName;
      } else {
        team1name = `${team1name}/${combName}`;
      }
    }

    data.team1details = team1detailArray;
    data.team1name = team1name;
    data.description = this.createDescription(data);

    const team2stuff = this.team2details(data.team2members);
    const { team2detailArray, name } = team2stuff;
    data.team2details = team2detailArray;
    data.team2name = name;
    data.compareEventDate = '21000101';
    if (data.event != null) {
      data.eventDate = this.eventDate;
      data.displayCourses = this.displayCourses;
      data.displayGroups = this.displayGroups;
      data.compareEventDate = this.compareEventDate;
    }
    data.players = this.populatePlayerArray(data);
    const { betTypeInd } = data;
    if (betTypeInd === 2) {
      this.addNewBet(data);
    }
  }

  async addNewBet(betData) {
    betData.id = this.betService.getId();
    betData.isActive = true;
    betData.createdTS = firebase.firestore.FieldValue.serverTimestamp();

    const batch = this.db.firestore.batch();
    const betXref = this.db.firestore.collection('bet').doc(betData.id);
    batch.set(betXref, betData);

    const addBetData = betData;
    // this.opponents returns an array
    for (const opponent of this.opponents) {
      // @ts-ignore
      if (opponent.opponents != null) {
        // @ts-ignore
        const team2stuff = this.team2details(opponent.opponents);
        const { team2detailArray, name } = team2stuff;
        addBetData.id = this.betService.getId();
        addBetData.team2details = team2detailArray;
        addBetData.team2name = name;
        addBetData.players = this.populatePlayerArray(addBetData);
        addBetData.isActive = true;
        addBetData.createdTS = firebase.firestore.FieldValue.serverTimestamp();
        this.betService.createBet(addBetData);
        const addBetXref = this.db.firestore.collection('bet').doc(addBetData.id);
        batch.set(addBetXref, addBetData);
      }
    }

    batch.commit().catch(error => console.log({ error }))

    const online = navigator.onLine;
    if (online) {
      const toastMsg = this.generateToastMsg(betData);
      generateToast(this.toastCtrl, toastMsg, 4000);
      this.modalCtrl.dismiss();
    } else {

      const alert = await this.alertCtrl.create({
        header: 'Alert',
        subHeader: 'Currently Offline',
        message: 'You currently do not have a network connection.  Your bet will be added when your network connection is restored.  If you do not see it then, please try again',
        buttons: ['OK'],
        cssClass: 'custom-alert'
      });

      await alert.present();
      await alert.onDidDismiss();
      this.modalCtrl.dismiss();
    }
  }

  populatePlayerArray(data) {
    const players = [];
    const { team1details, team2details } = data;
    for (const member of team1details) {
      players.push(member.id);
    }
    for (const member of team2details) {
      players.push(member.id);
    }

    return players;
  }

  generateToastMsg(data) {
    const { betTypeInd, team1name, team2name, eventDate } = data;
    let msg = `You have successfully created a standing bet between ${team1name} and ${team2name}`;
    if (betTypeInd === 2) {
      msg = `You have successfully created a one-time bet between ${team1name} and ${team2name} on ${eventDate}`;
    }
    return msg;
  }

  createDescription(data) {
    const { betFormat, autoDownNumber, numberOfBalls, betNumberOfHoles } = data;
    let description = '';
    if (betFormat === 0) {
      description = 'Automatic 2 down - std';
    }
    if (betFormat === 1) {
      let betHoleNumber = '';
      if (betNumberOfHoles === 9) {
        betHoleNumber = '9 hole bets';
      } else {
        betHoleNumber = '18 hole bet';
      }
      if (numberOfBalls > 1) {
        description = `Automatic ${autoDownNumber} down press - ${betHoleNumber} (${numberOfBalls} balls)`;
      } else {
        description = `Automatic ${autoDownNumber} down press - ${betHoleNumber} `;
      }
    }
    if (betFormat === 2) {
      description = 'Match play - front, back, and total';
    }
    if (betFormat === 3) {
      description = 'Match play - front and back';
    }
    if (betFormat === 4) {
      description = 'Nassau';
    }
    if (betFormat === 5) {
      description = 'Vegas';
    }
    if (betFormat === 6) {
      const { vegasMax } = data;
      let maxDesc = 'double';
      if (vegasMax === 3) {
        maxDesc = 'triple';
      }
      if (vegasMax === 4) {
        maxDesc = 'quad';
      }
      description = `Vegas with max - ${maxDesc}`;
    }
    if (betFormat === 7) {
      description = 'Per hole'
    }
    if (betFormat === 8) {
      description = 'Match play - total'
    }

    return description;
  }

  team2details(team2members) {
    const team2detailArray = [];
    let team2name = '';
    for (const member of team2members) {
      const theMember = this.dbTeam2Members.find(m => m.member === member);
      const tempMember = {} as any;
      tempMember.firstname = theMember.firstname;
      tempMember.lastname = theMember.lastname;
      tempMember.id = member;
      team2detailArray.push(tempMember);
    }

    let j = 0;
    const sortedTeam2members = team2detailArray.sort((a, b) => a.lastname < b.lastname ? -1 : a.lastname > b.lastname ? 1 : 0);
    for (const sort2member of sortedTeam2members) {
      j++;
      const { firstname, lastname } = sort2member;
      const initial = firstname.charAt(0);
      const combName = `${initial}. ${lastname}`;
      if (j === 1) {
        team2name = combName;
      } else {
        team2name = `${team2name}/${combName}`;
      }
    }

    const team2 = {} as any;
    team2.team2detailArray = team2detailArray;
    team2.name = team2name;

    return team2;
  }

  addOpponents() {
    console.log('add')
    this.additionalOpponentCount++;

    const fa = new FormArray([]);
    if (this.opponents.length > 0) {
      for (const opp of this.opponents) {
        const tempOpp = this.fb.group({
          // @ts-ignore
          opponents: new FormControl(opp.opponents)
        });
        fa.push(tempOpp);
      }
    }

    const newOpponent = this.fb.group({
      opponents: new FormControl(),
    });
    fa.push(newOpponent);

 /*    setTimeout(() => {
      this.addOpponentRef.open();
    }, 350) */

    this.betForm.setControl('additionalOpponents', fa);
  }

  checkForManual(event: any) {
    const betHandicapMethod = event.detail.value;
    if (betHandicapMethod === 4) {
      this.isManualHandicap = true;
      this.populateManualPlayers();
    } else {
      if (this.isManualHandicap) {
        this.removeManualPlayers();
      }
      this.isManualHandicap = false;
    }
  }

  removeManualPlayers() {
    const t1Length = this.team1Handicaps.length;
    for (let i = 0; i < t1Length; i++) {
      this.team1Handicaps.removeAt(0);
    }
    const t2Length = this.team2Handicaps.length;
    for (let i = 0; i < t2Length; i++) {
      this.team2Handicaps.removeAt(0);
    }
  }

  async populateManualPlayers() {
    if (this.team1members.value != null) {
      for (const player of this.team1members.value) {
        const memberData = await this.memberService.getMemberByDocIdMod(player);
        const { firstname, lastname } = memberData;
        const newId = this.betService.getId();
        const team1Player = this.fb.group({
          id: [newId],
          memberId: [player],
          name: [`${firstname} ${lastname}`],
          strokes: new FormControl(0, Validators.required)
        });
        this.team1Handicaps.push(team1Player)
      }
    }

    if (this.team2members.value != null) {
      for (const player of this.team2members.value) {
        const memberData = await this.memberService.getMemberByDocIdMod(player);
        const { firstname, lastname } = memberData;
        const newId = this.betService.getId();
        const team2Player = this.fb.group({
          id: [newId],
          memberId: [player],
          name: [`${firstname} ${lastname}`],
          strokes: new FormControl(0, Validators.required)
        });
        this.team2Handicaps.push(team2Player);
      }
    }
  }

  async setCompareDate(eventId) {
    const eventData = await this.eventService.getByDocIdMod(eventId);
    const { compareDate, eventDate, displayCourses, displayGroups } = eventData;
    this.compareEventDate = compareDate;
    this.eventDate = eventDate;
    this.displayGroups = displayGroups;
    this.displayCourses = displayCourses;
    this.eventData = eventData;
  }

  getFormattedCurrentDate() {
    const today = new (Date)();
    const year = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = today.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }

    const stringDate = '' + year + month + day;
    return stringDate;
  }

  betFormatChanged() {
    if ([5, 6].includes(this.betFormat)) {
      this.betForm.get('team1members').setValidators([CheckboxCheckedValidator.minSelectedCheckboxes(2), Validators.required]);
      this.betForm.get('team2members').setValidators([Validators.required, CheckboxCheckedValidator.minSelectedCheckboxes(2)]);
      if ([6].includes(this.betFormat)) {
        this.betForm.get('vegasMax').setValidators([Validators.required]);
      } else {
        this.betForm.get('vegasMax').setValidators(null);
      }
      this.betForm.get('vegasMax').updateValueAndValidity();
    } else {
      this.betForm.get('team1members').setValidators([Validators.required]);
      this.betForm.get('team2members').setValidators([Validators.required]);
    }

    this.betForm.get('team1members').updateValueAndValidity();
    this.betForm.get('team2members').updateValueAndValidity();

  }

  auto3and1PressChanged() {
    if (this.auto3and1Press === true) {
      this.betForm.get('auto3and1PressOption').setValidators([Validators.required]);
    }
    if (this.auto3and1Press === false) {
      this.betForm.get('auto3and1PressOption').setValidators(null);
    }
    this.betForm.patchValue({ auto3and1PressOrWorse: false, auto3and1PressOption: null })
  }

  numberOfPressesChanged() {
    if (this.numberOfPressesOption === 0) {
      this.betForm.patchValue({ maxNumberOfPresses: 1 })
    }
    if (this.numberOfPressesOption === 1) {
      this.betForm.patchValue({ maxNumberOfPresses: 99 })
    }
  }

  removeBet(i: number) {
    this.additionalOpponentCount--;
    (this.betForm.get('additionalOpponents') as FormArray).removeAt(i);
  }

  templateChanged(event: any) {
    const templateId = event.target.value;
    const theTemplate = this.betTemplates.find(t => t.id === templateId);
    if (theTemplate) {
      const { autoDownNumber, betFormat, betHandicapMethod, betHandicapPercentage, betNumberOfHoles,
        numberOfBalls, scoresToCount, auto3and1Press, description,useSecondScoreAsTieBreaker } = theTemplate;
      this.betForm.patchValue({
        autoDownNumber,
        betFormat,
        betHandicapMethod,
        betHandicapPercentage,
        betNumberOfHoles,
        numberOfBalls,
        scoresToCount,
        auto3and1Press,
        useSecondScoreAsTieBreaker,
      })
      this.auto3and1PressChanged();

      const arr = [
        'Group Auto 2 Downs 2 Balls - Net',
        'Group Auto 2 Down 2 Balls - Gross',
      ]
      if (arr.includes(description)) {
        this.presentBetPairingModal();
      }
    }
  }

  async presentBetPairingModal() {

    const modal = await this.modalCtrl.create({
      component: BetPairingModalPage,
      componentProps: {
        eventId: this.event.value,
      }
    })

    await modal.present();

    await modal.onDidDismiss()
      .then(async (data: any) => {
        if (data.data !== undefined && data.data !== null) {
          const team1members = data.data.currentPairing.playerArray;
          const team2members = data.data.selectedPairing.playerArray;
          this.betForm.patchValue({ team1members, team2members });
          const toastMsg = `Your team and the opponent players have been added for this bet`
          generateToast(this.toastCtrl, toastMsg, 4000);
        }
      })
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  get team1Handicaps() {
    return this.betForm.get('team1Handicaps') as FormArray;
  }

  get team2Handicaps() {
    return this.betForm.get('team2Handicaps') as FormArray;
  }

  get betAmount() {
    return this.betForm.get('betAmount').value;
  }

  get team1members() {
    return this.betForm.get('team1members');
  }

  get team2members() {
    return this.betForm.get('team2members');
  }

  get additionalOpponents() {
    return (this.betForm.get('additionalOpponents') as FormArray);
  }

  get event() {
    return this.betForm.get('event');
  }

  get opponents() {
    return this.betForm.get('additionalOpponents').value;

  }

  get betTypeInd() {
    return this.betForm.get('betTypeInd').value;

  }

  get group() {
    return this.betForm.get('group').value;

  }

  get betFormat() {
    return this.betForm.get('betFormat').value;

  }

  get vegasMax() {
    return this.betForm.get('vegasMax').value;
  }

  get scoresToCount() {
    return this.betForm.get('scoresToCount').value;
  }

  get numberOfPressesOption() {
    return this.betForm.get('numberOfPressesOption').value;
  }

  get auto3and1Press() {
    return this.betForm.get('auto3and1Press').value;
  }

  get auto3and1PressOption() {
    return this.betForm.get('auto3and1PressOption');
  }

  get auto3and1PressOptionValue() {
    return this.betForm.get('auto3and1PressOption').value;
  }
}
