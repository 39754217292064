import { LoggedInGuard } from './guards/logged-in.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeGuard } from './guards/home.guard';

const routes: Routes = [

   {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [LoggedInGuard]
  }, 

  //{ path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule), canActivate: [HomeGuard] },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule) },
  // { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule), canActivate: [LoggedInGuard] },
   { path: 'dashboard', redirectTo: 'tabs', pathMatch: 'full' },
  { path: 'rsvp', loadChildren: () => import('./rsvp/rsvp.module').then(m => m.RsvpPageModule), canActivate: [LoggedInGuard] },
  { path: 'group', loadChildren: () => import('./group/group.module').then(m => m.GroupPageModule), canActivate: [LoggedInGuard] },
  { path: 'event', loadChildren: () => import('./event/event.module').then(m => m.EventPageModule), runGuardsAndResolvers: 'always',  canActivate: [LoggedInGuard] },
  //{ path: 'course', loadChildren: () => import('./course/course.module').then(m => m.CoursePageModule), canActivate: [LoggedInGuard] },
  //{ path: 'list', loadChildren: () => import('./list/list.module').then(m => m.ListPageModule), canActivate: [LoggedInGuard] },
  { path: 'member', loadChildren: () => import('./member/member.module').then(m => m.MemberPageModule), canActivate: [LoggedInGuard] },
  
  // { path: 'scores', loadChildren: () => import('./scores/scores.module').then(m => m.ScoresPageModule) },
  // { path: 'pairing-modal', loadChildren: () => import('./pairing-modal/pairing-modal.module').then(m => m.PairingModalPageModule) },
  { path: 'bet', loadChildren: () => import('./bet/bet.module').then(m => m.BetPageModule), canActivate: [LoggedInGuard] },
  // { path: 'group-modal', loadChildren: () => import('./group-modal/group-modal.module').then(m => m.GroupModalPageModule) },
  { path: 'password-reset', loadChildren: () => import('./password-reset/password-reset.module').then(m => m.PasswordResetPageModule) },
  { path: 'password-change', loadChildren: () => import('./password-change/password-change.module').then(m => m.PasswordChangePageModule) },
  { path: 'group-registration', loadChildren: () => import('./group-registration/group-registration.module').then(m => m.GroupRegistrationPageModule) },
  { path: 'query', loadChildren: () => import('./query/query.module').then(m => m.QueryPageModule) },
  { path: 'ctp-modal', loadChildren: () => import('./ctp-modal/ctp-modal.module').then(m => m.CtpModalPageModule) },
  {
    path: 'open-event',
    loadChildren: () => import('./open-event/open-event.module').then( m => m.OpenEventPageModule)
  },
  {
    path: 'monster-modal',
    loadChildren: () => import('./monster-modal/monster-modal.module').then( m => m.MonsterModalPageModule)
  },
  {
    path: 'bet-summary',
    loadChildren: () => import('./bet-summary/bet-summary.module').then( m => m.BetSummaryPageModule)
  },
  {
    path: 'bet-summary-modal',
    loadChildren: () => import('./bet-summary-modal/bet-summary-modal.module').then( m => m.BetSummaryModalPageModule)
  },
  {
    path: 'bet-modal',
    loadChildren: () => import('./bet-modal/bet-modal.module').then( m => m.BetModalPageModule)
  },
  {
    path: 'modal',
    loadChildren: () => import('./modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'create-pairing-modal',
    loadChildren: () => import('./create-pairing-modal/create-pairing-modal.module').then( m => m.CreatePairingModalPageModule)
  },
  {
    path: 'team-pairing-modal',
    loadChildren: () => import('./team-pairing-modal/team-pairing-modal.module').then( m => m.TeamPairingModalPageModule)
  },
  {
    path: 'event-results-modal',
    loadChildren: () => import('./event-results-modal/event-results-modal.module').then( m => m.EventResultsModalPageModule)
  },
  {
    path: 'live-scoring',
    loadChildren: () => import('./live-scoring/live-scoring.module').then( m => m.LiveScoringPageModule)
  },
 /*  {
    path: 'event-info',
    loadChildren: () => import('./event-info/event-info.module').then( m => m.EventInfoPageModule)
  }, */
  {
    path: 'event-info-modal',
    loadChildren: () => import('./event-info-modal/event-info-modal.module').then( m => m.EventInfoModalPageModule)
  },
  {
    path: 'find-course-modal',
    loadChildren: () => import('./find-course-modal/find-course-modal.module').then( m => m.FindCourseModalPageModule)
  },
  {
    path: 'bet-pairing-modal',
    loadChildren: () => import('./bet-pairing-modal/bet-pairing-modal.module').then( m => m.BetPairingModalPageModule)
  },
  {
    path: 'team-modal-page',
    loadChildren: () => import('./team-modal-page/team-modal-page.module').then( m => m.TeamModalPagePageModule)
  },
  {
    path: 'daily-summary',
    loadChildren: () => import('./daily-summary/daily-summary.module').then( m => m.DailySummaryPageModule)
  },
  {
    path: 'bet-history-modal',
    loadChildren: () => import('./bet-history-modal/bet-history-modal.module').then( m => m.BetHistoryModalPageModule)
  },
  {
    path: 'individual-stats',
    loadChildren: () => import('./individual-stats/individual-stats.module').then( m => m.IndividualStatsPageModule)
  },
  {
    path: 'group-stats',
    loadChildren: () => import('./group-stats/group-stats.module').then( m => m.GroupStatsPageModule)
  },
  {
    path: 'add-press-modal',
    loadChildren: () => import('./add-press-modal/add-press-modal.module').then( m => m.AddPressModalPageModule)
  },
  {
    path: 'app-info',
    loadChildren: () => import('./app-info/app-info.module').then( m => m.AppInfoPageModule)
  },
  {
    path: 'flip-wolf-modal',
    loadChildren: () => import('./flip-wolf-modal/flip-wolf-modal.module').then( m => m.FlipWolfModalPageModule)
  },
  {
    path: 'new-pairing-modal',
    loadChildren: () => import('./new-pairing-modal/new-pairing-modal.module').then( m => m.NewPairingModalPageModule)
  },
  {
    path: 'register-modal',
    loadChildren: () => import('./register-modal/register-modal.module').then( m => m.RegisterModalPageModule)
  },
  {
    path: 'guest-modal',
    loadChildren: () => import('./guest-modal/guest-modal.module').then( m => m.GuestModalPageModule)
  },
  {
    path: 'mass-update-modal',
    loadChildren: () => import('./mass-update-modal/mass-update-modal.module').then( m => m.MassUpdateModalPageModule)
  },
  {
    path: 'other-score-modal',
    loadChildren: () => import('./other-score-modal/other-score-modal.module').then( m => m.OtherScoreModalPageModule)
  },
  {
    path: 'group-wizard',
    loadChildren: () => import('./group-wizard/group-wizard.module').then( m => m.GroupWizardPageModule)
  },
  {
    path: 'group-help-modal',
    loadChildren: () => import('./group-help-modal/group-help-modal.module').then( m => m.GroupHelpModalPageModule)
  },
  {
    path: 'change-email-modal',
    loadChildren: () => import('./change-email-modal/change-email-modal.module').then( m => m.ChangeEmailModalPageModule)
  },
  {
    path: 'preferred-tee-modal',
    loadChildren: () => import('./preferred-tee-modal/preferred-tee-modal.module').then( m => m.PreferredTeeModalPageModule)
  },
  {
    path: 'skin-ctp-summary-modal',
    loadChildren: () => import('./skin-ctp-summary-modal/skin-ctp-summary-modal.module').then( m => m.SkinCtpSummaryModalPageModule)
  },
  {
    path: 'set-group-modal',
    loadChildren: () => import('./set-group-modal/set-group-modal.module').then( m => m.SetGroupModalPageModule)
  },
  {
    path: 'ionic',
    loadChildren: () => import('./ionic/ionic.module').then( m => m.IonicPageModule)
  },
  {
    path: 'build-pairings-modal',
    loadChildren: () => import('./build-pairings-modal/build-pairings-modal.module').then( m => m.BuildPairingsModalPageModule)
  },
  {
    path: 'tee-sheet',
    loadChildren: () => import('./tee-sheet/tee-sheet.module').then( m => m.TeeSheetPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'image',
    loadChildren: () => import('./image/image.module').then( m => m.ImagePageModule)
  },
  {
    path: 'add-player-page',
    loadChildren: () => import('./add-player-page/add-player-page.module').then( m => m.AddPlayerPagePageModule)
  },
  {
    path: 'reset-course',
    loadChildren: () => import('./reset-course/reset-course.module').then( m => m.ResetCoursePageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'tab1',
    loadChildren: () => import('./tab1/tab1.module').then( m => m.Tab1PageModule)
  },
  {
    path: 'tab3',
    loadChildren: () => import('./tab3/tab3.module').then( m => m.Tab3PageModule)
  },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  },
  {
    path: 'tab2',
    loadChildren: () => import('./tab2/tab2.module').then( m => m.Tab2PageModule)
  },
  {
    path: 'tab5',
    loadChildren: () => import('./tab5/tab5.module').then( m => m.Tab5PageModule)
  },
  {
    path: 'tab6',
    loadChildren: () => import('./tab6/tab6.module').then( m => m.Tab6PageModule)
  },
  {
    path: 'tab7',
    loadChildren: () => import('./tab7/tab7.module').then( m => m.Tab7PageModule)
  },
  {
    path: 'tab8',
    loadChildren: () => import('./tab8/tab8.module').then( m => m.Tab8PageModule)
  },
  {
    path: 'delete-account-modal',
    loadChildren: () => import('./delete-account-modal/delete-account-modal.module').then( m => m.DeleteAccountModalPageModule)
  },
  {
    path: 'delete-press-modal',
    loadChildren: () => import('./delete-press-modal/delete-press-modal.module').then( m => m.DeletePressModalPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'billing-modal',
    loadChildren: () => import('./billing-modal/billing-modal.module').then( m => m.BillingModalPageModule)
  },
  {
    path: 'change-billing-modal',
    loadChildren: () => import('./change-billing-modal/change-billing-modal.module').then( m => m.ChangeBillingModalPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 