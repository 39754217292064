import { Component, OnInit } from '@angular/core';
import { BillingProductService } from '../services/billing-product.service';
import { AlertController, IonicSafeString, LoadingController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-billing-modal',
  templateUrl: './billing-modal.page.html',
  styleUrls: ['./billing-modal.page.scss'],
})
export class BillingModalPage implements OnInit {

  billingProducts = [];
  productSelected = false;
  selectedBillingProduct: any;
  memberId = '';
  cardForm: any;
  iFrameLoaded = false;
  result1: any;
  result2: any;
  isChecked = false;

  constructor(
    private billingProductService: BillingProductService,
    private http: HttpClient,
    private navParams: NavParams,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.getNavParams();
    this.loadInitialData();
  }

  getNavParams() {
    this.memberId = this.navParams.get('user');
  }

  async loadInitialData() {
    const products = [];
    const docs = await this.billingProductService.getAll();
    for (const doc of docs) {
      const data = doc.data();
      products.push(data)
    }
    this.billingProducts = products;
  }

  async acceptCard() {  

    try {
      this.result1 = await this.cardForm.getNonceToken();
      this.result2 = await this.cardForm.getNonceToken();

      const desc = this.selectedProductDescription();

      const alert = await this.alertCtrl.create({
        header: 'Alert',
        message: new IonicSafeString(`${desc} Your card will be charged now and for future payments as needed per your subscription. <br><br> Cancel anytime. <br><br>Do you want to continue?`),
        cssClass: 'custom-alert',
        buttons: [{
          text: 'Yes',
          handler: () => {
            this.submitCardInfo();
          }
        }, {
          text: 'No',
        }]
      });
  
      await alert.present();
      alert.onDidDismiss().then();
    }
    catch (error) {
      this.presentCardAlert(error.message);
    }
  }

  async submitCardInfo() {
    if (this.result1 && this.result2) {
      const loader = await this.loadingCtrl.create({
        message: 'Processing your payment information',
        translucent: true,
        spinner: 'bubbles',
        duration: 10000
      });
      await loader.present();

      const { amount, description: recurringDescription, frequency, id: productId } = this.selectedBillingProduct;

      const body = {
        memberId: this.memberId,
        amount,
        result: this.result1,
        result2: this.result2,
        recurringDescription,
        frequency,
        productId,
      }

      const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/processPayment`;
      this.http.post(url, body).subscribe({
        next: () => {
          loader.dismiss();
          this.makeSuccessfulToast();
          this.modalCtrl.dismiss();
        },
        error: (err) => {
          loader.dismiss();
          console.error('Error occurred:', err);
          this.presentErrorAlert();   
        },
        complete: () => {
          console.log('Request completed.');
        }
      });
    }
  }

  async presentCardAlert(msg: string) {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Incorrect Billing Information',
      message: `${msg}. Please correct and retry.`,
      buttons: ['OK'],
      cssClass: 'custom-alert'
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async makeSuccessfulToast() {
    const toast = await this.toastCtrl.create({
      message: new IonicSafeString(`Your billing information has been accepted.<br><br>Thank you for you using the Golf Bet Whisperer platform!`),
      position: 'middle',
      color: 'primary',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        }
      ]
    });

    toast.present();
  }

  async presentErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Incorrect Payment Information',
      message: new IonicSafeString('Unfortunately your billing information was not accepted. <br><br>One likely error is an invalid CVV number. <br><br>Please correct and retry.'),
      buttons: ['OK'],
      cssClass: 'custom-alert'
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  selectedProductDescription() {
    let desc = '';
    if (this.selectedBillingProduct) {
      const { amount, description, recurringMethod } = this.selectedBillingProduct;
      desc = `You have selected the ${description} for $${amount}.`;
      if (recurringMethod === 'L') {
        desc = `You have selected the ${description} for a one time payment of $${amount}.`;
      }
    }

    return desc;
  }

  billingProductSelected(billingProduct: any) {
    this.productSelected = true;
    this.selectedBillingProduct = billingProduct;
    
    setTimeout(() => {
      if (this.iFrameLoaded === false) {
        this.loadBillingIFrame();
        this.iFrameLoaded = true;
        this.scrollToBottom()
      }
    }, 500);
  }

  scrollToBottom() {
    const modal = document.querySelector('ion-modal');

    if (modal) {
      const ionContent = modal.querySelector('ion-content');
      if (ionContent) {
        ionContent.scrollToBottom(500); // 500ms for smooth scrolling; modify as needed
      }
    }
  }


  loadBillingIFrame() {
    const z = new (window as any).HostedTokenization('pk_wdXqClnqD1tmPG2dDnICOyawGrYwl')
    const cardForm = z.create('card-form');
    this.cardForm = cardForm;
    cardForm.mount('#my-div')
    cardForm.setStyles({
      container: 'border: 2px solid blue',
    });
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
