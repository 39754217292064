import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, doc, getDoc, getDocs, getFirestore, query, where, limit } from 'firebase/firestore';

@Injectable()
export class PairingService extends BaseService {

  node = 'pairing';

  createPairing(data: any) {
    return super.create(this.node, data);
  }

  getByDocId(id) {
    return this.afs.collection(this.node).doc(id);
  }

  async getByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  async getForEventMod(eventId: string) {
    const db = getFirestore(); 
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId)
    )
    return (await getDocs(q)).docs;
    
  }

  getForEvent(eventId: string) {
    return this.afs.collection(this.node, ref => ref
      .where('eventId', '==', eventId));
  }

  getForEventOrderByTime(eventId) {
    return this.afs.collection(this.node, ref => ref
      .where('eventId', '==', eventId)
      .orderBy('startTime', 'asc'))
  }

  async getForEventAndMemberMod(eventId: string, memberId: string ) {
    const db = getFirestore(); 
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId),
      where('playerArray', 'array-contains', memberId),
      limit(1)
    )
    return (await getDocs(q)).docs;

  } 

  getForEventandMember(eventId: string, memberId: string) {
    return this.afs.collection(
      this.node,
      ref => ref
        .where('eventId', '==', eventId)
        .where('playerArray', 'array-contains', memberId)
        .limit(1)
    );
  }

  updatePairing(pairingId: string, data: any) {
    return super.update(this.node, pairingId, data);
  }

  updatePairingDetails(pairing) {
    const { id, players, playerArray, usePoints, pointsAmount, pointsDescription, pointsHandicapMethod,
       pointsHandicapPercentage, autoCalc, selectPartners, partners, hasTeams, hasProx, hasTwists, hasTurns,
       teamSize, twists, turns, proxs, team1, team2, excludePlayersFromSkins } = pairing;
    return this.afs.collection(this.node).doc(id)
      .update({
        'players': players,

        'playerArray': playerArray,
        'usePoints': usePoints,
        'pointsAmount': pointsAmount,
        'pointsDescription': pointsDescription,
        'pointsHandicapMethod': pointsHandicapMethod,
        'pointsHandicapPercentage': pointsHandicapPercentage,
        'autoCalc': autoCalc,
        'selectPartners': selectPartners,
        'partners': partners,
        'hasTeams': hasTeams,
        'hasProx': hasProx,
        'hasTwists': hasTwists,
        'hasTurns': hasTurns,
        'teamSize': teamSize,
        'twists': twists,
        'turns': turns,
        'proxs': proxs,
        'team1': team1,
        'team2': team2,
        'excludePlayersFromSkins': excludePlayersFromSkins,
      });
  }

  updateScoresAndHoleIndex(pairingId: string, players, holeIndex: number, 
    currentHoleIndex: number, holesPlayed: number, updateOpId: string, partners, proxs, twists, turns) {
    return this.afs.collection(this.node).doc(pairingId)
      .update({
        players,
        holeIndex,
        currentHoleIndex,
        holesPlayed,
        updateOpId,
        partners,
        proxs,
        twists,
        turns,
      });
  }

  updatePlayersAndPlayerArray(pairingId, players, playerArray) {
    return this.afs.collection(this.node).doc(pairingId)
      .update({ players, playerArray, });
  }
  
  updatePlayers(pairingId, players) {
    return this.afs.collection(this.node).doc(pairingId)
      .update({ players, });
  }

  updatePlayersAndPlayerArrayWithTeams(pairingId, players, playerArray, team1members, team2members) {
    return this.afs.collection(this.node).doc(pairingId)
      .update({ players, playerArray, team1members, team2members });
  }

  delete(id) {
    return this.afs.collection(this.node).doc(id).delete();
  }
}
