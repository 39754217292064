import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AbstractControl, ValidatorFn, ValidationErrors, FormGroup, FormArray } from '@angular/forms';
import { take, map, debounceTime } from 'rxjs/operators';
import { Injectable } from "@angular/core";

export function eventIsFull(): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
        return new Promise((resolve, reject) => [
            this.afs.collection('event')
        ])

    };
}

export function netBetween1and100(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value < 1 || control.value > 100) {
            return { between1and100: true };
        }

        return null;
    };
}

export function between1and18(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value < 1 || control.value > 18) {
            return { between1and18: true };
        }

        return null;
    };
}

export function greaterThanZero(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control && control.value <= 0) {
            return { greaterThanZero: true };
        }

        return null;
    };
}

export function greaterThanOne(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control && control.value <= 1) {
            return { greaterThanOne: true };
        }

        return null;
    };
}

export function validEmailAddress(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value != null) {
            const emails = control.value.split(',');
            let areAllValid = true;
            if (emails > '') {
                emails.forEach(email => {
                    if (!validateEmail(email.trim())) {
                        areAllValid = false;
                    }
                });
                if (!areAllValid) {
                    return { validEmailAddress: true };
                }
            }

            return null;
        }
    };
}

function validateEmail(email) {
    const re
        = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export class CustomValidator {
    static rsvpExists(afs: AngularFirestore) {
        return (control: AbstractControl) => {

            let userId = '';
            const parent = control.parent as FormGroup;
            if (parent) {
                userId = parent.get('userId').value;
            }
            const eventId = control.value;

            return afs.collection('rsvp', ref => ref.where('eventId', '==', eventId)
                .where('memberAuthId', '==', userId))
                .valueChanges().pipe(
                    debounceTime(500),
                    take(1),
                    map(arr => arr.length ? { rsvpExists: true } : null))
        }
    }
}
@Injectable()
export class ScoresToCountVsTeamSizeValidator {
    public grossScoresLessThanTeamsSize(): ValidatorFn {
        return (fg: FormGroup) => {
            const teamSize = fg.get('teamSize').value;
            const grossScoresToCount = fg.get('grossScoresToCount').value;
            return teamSize !== null && grossScoresToCount !== null && grossScoresToCount <= teamSize || (teamSize === null || grossScoresToCount === null)
                ? null
                : { grossGreater: true };
        };
    }
    public netScoresLessThanTeamsSize(): ValidatorFn {
        return (fg: FormGroup) => {
            const teamSize = fg.get('teamSize').value;
            const netScoresToCount = fg.get('netScoresToCount').value;
            return teamSize !== null && netScoresToCount !== null && netScoresToCount <= teamSize || (teamSize === null || netScoresToCount === null)
                ? null
                : { netGreater: true };
        };
    }
    public ballCountGreaterThanTeamSize(): ValidatorFn {
        return (fg: FormGroup) => {
            const teamSize = fg.get('teamSize').value;
            const grossScoresToCount = fg.get('grossScoresToCount').value;
            const netScoresToCount = fg.get('netScoresToCount').value;
            return teamSize !== null
                && grossScoresToCount !== null
                && netScoresToCount !== null 
                && (grossScoresToCount + netScoresToCount <= teamSize)
                || (grossScoresToCount === null || netScoresToCount === null || teamSize === null)
                ? null : { ballCountGreaterThanTeamSize: true };
        };
    }
}

@Injectable()
export class UnevenTeamSizeValidator {
    public teamSizeMustBeGreaterThanTwo(): ValidatorFn {
        return (fg: FormGroup) => {
            const teamSize = fg.get('teamSize').value;
            const unevenTeamMethod = fg.get('teamUnevenMethod').value;

            return teamSize !== null && unevenTeamMethod !== null && (teamSize > 2 || (teamSize <= 2 && unevenTeamMethod === 0)) || ((teamSize === undefined || teamSize === null)
                || (unevenTeamMethod === null || unevenTeamMethod === undefined))
                ? null
                : { unevenTeamMethodMustBeZero: true };
        };
    }
}

@Injectable()
export class PointsTeamSizeValidator {
    public teamSizeValidator(teamSize: number) : ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
           
            return control.value ? control.value.length === teamSize ? null : { teamSizeError: true } : null

        };
    }
}
@Injectable()
export class TeamSizeValidator {
    public maxNumberOfTeamMembers(teamSize: any): ValidatorFn {
        return (fg: FormGroup) => {
            let isValid = true;
            const teams = fg.get('teams') as FormArray;
            teams.controls.forEach(c => {
                const { members } = c.value;
                if (teamSize !== undefined && teamSize !== null) {
                    if (members.length > teamSize) {
                        isValid = false;
                    }
                }
            })

            return isValid ? null : { teamSizeTooLarge: true }
        };
    }
}