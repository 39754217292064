import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class GroupStatDetailService extends BaseService {

  node = 'group-stat-detail';


  getForGroupId(groupId: string) {

    return this.afs.collection(this.node, ref => 
        ref.where('group', '==', groupId))
  }

  getForGroupIdLatest(groupId: string) {

    return this.afs.collection(this.node, ref => 
        ref.where('group', '==', groupId)
        .orderBy('createdTS', 'desc')
        .limit(1))
  }
}