import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';

@Injectable()
export class EventFlightService extends BaseService {

    node = 'event-flight';

    createEventFlight(data: any) {
        return super.create(this.node, data);
    }

    getByDocId(id) {
        return this.afs.collection(this.node).doc(id);
    }

    async getForEventMod(eventId: string) {
        const db = getFirestore();
        const q = query(
            collection(db, this.node),
            where('eventId', '==', eventId),
        )
        return (await getDocs(q)).docs;

    }

    getForEvent(eventId: string) {
        return this.afs.collection(this.node, ref =>
            ref.where('eventId', '==', eventId));
    }

    getByFlightForEventAndPlayer(eventId: string, playerId: string) {
        return this.afs.collection(this.node, ref =>
            ref.where('eventId', '==', eventId)
                .where('players', 'array-contains', playerId))
            .get();
    }
}

