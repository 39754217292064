import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.page.html',
  styleUrls: ['./password-reset.page.scss'],
})
export class PasswordResetPage {
  
  validations = {
    'email': [
      { type: 'email', message: 'Please enter a valid email address' },
      { type: 'required', message: 'Email address is required' },
    ],
  }
  
  passwordResetForm = this.fb.group({
    email: new FormControl('', [Validators.email, Validators.required]),
  });

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
  ) { }
  
  passwordReset() {
    const data = this.passwordResetForm.value;
    this.modalCtrl.dismiss({  
      email: data.email 
    });
  }

  cancel() {
    this.modalCtrl.dismiss({
    })
  }

  get email() {
    return this.passwordResetForm.get('email');
  }
}