import { WorkerService } from './../services/worker-service';
import { PairingService } from './../services/pairing-service';
import { MemberService } from './../services/member.service';
import { CourseService } from './../services/course.services';
import { EventService } from './../services/event.service';
import { Subscription } from 'rxjs';
import { RsvpService } from './../services/rsvp.service';
import { Component, } from '@angular/core';
import { ModalController, NavParams, LoadingController, ToastController, AlertController, IonicSafeString } from '@ionic/angular';
import { formatHandicapIndexToNum, formatDisplayCourseHandicap, calcCourseHandicap, isUsingLowIndex } from '../common/helpers/reuse-functions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-create-pairing-modal',
  templateUrl: './create-pairing-modal.page.html',
  styleUrls: ['./create-pairing-modal.page.scss'],
})
export class CreatePairingModalPage {

  subscription: Subscription;
  thosePlaying = [];
  event: any;
  course: any;
  pairingsExist = false;
  isLoading = false;
  loader: any;

  constructor(
    private navParams: NavParams,
    private courseService: CourseService,
    private eventService: EventService,
    private memberService: MemberService,
    private pairingService: PairingService,
    private rsvpService: RsvpService,
    private workerService: WorkerService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
  ) { }


  async ionViewWillEnter() {
    this.subscription = new Subscription;
    const eventId = this.navParams.get('id');
    await this.getEventData(eventId);
    const msg = 'Loading'
    this.setLoading(msg);
    this.pairingsExist = await this.determineIfPairingsExist(eventId)
    if (this.pairingsExist) {
      this.populateFromPairings(eventId);
    } else {
      this.populateFromRsvps(eventId);
    }
  }

  async getEventData(eventId: string) {
    const eventSnapshot = await this.eventService.getByDocId(eventId).get().toPromise();
    this.event = eventSnapshot.data();
    const { course } = this.event;
    const courseSnapshot = await this.courseService.getCourseByDocId(course).get().toPromise();
    this.course = courseSnapshot.data();
  }

  async determineIfPairingsExist(eventId: string) {
    const pairingSnapshots = await this.pairingService.getForEvent(eventId).get().toPromise();
    return pairingSnapshots.size > 0
  }

  async populateFromRsvps(eventId: string) {
    const rsvps = [];
    const docs = await this.rsvpService.getRsvpThatArePlayingMod(eventId);
    for (const doc of docs) {
      const data = doc.data();
      rsvps.push(data);
    }
    
    let i = 0;
    const playersPerGroup = this.navParams.get('playersPerGroup');
    const teeTimeIncrement = this.navParams.get('teeTimeIncrement');
    const startingTime = this.navParams.get('startingTime');
    const eventTeeTimeType = this.navParams.get('eventTeeTimeType');
    const totalPlayers = docs.length;
    const numberOfGroups = Math.ceil(totalPlayers / Number(playersPerGroup)); 

    let remaining = totalPlayers;
    let currentTime = startingTime;
    for (let g = numberOfGroups; g > 0; g--) {
      let groupSize = remaining;
      if (g > 1) {
        groupSize = Math.floor(remaining / g);
      }
      for (let g2 = 0; g2 < groupSize; g2++) {
        const tempTime = {} as any;
        if (g2 === 0) {
          tempTime.type = 'h';
          if (g === numberOfGroups || eventTeeTimeType === '2') {
            tempTime.value = startingTime;
          } else {
            const nextTime = this.getNextTime(currentTime, teeTimeIncrement);
            tempTime.value = nextTime;
            currentTime = nextTime;
          }
          this.thosePlaying.push(tempTime);
        }

        const { firstname, lastname, memberAuthId } = rsvps[i];
        const tempRsvp = {
          type: 'd',
          value: `${firstname} ${lastname}`,
          memberId: memberAuthId,
        } as any;
        this.thosePlaying.push(tempRsvp);
        i++
      }
      remaining = remaining - groupSize;
    }

    this.dismissLoader();
  }

  getNextTime(currentTime: string, teeTimeIncrement: number) {

    if (currentTime.length === 5) {
      var hour = +currentTime.substr(0, 2);
      var minutes = +currentTime.substr(3, 2);
    } else {
      var hour = +currentTime.substr(0, 1);
      var minutes = +currentTime.substr(2, 2);
    }

    if ((minutes + teeTimeIncrement) >= 60) {
      const a = 60 - minutes;
      minutes = teeTimeIncrement - a;
      if (hour !== 12) {
        hour = hour + 1;
      } else {
        hour = 1;
      }
    } else {
      minutes = minutes + teeTimeIncrement;
    }

    if (minutes < 10) {
      return `${String(hour)}:0${String(minutes)}`;
    } else {
      return `${String(hour)}:${String(minutes)}`;
    }
  }

  async populateFromPairings(eventId: string) {
    const pairingSnapshots = await this.pairingService.getForEventOrderByTime(eventId).get().toPromise();
    const pairingDocs = pairingSnapshots.docs;
    for (const pairingDoc of pairingDocs) {
      const pairingData: any = pairingDoc.data();
      const { startTime, players } = pairingData;
      const header = {
        type: 'h',
        value: startTime,
      }
      this.thosePlaying.push(header);
      for (const player of players) {
        const { name, id: memberId } = player;

        const detail = {
          type: 'd',
          value: name,
          memberId,
        } as any;
        this.thosePlaying.push(detail);
      }
    }
    this.dismissLoader();
  }

  async savePairings() {

    const hasScores = await this.checkIfScoresExist();
    if (hasScores) {
      this.presentAlert();
    } else {
      this.continueWithSave();
    }
  }

  async continueWithSave() {

    const msg = 'Saving your pairings'
    this.setLoading(msg);
    let playerArray = [];
    let players = [];
    let time: string;
    let lowGuyCourseHandicap = 0;

    if (this.pairingsExist) {
      await this.deleteCurrentPairings(this.event.id);
    }

    const usesLowGuy = isUsingLowIndex(this.event);

    if (usesLowGuy) {
      const lowGuy = await this.findLowGuy();
      lowGuyCourseHandicap = lowGuy.courseHandicap;
    }

    for (let i = 0; i < this.thosePlaying.length; i++) {
      const { type, memberId } = this.thosePlaying[i];
      if (type === 'd') {
        const memberSnapshot = await this.memberService.getMemberByDocId(memberId).get().toPromise();
        const memberData: any = memberSnapshot.data();
        const { displayHandicapIndex, id, firstname, gender, lastname } = memberData;

        const theTee = this.eventTee();
        const par = this.eventTeePar();
        const handicapIndex = formatHandicapIndexToNum(displayHandicapIndex);
        const { slope, rating } = theTee;
        const courseHandicap = calcCourseHandicap(handicapIndex, Number(slope), par, rating);
        const displayCourseHandicap = formatDisplayCourseHandicap(courseHandicap);
        const scores = this.populateScores();
        const skinsHandicap = lowGuyCourseHandicap - courseHandicap;
        const displaySkinsHandicap = String(skinsHandicap);

        const player = {
          courseHandicap: courseHandicap,
          displayCourseHandicap,
          displayHandicapIndex,
          displaySkinsHandicap,
          flight: '999',
          gender,
          handicapIndex,
          id: id,
          scores,
          includeInSkins: true,
          name: `${firstname} ${lastname}`,
          skinsHandicap,
          tee: this.event.tee,
        } as any
        players.push(player);
        playerArray.push(memberId);
      }

      if ((type === 'h' && i > 0) || (i + 1 >= this.thosePlaying.length)) {
        await this.createPairing(playerArray, players, time);
        playerArray = [];
        players = [];
      }
      if (type === 'h') {
        time = this.thosePlaying[i].value
      }
    }

    const newWorker = {
      id: this.workerService.getId(),
      type: '16',
      eventId: this.event.id,
    }
    this.workerService.createEvent(newWorker);

    const toast = await this.toastCtrl.create({
      message: 'Your pairings have been created',
      duration: 5000,
      position: 'middle',
      color: 'primary',
    });
    toast.present();
    this.loader.dismiss();
    this.modalCtrl.dismiss();
  }


  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      cssClass: 'custom-alert',
      subHeader: 'Scores already exist for this event',
      message: new IonicSafeString(`You are trying to modify pairings that already have scores entered for today.
        <br><br>Currently it is not possible to do a mass change after the round has started.
        <br><br>The individual pairing{s) can be changed by each group`),
      buttons: [
        {
          text: 'OK',
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
      .then(() => this.modalCtrl.dismiss());
  }


  async checkIfScoresExist() {
    let scoresExist = false;

    const pairingSnapshots = await this.pairingService.getForEvent(this.event.id).get().toPromise();
    const pairingDocs = pairingSnapshots.docs;
    for (const pairingDoc of pairingDocs) {
      const pairingData: any = pairingDoc.data();
      const { players } = pairingData;
      for (const player of players) {
        const aScore = player.scores.find(s => s.gross > 0);
        if (aScore) {
          scoresExist = true;
        }
      }
    }

    return scoresExist;
  }

  createPairing(playerArray: any[], players: any[], time: any) {
    const { id: eventId, skinsFormat, eventFormatType, useStablefordQuotaScoring, flightOption, birdOrBetOption,
      doubleEagleAmount, eagleAmount, birdieAmount, parAmount, bogeyAmount, otherAmount } = this.event;

    const id = this.pairingService.getId();
    const theTee = this.eventTee();
    const handicapSet = theTee.handicapSet;
    const newPairing = {
      id,
      eventId,
      skinsFormat,
      eventFormatType,
      useStablefordQuotaScoring,
      flightOption,
      birdOrBetOption,
      doubleEagleAmount,
      eagleAmount,
      birdieAmount,
      parAmount,
      bogeyAmount,
      otherAmount,
      playerArray,
      players,
      handicapSet,
      startingHole: 1,
      holeIndex: 0,
      currentHoleIndex: 0,
      holesPlayed: 0,
      updateOpId: '',
      startTime: time,
      createdTS: this.pairingService.getTimeStamp(),
      createBetEvents: false,
      
    } as any;
    return this.pairingService.createPairing(newPairing);
  }

  eventTee() {
    return this.course.tees.find(((t: any) => t.id === this.event.tee));
  }

  eventTeePar() {
    return this.course.handicapSets.find(h => h.id = this.eventTee().handicapSet).par
  }

  async findLowGuy() {
    const { course: courseId, tee } = this.event;
    const courseDoc = await this.courseService.getCourseByDocId(courseId).get().toPromise();
    const courseData: any = courseDoc.data();
    const { tees, handicapSets } = courseData;
    const theTee = tees.find(t => t.id === tee);
    if (theTee) {
      var { handicapSet, slope, rating } = theTee;
      const theHandicapSet = handicapSets.find(h => h.id === handicapSet);
      if (theHandicapSet) {
        var { par } = theHandicapSet;
      }
    }

    const rsvpSnapshots = await this.rsvpService.getLowIndexRsvp(this.event.id, 99).get().toPromise();
    const rsvpDocs = rsvpSnapshots.docs;
    for (const rsvpDoc of rsvpDocs) {
      const rsvpData: any = rsvpDoc.data();
      const { handicapIndex, member } = rsvpData;
      const { id: memberId, firstname, lastname } = member;
      const courseHandicap = Math.round(Number(handicapIndex) * (slope / 113) - (Number(rating) - par));

      const lowSkinsGuy = {
        memberId,
        handicapIndex,
        name: `${firstname} ${lastname}`,
        courseHandicap,
        difference: 0,
      }

      return lowSkinsGuy;
    }
  }

  async deleteCurrentPairings(eventId: string) {
    const pairingSnapshots = await this.pairingService.getForEvent(eventId).get().toPromise();
    const pairingDocs = pairingSnapshots.docs;
    for (const pairingDoc of pairingDocs) {
      const pairingData: any = pairingDoc.data();
      const { id: pairingId } = pairingData;
      this.pairingService.delete(pairingId)
    }

    return
  }

  populateScores() {
    const scores = [];
    for (let i = 0; i < 18; i++) {
      const newHole = {
        gross: 0,
        net: 0,
        relationToParGross: 0,
        relationToParNet: 0,
        skinsNet: 0,
        displayGross: 0,
        displayNet: 0,
        isBoB: false,
        stablefordGross: 0,
        stablefordNet: 0,
        isAdjustment: false,
      } as any;

      scores.push(newHole);
    }

    return scores;

  }

  reorderItems(event) {
    const itemMove = this.thosePlaying.splice(event.detail.from, 1)[0];
    this.thosePlaying.splice(event.detail.to, 0, itemMove);
    event.detail.complete();
  }

  async setLoading(msg: string) {
    this.isLoading = true;
    this.loader = await this.loadingCtrl.create({
      message: msg,
      translucent: true,
      spinner: 'bubbles',
      duration: 5000
    });
    await this.loader.present();
    if (!this.isLoading) {
      this.loader.dismiss();
    }
    return this.loader;
  }

  dismissLoader() {
    this.loader.dismiss();
    this.isLoading = false;
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
