import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  swPushpayload: any;
    p = 'BC_7LdM5_G2qOu8AXrnzgmWHSmQzBq7rPCPrip4CfgENKahFe8_te6r3mURySxuMCYHph9qFTGjqx-RsoxNve38'
  constructor() {}


}