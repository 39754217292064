// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border1px {
  border: 1px solid white;
}

.border2px {
  border: 2px solid white;
}

.custom-card {
  width: 70%;
  margin: 0 auto;
}

.padding-left {
  padding-left: 8px;
}

.padding {
  padding: 10px;
}

h6 {
  text-align: center;
  font-weight: bold;
}

.para {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 90%;
}

.product-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.larger-font {
  font-size: 125%;
}

.button-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.center {
  text-align: center;
}

.selected-product-para {
  font-size: 120%;
  font-weight: bold;
  padding-left: 10px;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
}

.card-border {
  border-top: 1.5px solid var(--ion-color-new-blue);
  border-bottom: 1.5px solid var(--ion-color-new-blue);
}`, "",{"version":3,"sources":["webpack://./src/app/billing-modal/billing-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AAEE;EACE,uBAAA;AACJ;;AAEE;EACE,UAAA;EACA,cAAA;AACJ;;AAEE;EACE,iBAAA;AACJ;;AAEE;EACE,aAAA;AACJ;;AAEE;EACE,kBAAA;EACA,iBAAA;AACJ;;AAEE;EACM,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AACR;;AAEE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;;AAEE;EACE,eAAA;AACJ;;AAEE;EACE,kBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,kBAAA;AACJ;;AAEE;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,oBAAA;AACJ;;AAEE;EACE,iDAAA;EACA,oDAAA;AACJ","sourcesContent":[".border1px {\n    border: 1px solid white;\n  }\n\n  .border2px {\n    border: 2px solid white;\n  }\n\n  .custom-card {\n    width: 70%;\n    margin: 0 auto;\n  }\n\n  .padding-left {\n    padding-left: 8px;\n  }\n\n  .padding {\n    padding: 10px;\n  }\n  \n  h6 {\n    text-align: center;\n    font-weight: bold;\n  }\n\n  .para {\n        padding-left: 10px;\n        padding-right: 5px;\n        padding-top: 0px;\n        padding-bottom: 0px;\n        font-size: 90%\n  }\n  \n  .product-card {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n  }\n\n  .larger-font {\n    font-size: 125%\n  }\n\n  .button-padding {\n    padding-left: 10px;\n    padding-right: 10px;\n  }\n\n  .center { \n    text-align: center;\n  }\n\n  .selected-product-para {\n    font-size: 120%;\n    font-weight: bold;\n    padding-left: 10px;\n    text-align: center;\n  }\n\n  .button-container {\n    display: flex;\n    justify-content: center; \n    align-items: center; \n    height: 100%; \n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 30px;\n  }\n\n  .card-border {\n    border-top: 1.5px solid var(--ion-color-new-blue);\n    border-bottom: 1.5px solid var(--ion-color-new-blue);\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
