import { AuthService } from './../services/auth.service';
import { EventService } from './../services/event.service';
import { EventBetService } from './../services/event-bet.service';
import { NavParams, LoadingController, ModalController } from '@ionic/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-bet-history-modal',
  templateUrl: './bet-history-modal.page.html',
  styleUrls: ['./bet-history-modal.page.scss'],
})
export class BetHistoryModalPage {
  betId: string;
  eventBets = [];
  total = 0;
  loader: any;
  firstTime = true;
  betDesc = '';

  constructor(
    private navParams: NavParams,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private eventBetService: EventBetService,
    private eventService: EventService,

  ) { }

  ionViewWillEnter() {
    this.betId = this.navParams.get('betId');
    this.setLoading();
    this.getEventBets(this.betId);
  }

  async setLoading() {
    this.loader = await this.loadingCtrl.create({
      message: 'Loading',
      translucent: true,
      spinner: 'bubbles',
      duration: 15000
    });
    await this.loader.present()
  }

  more() {
    this.getEventBets(this.betId)
  }

  async getEventBets(betId: string) {
    const currentUser = await this.authService.currentUser()
    const docs = await this.eventBetService.getEventBetListForBetIdMod(betId);
    const eventBetArr = [];
    for (const doc of docs) {
      const data = doc.data();
      if (this.firstTime) {
        this.buildDesc(data);
        this.firstTime = false; 
      }
      const { id, eventId, team1members, bet } = data;
      const isTeam1member = team1members.includes(currentUser);
      const { betAmount } = bet;
      const eventSnapshot = await this.eventService.getByDocId(eventId).get().toPromise()
      const eventData: any = eventSnapshot.data()
      const { eventDate } = eventData;
      const totalBets = this.determineNumberOfBets(data)
      let betTotalAmount = betAmount;
      if (!isTeam1member) {
        betTotalAmount = betTotalAmount * -1;
      }

      const dte = new Date(eventDate);
      const newEventBetSummary = {
        eventDate: dte.toISOString().slice(0, 10),
        eventTotal: totalBets * betTotalAmount,
      } as any;
      eventBetArr.push(newEventBetSummary);
    }
    this.eventBets = eventBetArr.sort((a, b) => a.eventDate > b.eventDate ? -1 : a.eventDate < b.eventDate ? 1 : 0); eventBetArr;
    this.total = eventBetArr.reduce((tot: number, { eventTotal }) => tot + eventTotal, 0);
    if (this.loader) {
      this.loader.dismiss();
    }
  }

  async buildDesc(eventBet: any) {
    const { team1details, team2details, team1name, team2name } = eventBet;
    const currentUser = await this.authService.currentUser();
    const team1Index = team1details.findIndex((t: any)  => t.id === currentUser);
    const isTeam1 = team1Index > -1;    
    this.betDesc = isTeam1 && team1details.length === 1 ? `You vs. ${team2name}` :
        !isTeam1 && team2details.length === 1 ? `You vs. ${team1name}` :
        isTeam1 ? `${team1name} vs. ${team2name}` : 
        `${team2name} vs. ${team1name}`;
  }

  determineNumberOfBets(eventBet: any) {
    const { bet } = eventBet;
    const { betFormat } = bet;
    let totalBets = 0;
    // two down bets
    if ([0, 1].includes(betFormat)) {
      totalBets = this.determineTwoDownTotalBets(eventBet)
    }
    // match plays and nassau and per-hole
    if ([2, 3, 4, 7, 8].includes(betFormat)) {
      totalBets = this.determineBetsWithSidesTotalBets(eventBet)
    }
    // vegas
    if ([5, 6].includes(betFormat)) {
      totalBets = this.determineBetsForVegas(eventBet);
    }

    return totalBets;
  }

  determineTwoDownTotalBets(eventBet: any) {
    const { balls } = eventBet;
    let totalBets = 0;
    for (const ball of balls) {
      const { sides } = ball;
      for (const side of sides) {
        const { bets } = side;
        for (const bet of bets) {
          const { currentTotal } = bet;
          if (currentTotal > 0) {
            totalBets++
          }
          if (currentTotal < 0) {
            totalBets--
          }
        }
      }
    }

    return totalBets;
  }

  determineBetsWithSidesTotalBets(eventBet: any) {
    let totalBets = 0;
    const { sides } = eventBet;
    for (const side of sides) {
      const { currentTotal } = side;
      if (currentTotal > 0) {
        totalBets++;
      }
      if (currentTotal < 0) {
        totalBets--;
      }
    }

    return totalBets;
  }

  determineBetsForVegas(eventBet: any) {
    let totalBets = 0;
    const { sides } = eventBet;
    for (const side of sides) {
      const { currentTotal } = side;
      totalBets = totalBets + currentTotal;
    }

    return totalBets;
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
