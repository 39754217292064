export const removeEvent = 'Warning: Removing the event will also remove all player RSVPs for the event.  Would you like to continue?';
export const lockEventInquiry =
    'After the pairings are created would you like to lock the event? ' +
     'Event attributes and player statuses will be locked.  You can unlock later if required';
export const pairingsExist = 'This event already has pairings established, do you want to replace the current pairings?';
export const orphanedGuests = 'You have added guests but you yourself are not playing, do you want to continue?';
export const missingScores = 'You have not entered a score for each player in your group; do you want to continue?';
export const morePlayersThanAllowed =
   'You have selected more players for your group than recommended for your event; do you want to continue?';
export const isNonStd2DownRequired =
    'You have selected the standard 2 down bet format with teams larger than 2 players. If you need a ball count greater than one, ' +
    'please change bet format to the Automatic Press - nonstd option.  Do you want to continue?';

