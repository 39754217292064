import { BaseService } from './base.service';
import { Course } from './../common/model-classes/course';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';

@Injectable()
export class CourseService extends BaseService {

  node = 'course';

  createCourse(data: any) {
    return super.create(this.node, data);
  }

  updateCourse(id: string, data: any) {
    return super.update(this.node, id, data);
  }

  getCourseList() {
    return this.afs.collection<Course>(this.node, ref =>
      ref.orderBy('courseName', 'asc'));
  }

  async getCourseListMod() {

    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      orderBy('courseName', 'asc'),
    )
      
    return (await getDocs(q)).docs;
  }


 

  getForGhinId(ghinCourseId: string) {
    return this.afs.collection<Course>('course', ref => ref.where('ghinCourseId', '==', ghinCourseId));
  }


  async getForGhinIdMod(ghinCourseId: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('ghinCourseId', '==', ghinCourseId),
    );

    return (await getDocs(q)).docs;
  }

  async getCourseDataByDocId(id: string) {
    const courseSnapshot = await this.afs.collection('course').doc(id).get().toPromise();

    return courseSnapshot.data();
  }

  getCourseByDocId(id) {
    return this.afs.collection('course').doc(id);
  }

  async getCourseByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  addMember(id, memberId) {
    return this.afs.collection('course').doc(id).update({ 'members': firebase.firestore.FieldValue.arrayUnion(memberId) });
  }

  removeMember(id, member) {
    return this.afs.collection('course').doc(id).update
      ({ 'members': firebase.firestore.FieldValue.arrayRemove(Object.assign({}, member)) });
  }

  addGroup(id, courseGroup) {
    return this.afs.collection('course').doc(id)
      .update({ 'groups': firebase.firestore.FieldValue.arrayUnion(courseGroup) });
  }

  removeGroup(id, group) {
    return this.afs.collection('course').doc(id).update({ 'groups': firebase.firestore.FieldValue.arrayRemove(group) });
  }

  updateGroups(id, groupArray) {
    return this.afs.collection('course').doc(id).update({ 'groups': groupArray });
  }

}
  