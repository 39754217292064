import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class BetOfferService extends BaseService {

    node = 'bet-offer';

    createBetOffer(data: any) {
        return super.create(this.node, data);
      }

      getByDocId(id) {
        return this.afs.collection(this.node).doc(id);
      }
    
      getbetListForUserFromPlayerArray(user: string, currentDate: string) {
        return this.afs.collection(this.node, ref => ref.where('players', 'array-contains', user)
                                        .where('compareEventDate', '>=', currentDate))
      }

      updateAcceptStatus(id: string, status: string, user: string, userName: string,  ) {
        return this.afs.collection(this.node).doc(id)
          .update({'status': status,
                   'acceptUser': user, 
                   'acceptUserName': userName });
      }

      updateRejectStatus(id: string, status: string, user: string, userName: string,  ) {
        return this.afs.collection(this.node).doc(id)
          .update({'status': status,
                   'rejectUser': user, 
                   'rejectUserName': userName });
      }

      delete(id) {
        return this.afs.collection(this.node).doc(id).delete();
    }
  
}