import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, getDocs, getFirestore, limit, orderBy, query, where, doc, getDoc} from 'firebase/firestore';

@Injectable()
export class BetService extends BaseService {

  node = 'bet';

  createBet(data: any) {
    return super.create(this.node, data)
  }

  getByDocId(id) {
    return this.afs.collection(this.node).doc(id);
  }

  async getByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  getBetListForUser(user) {
    return this.afs.collection(this.node, ref => ref.where('betAuthId', '==', user));
  }

  getbetListForUserFromPlayerArray(user: string, currentDate: string) {
    return this.afs.collection(
      this.node, ref =>
      ref.where('players', 'array-contains', user)
        .where('compareEventDate', '>=', currentDate))
  }

  toggleBetStatus(id: string, status) {
    return this.afs.collection(this.node).doc(id)
      .update({
        isActive: status,
      });
  }

  updateBet(id: string, data: any) {
    return super.update(this.node, id, data);
  }

  delete(id) {
    return this.afs.collection(this.node).doc(id).delete();
  }
}
