// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first {
  font-weight: bold;
  padding-right: 7px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding-left: 5px;
  padding-bottom: 8px;
}

tr:nth-child(even) {
  background: lightgray;
}

p {
  margin: 0em;
  text-align: center;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/tee-sheet/tee-sheet.page.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,yBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".first {\n    font-weight: bold;\n    padding-right: 7px;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n}\n\ntd {\n    padding-left: 5px;\n    padding-bottom: 8px;\n}\n\ntr:nth-child(even) {\n    background: lightgray;\n}\n\np { \n    margin: 0em;\n    text-align: center;\n    font-weight: bold;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
