import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//  Firebase modules
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { ErrorService } from './services/error.service';
import { CourseService } from './services/course.services';
import { CourseMemberService } from './services/course-member.service';
import { EventService } from './services/event.service';
import { GroupEventService } from './services/group-event';
import { GroupService } from './services/group.service';
import { GroupInviteService } from './services/group-invite.service';
import { HandicapHistoryService } from './services/handicap-history.service';
import { MemberService } from './services/member.service';
import { HttpService } from './services/http.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { GlobalService } from './services/global.service';
import { BetService } from './services/bet.service';
import { BetOfferService } from './services/bet-offer.service';
import { EventPlayingCountService } from './services/event-playing-count.service';
import { WorkerService } from './services/worker-service';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationService } from './services/notication.service';
import { RsvpService } from './services/rsvp.service';
import { EventFlightService } from './services/event-flight.service';
import { PairingService } from './services/pairing-service';
import { PointsTeamSizeValidator, ScoresToCountVsTeamSizeValidator, TeamSizeValidator, UnevenTeamSizeValidator } from './common/helpers/validators';
import { FindCourseModalPage } from './find-course-modal/find-course-modal.page';
import { BetSettledService } from './services/bet-settled.service';
import { MemberFavoriteService } from './services/member-favorite.service';
import { CtpDetailService } from './services/ctp-detail.service';
import { MonsterDetailService } from './services/monster-detail.service';
import { EventBoBService } from './services/event-BoB.service';
import { EventBetService } from './services/event-bet.service';
import { EventSkinService } from './services/event-skins.service';
import { EventSummaryTotalService } from './services/event-summary-total.service';
import { EventSummaryAdjustmentService } from './services/event-summary-adjustment.service';
import { PairingUpdateEventService } from './services/pairing-update-event.service';
import { PairingAddedEventService } from './services/pairing-added-event';
import { PlayerScoreService } from './services/player-score.service';
import { TeamScoreService } from './services/team-scores.service';
import { TeamPairingModalPage } from './team-pairing-modal/team-pairing-modal.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BetTemplateService } from './services/bet-template.service';
import { PlayerStatDetailService } from './services/player-stat-detail.service';
import { GroupStatDetailService } from './services/group-stat-detail.service';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { BetModalPage } from './bet-modal/bet-modal.page';
import { AddPressModalPage } from './add-press-modal/add-press-modal.page';
import { CtpModalPage } from './ctp-modal/ctp-modal.page';
import { MonsterModalPage } from './monster-modal/monster-modal.page';
import { EventSummaryTransactionService } from './services/event-summary-transaction.service';
import { EventSummaryWarningService } from './services/event-summary-warning.service';
import { EventResultsModalPage } from './event-results-modal/event-results-modal.page';
import { Capacitor } from '@capacitor/core';
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { getApp } from 'firebase/app';
import { NotificationsComponent } from './notifications/notifications.component';
import { TeamModalPagePage } from './team-modal-page/team-modal-page.page';
import { NewPairingModalPage } from './new-pairing-modal/new-pairing-modal.page';
import { FlipWolfModalPage } from './flip-wolf-modal/flip-wolf-modal.page';
import { RegisterModalPage } from './register-modal/register-modal.page';
import { PasswordResetPage } from './password-reset/password-reset.page';
import { EventInfoModalPage } from './event-info-modal/event-info-modal.page';
import { ModalPage } from './modal/modal.page';
import { BetPairingModalPage } from './bet-pairing-modal/bet-pairing-modal.page';
import { BetHistoryModalPage } from './bet-history-modal/bet-history-modal.page';
import { CreatePairingModalPage } from './create-pairing-modal/create-pairing-modal.page';
import { GuestModalPage } from './guest-modal/guest-modal.page';
import { MassUpdateModalPage } from './mass-update-modal/mass-update-modal.page';
import { PcourseMemberService } from './services/pcourse-member.service';
import { OtherScoreModalPage } from './other-score-modal/other-score-modal.page';
import { GroupHelpModalPage } from './group-help-modal/group-help-modal.page';
import { ChangeEmailModalPage } from './change-email-modal/change-email-modal.page';
import { PreferredTeeModalPage } from './preferred-tee-modal/preferred-tee-modal.page';
import { EventFlightBreaksService } from './services/event-flight-breaks.service';
import { SkinCtpSummaryModalPage } from './skin-ctp-summary-modal/skin-ctp-summary-modal.page';
import { StagedPairingService } from './services/staged-pairing.service';
import { SetGroupModalPage } from './set-group-modal/set-group-modal.page';
import { BuildPairingsModalPage } from './build-pairings-modal/build-pairings-modal.page';
import { TeeSheetPage } from './tee-sheet/tee-sheet.page';
import { SpeedTestModule } from 'ng-speed-test';
import { MassUpdateEventService } from './services/mass-update-event';
import { ErrorLogService } from './services/error-log.service';
import { DeleteAccountModalPage } from './delete-account-modal/delete-account-modal.page';
import { PairingDeleteEventService } from './services/pairing-delete-event.service';
import { PairingChangeEventService } from './services/pairing-change-event.service';
import { DeletePressModalPage } from './delete-press-modal/delete-press-modal.page';
import { PushNotificationService } from './services/push-notification.service';
import { DeviceService } from './services/device.service';
import { BillingProductService } from './services/billing-product.service';
import { BillingTransactionService } from './services/billing-transaction.service';
import { BillingModalPage } from './billing-modal/billing-modal.page';
import { ChangeBillingModalPage } from './change-billing-modal/change-billing-modal.page';
import { StorageService } from './services/storage-service';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({ declarations: [AppComponent, FindCourseModalPage, NewPairingModalPage, TeamPairingModalPage, BetModalPage,
        AddPressModalPage, CtpModalPage, MonsterModalPage, EventResultsModalPage, NotificationsComponent, TeamModalPagePage, RegisterModalPage,
        FlipWolfModalPage, PasswordResetPage, EventInfoModalPage, ModalPage, BetPairingModalPage, BetHistoryModalPage, CreatePairingModalPage, GuestModalPage, MassUpdateModalPage,
        OtherScoreModalPage, GroupHelpModalPage, ChangeEmailModalPage, PreferredTeeModalPage, SkinCtpSummaryModalPage, SetGroupModalPage, BuildPairingsModalPage, TeeSheetPage,
        DeleteAccountModalPage, DeletePressModalPage, BillingModalPage, ChangeBillingModalPage,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
        AppRoutingModule,
        AngularFireDatabaseModule,
        FormsModule,
        ReactiveFormsModule,
        SpeedTestModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => {
            if (Capacitor.isNativePlatform()) {
                return initializeAuth(getApp(), {
                    persistence: indexedDBLocalPersistence,
                });
            }
            else {
                return getAuth();
            }
        }),
        ServiceWorkerModule.register('/assets/combined-sw.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
        AuthService,
        ClipboardService,
        ErrorService,
        BetService,
        BetOfferService,
        BetSettledService,
        BetTemplateService,
        BillingProductService,
        BillingTransactionService,
        EventBoBService,
        CourseService,
        CourseMemberService,
        CtpDetailService,
        DeviceService,
        ErrorLogService,
        EventBetService,
        EventSkinService,
        EventSummaryAdjustmentService,
        EventSummaryTotalService,
        EventSummaryTransactionService,
        EventSummaryWarningService,
        EventService,
        EventFlightService,
        EventFlightBreaksService,
        EventPlayingCountService,
        MassUpdateEventService,
        MemberFavoriteService,
        PushNotificationService,
        GlobalService,
        GroupEventService,
        GroupService,
        GroupStatDetailService,
        GroupInviteService,
        HandicapHistoryService,
        HttpService,
        MemberService,
        MonsterDetailService,
        NotificationService,
        PairingService,
        PairingUpdateEventService,
        PairingDeleteEventService,
        PairingChangeEventService,
        PairingAddedEventService,
        PcourseMemberService,
        PlayerStatDetailService,
        PlayerScoreService,
        RsvpService,
        StorageService,
        StagedPairingService,
        TeamScoreService,
        WorkerService,
        ScoresToCountVsTeamSizeValidator,
        UnevenTeamSizeValidator,
        PointsTeamSizeValidator,
        TeamSizeValidator,
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })

export class AppModule {}