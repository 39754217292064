// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smaller {
  font-size: 75%;
}

.container {
  padding-top: 15px;
  width: 100%;
}

.shorter {
  height: 1.35em;
}

.padding-l {
  padding-left: 10px;
}

table tr td {
  padding-bottom: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/mass-update-modal/mass-update-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,iBAAA;EACA,WAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,oBAAA;AACJ","sourcesContent":[".smaller {\n    font-size: 75%\n}\n\n.container {\n    padding-top: 15px;\n    width: 100%;\n}\n\n.shorter {\n    height: 1.35em;\n}\n\n.padding-l {\n    padding-left: 10px;\n}\n\ntable tr td {\n    padding-bottom: 13px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
