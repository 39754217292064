import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';

@Injectable()
export class GroupEventService extends BaseService {

  node = 'group-event';

  createGroupEvent(data: any) {
    return super.create(this.node, data);
  }

  getForGroup(group) {
    return this.afs.collection('group-event', ref => ref.where('group', '==', group ));
  }

  getForEvent(event) {
    return this.afs.collection('group-event', ref => ref.where('event', '==', event ));
  }

  async getForEventMod(eventId: string) {
      const db = getFirestore();
      const q = query(
        collection(db, this.node),
        where('event', '==', eventId),
      );

      return (await getDocs(q)).docs;
  }

  delete(id) {
    return this.afs.collection('group-event').doc(id).delete();
  }

}

