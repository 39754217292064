import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, getDocs, getFirestore, orderBy, query, where, } from 'firebase/firestore';

@Injectable()
export class CourseMemberService extends BaseService {

  node = 'course-member';

  createCourseMember(data: any) {
    return super.create(this.node, data);
  }
 
  getForCourse(course: string) {
    return this.afs.collection('course-member', ref => ref.where('course', '==', course).orderBy('firstname', 'asc').orderBy('lastname', 'asc'));
  }

  async getForCourseMod(course: string) {
      const db = getFirestore(); 
      const q = query(
        collection(db, this.node),
        where('course', '==', course),
        orderBy('firstname', 'asc'),
        orderBy('lastname', 'asc'),
      )
      return (await getDocs(q)).docs;
  }

  getForMember(member: string) {
    return this.afs.collection('course-member', ref => ref.where('member', '==', member).orderBy('lastname', 'asc').orderBy('firstname', 'asc'));
  }

  getForCourseAndMember(course: string, member: string) {
    return this.afs.collection('course-member', ref =>
      ref.where('course', '==', course)
        .where('member', '==', member));
  }

  async getForCourseAndMemberMod(course: string, member: string) {
    const db = getFirestore(); 
    const q = query(
      collection(db, this.node),
      where('course', '==', course),
      where('member', '==', member)
    )
    return (await getDocs(q)).docs;
    
  }
}
