import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class WorkerService extends BaseService {

  node = 'worker';

  createEvent(data) {
    return super.create(this.node, data);
  }

  delete(id) {
      return this.afs.collection(this.node).doc(id).delete();
  }

}
