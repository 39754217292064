import { BetService } from './../services/bet.service';
import { BetOfferService } from './../services/bet-offer.service';
import { Subscription } from 'rxjs';
import { NotificationService } from './../services/notication.service';
import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { PopoverController, ToastController } from '@ionic/angular';
import { MemberService } from '../services/member.service';
import { generateToast } from '../common/helpers/toasts';
import { betHandicapDescription } from '../common/helpers/reuse-functions';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {

  subscription: Subscription;
  notifications: any[] = [];
  completedBetOfferNots: any[] = [];
  asOfDate: any;
  sevenDaysNumLit = 604800000;
  currentUser;

  constructor(
    private authService: AuthService,
    private betService: BetService,
    private betOfferService: BetOfferService,
    private memberService: MemberService,
    private notificationService: NotificationService,
    private router: Router,
    private popoverController: PopoverController,
    private toastCtrl: ToastController,
  ) { }

  async ionViewWillEnter() {
    this.currentUser = await this.authService.currentUser();
    this.subscription = new (Subscription)();
    this.asOfDate = Date.now() - this.sevenDaysNumLit;
    this.getNotifications(new Date(this.asOfDate));
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  moreNotifications() {
    this.asOfDate = this.asOfDate - this.sevenDaysNumLit;
    this.getNotifications(new Date(this.asOfDate));
  }

  getNotifications(asOfDate: Date) {
  
    const subscription = this.notificationService.getForOwnerAndDays(this.currentUser, asOfDate)
      .valueChanges()
      .subscribe((notifications: any) => {
        this.notifications = notifications.map( not => {
          not.displayTime = this.calcDisplayNotTime(not.createdTS);
          return not;
        });

        for (const notification of notifications) {
          const { id, isRead, type, betOffer } = notification;
          if (isRead === false) {
            this.notificationService.updateIsReadStatus(id, true);
          }
          if (type === 3 || type === 4 || type === 5) {
            this.completedBetOfferNots.push(betOffer.id);
          }
        }
      });
    this.subscription.add(subscription);
  }

  calcDisplayNotTime(createdTS: any) {
    const currentDate = new Date().getTime();
    const dateDiff = currentDate - new Date(createdTS.toDate()).getTime();
    const dateDiffInDays = dateDiff / (1000 * 3600 * 24);

    let displayTime = ``;
    if (dateDiffInDays > 1) {
      const days = dateDiffInDays.toFixed(0);
      displayTime = `${days}d`;
    }
    // less than a day
    if (dateDiffInDays < 1) {
      // less than an hour
      if (dateDiffInDays < .04166666667) {
        // less than a minute  
        if (dateDiffInDays < .0006944444) {
          const seconds = (dateDiffInDays * 3600 * 24).toFixed(0);
          displayTime = `${seconds}s`;
        } else {
          const minutes = (dateDiffInDays * 24 * 60).toFixed(0);

          displayTime = `${minutes}m`;
        }

      } else {
        const hours = (dateDiffInDays * 24).toFixed(0);
        displayTime = `${hours}h`;
      }
    }

    return displayTime;

  }

  goToRsvp(rsvpId, disableOnOpen: boolean) {

    if (disableOnOpen) {
    }
    const newRoute = 'rsvp';

    this.dismissClick()
      .then(() => {
        if (!disableOnOpen) {
          this.router.navigate([newRoute], { queryParams: { rsvpId } });
        } else {
          this.router.navigate([newRoute], { queryParams: { rsvpId, disableOnOpen } });
        }
      });
  }

  async acceptBetOffer(betOffer: any) {
    const acceptName = await this.getMemberName();
    const { bet, firstname: boFirstname, lastname: boLastname } = betOffer;
    const newBet = bet;
    newBet.acceptedByUser = acceptName;
    newBet.offeredByUser = `${boFirstname} ${boLastname}`;
    newBet.id = this.betService.getId();
    newBet.isActive = true;
    await this.betService.createBet(newBet);
    await this.deleteBetOffer(3, betOffer, acceptName, 1);
    const msg = `Congratulations!  The bet has been accepted and added to your active bets`;
    generateToast(this.toastCtrl, msg, 5000);
  }

  async rejectBetOffer(betOffer: any) {
    const rejectName = await this.getMemberName();
    await this.deleteBetOffer(4, betOffer, rejectName, 2);
    const msg = `No action!!! The bet has been rejected`;
    generateToast(this.toastCtrl, msg, 5000);
  }

  async removeBetOffer(betOffer: any) {
    const removeName = await this.getMemberName();
    await this.deleteBetOffer(5, betOffer, removeName, 3);
    const msg = `Your offer for a bet has been removed`;
    generateToast(this.toastCtrl, msg, 5000);
  }

  async getMemberName() {
    const memberSnapshot = await this.memberService.getMemberByDocId(this.currentUser).get().toPromise();
    const memberData: any = memberSnapshot.data();
    const { firstname, lastname } = memberData;
    const memberName = `${firstname} ${lastname}`;
    return memberName;
  }

  canAccept(bet: any) {
    let team2Index = -1;
    let isTeam2 = false;
    if (bet != null) {
      const { team2members } = bet;
      team2Index = team2members.findIndex(m => m === this.currentUser);
    }

    if (team2Index > -1) {
      isTeam2 = true;
    }

    return isTeam2;
  }

  canRemove(bet: any) {
    let team1Index = -1;
    let isTeam1 = false;
    if (bet != null) {
      const { team1members } = bet;
      team1Index = team1members.findIndex(m => m === this.currentUser);
    }

    if (team1Index > -1) {
      isTeam1 = true;
    }

    return isTeam1;
  }

  async deleteBetOffer(notType: number, betOffer: any, acceptRejectName?: string, acceptRejectType?: number) {
    const { id, players } = betOffer;
    const promises = [];
    for (const player of players) {
      const newNote = this.createNewNote(notType, betOffer, player, acceptRejectName, acceptRejectType);
      const p = this.notificationService.createNotification(newNote);
      promises.push(p);
    }
    await Promise.all(promises);
    this.betOfferService.delete(id);
  }

  createNewNote(notType: any, betOffer: any, player: string, acceptRejectName?: string, acceptRejectType?: number) {
    const { id, team1members, team1Handicaps, team2Handicaps, bet,
      firstname, lastname } = betOffer;
    const { eventDate, betHandicapMethod, team1name, team2name } = bet;

    const newNot = {} as any;
    newNot.id = this.notificationService.getId();
    newNot.type = notType;
    const theTeam = team1members.find((m: any) => m === player);
    if (theTeam != null) {
      newNot.teamsDesc = `${team1name} vs ${team2name}`;
    } else {
      newNot.teamsDesc = `${team2name} vs ${team1name}`;
    }
    if (acceptRejectName != null) {
      if (acceptRejectType === 1) {
        newNot.acceptedByName = acceptRejectName;
      }
      if (acceptRejectType === 2) {
        newNot.rejectedByName = acceptRejectName;
      }
      if (acceptRejectType === 3) {
        newNot.removedByName = acceptRejectName;
      }
    }
    newNot.body = newNot.teamsDesc;
    switch (notType) {
      case 2: {
        newNot.title = 'Bet Offer';
        newNot.body = `${newNot.teamsDesc} offered by: ${newNot.offeredBy}`;
        break;
      }
      case 3: {
        newNot.title = 'Bet Offer Accepted';
        newNot.body = `${newNot.teamsDesc} accepted by: ${acceptRejectName}`;
        break;
      }
      case 4: {
        newNot.title = 'Bet Offer Rejected';
        newNot.body = `${newNot.teamsDesc} rejected by: ${acceptRejectName}`;
        break;
      }
      case 5: {
        newNot.title = 'Bet Offer Removed';
        break;
      }
    }
    newNot.isRead = false;
    newNot.owner = player;
    newNot.betOfferId = id;
    newNot.betOffer = betOffer;
    newNot.eventDate = eventDate;
    switch (betHandicapMethod) {
      case betHandicapMethod === 1: {
        newNot.betHandicapDesc = 'Handicap: Even';
        break;
      }
      case betHandicapMethod === 2: {
        newNot.bethandicapDesc = `Net off of the low handicap for the bet`;
        break;

      }
      case betHandicapMethod === 3: {
        newNot.beethandicapDesc = `Net using full handicaps`;
        break;
      }
      case betHandicapMethod === 4: {
        let team1ManualDesc = ``;
        let firstTime = true;
        for (const team1Handicap of team1Handicaps) {
          const { name, strokes } = team1Handicap;
          if (firstTime) {
            team1ManualDesc = team1ManualDesc + `${name}(${strokes})`;
            firstTime = false;
          } else {
            team1ManualDesc = team1ManualDesc + `/${name}(${strokes})`;
          }
          let team2ManualDesc = ``;
          let firstTimeT2 = true;
          for (const team2Handicap of team2Handicaps) {
            const { name: t2name, strokes: t2strokes } = team2Handicap;
            if (firstTimeT2) {
              team2ManualDesc = team2ManualDesc + `${t2name}(${t2strokes})`;
              firstTimeT2 = false;
            } else {
              team2ManualDesc = team2ManualDesc + `/${t2name}(${t2strokes})`;
            }
          }
          newNot.betHandicapDesc = `Net ${team1ManualDesc} vs. ${team2ManualDesc}`;
        }
      }
    }
    newNot.offeredBy = `${firstname} ${lastname}`;
    return newNot;
  }

  completedBetOfferExists(betOfferId: string) {
    let found = false;
    if (betOfferId != null) {
      const theBetOffer = this.completedBetOfferNots.find(b => b === betOfferId);
      if (theBetOffer != null) {
        found = true;
      }
      return found;
    }
  }

  getBetHandicapDescription(betHandicapMethod: number, team1Handicaps: any[], team2Handicaps: any[]) {
    const description = betHandicapDescription(betHandicapMethod, team1Handicaps, team2Handicaps);
    return description;
  }

  async dismissClick() {
    await this.popoverController.dismiss();
  }
}
