import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class EventBoBService extends BaseService {

    node = 'event-BoB';

      getByDocId(id) {
        return this.afs.collection(this.node).doc(id);
      }
    
      getEventBoBListForUserAndEvent(user: string, eventId: string) {
        return this.afs.collection(this.node, ref => 
            ref.where('eventId', '==', eventId)
             .where('players', 'array-contains', user));
      }

      
      getEventBoBListForEvent(eventId: string) {
        return this.afs.collection(this.node, ref => 
            ref.where('eventId', '==', eventId))
      }
}