import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, getDocs, getFirestore, limit, orderBy, query, where, doc, getDoc } from 'firebase/firestore';

@Injectable()
export class RsvpService extends BaseService {

  node = 'rsvp';

  createRsvp(data: any) {
    return super.create(this.node, data);
  }

  updateRsvp(id: string, data: any) {
    return super.update(this.node, id, data);
  }

  getByDocId(id) {
    return this.afs.collection(this.node).doc(id);
  }

  async getByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  getRsvpListForUserWithCurrentDate(user, currentDate) {
    return this.afs.collection('rsvp', ref =>
      ref.where('memberAuthId', '==', user)
        .where('compareDate', '>=', currentDate)
        .orderBy('compareDate', 'asc')
        .orderBy('createdTS', 'desc'));
  }

  async getRsvpListForUserWithCurrentDateMod(user: string, currentDate: string) {

    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('memberAuthId', '==', user),
      where('compareDate', '>=', currentDate),
      orderBy('compareDate', 'asc'),
    )

    return (await getDocs(q)).docs;
  }

  async getPlayingRsvpListForUserWithCurrentDate(user: string, currentDate: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('memberAuthId', '==', user),
      where('compareDate', '>=', currentDate),
      where('isPlaying', '==', true),
      orderBy('compareDate', 'asc'),
    );

    return (await getDocs(q)).docs;
  }

  getRsvpListForUserWithCurrentDateIsHidden(user, currentDate) {
    return this.afs.collection('rsvp', ref =>
      ref.where('memberAuthId', '==', user)
        .where('isHidden', '==', false)
        .where('compareDate', '>=', currentDate)
        .orderBy('compareDate', 'asc'));
  }

  getRsvpListForUserWithCurrentDateNotHidden(user, currentDate) {
    return this.afs.collection('rsvp', ref =>
      ref.where('memberAuthId', '==', user)
        .where('compareDate', '>=', currentDate)
        .where('isHidden', '==', false)
        .orderBy('compareDate', 'asc'))
      .valueChanges()
  }

  getRsvpListForUser(user, limit: number) {
    return this.afs.collection('rsvp', ref =>
      ref.where('memberAuthId', '==', user)
        .orderBy('compareDate', 'desc')
        .limit(limit));

  }

  getPrevRsvpListForUser(user: string, currentDate: string, limit: number) {
    return this.afs.collection('rsvp', ref =>
      ref.where('memberAuthId', '==', user)
        .where('compareDate', '<', currentDate)
        .orderBy('compareDate', 'desc')
        .limit(limit));


  }
  async getLowIndexRsvpMod(eventId: string, handicapIndex: number) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId),
      where('isPlaying', '==', true),
      where('handicapIndex', '<', handicapIndex),
      orderBy('handicapIndex', 'desc'),
      limit(1)
    )

    return (await getDocs(q)).docs;

  }

  getLowIndexRsvp(eventId: string, handicapIndex: number) {
    return this.afs.collection('rsvp', ref =>
      ref.where('eventId', '==', eventId)
        .where('isPlaying', '==', true)
        .where('handicapIndex', '<', handicapIndex)
        .orderBy('handicapIndex', 'desc')
        .limit(1));
  }

  getRsvpsListForEvent(eventId) {
    return this.afs.collection(this.node, ref => ref.where('eventId', '==', eventId)
      .orderBy('firstname', 'asc')
      .orderBy('lastname', 'asc'));
  }

  async getRsvpsListForEventMod(eventId: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId),
      orderBy('firstname', 'asc'),
      orderBy('lastname', 'asc')
    );

    return (await getDocs(q)).docs;
  }

  async getRsvpThatArePlayingMod(eventId: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId),
      where('isPlaying', '==', true),
      orderBy('firstname', 'asc'),
      orderBy('lastname', 'asc')
    );

    return (await getDocs(q)).docs;
  }

  getRsvpThatArePlaying(eventId) {
    return this.afs.collection(this.node, ref =>
      ref.where('eventId', '==', eventId)
        .where('isPlaying', '==', true)
        .orderBy('firstname', 'asc')
        .orderBy('lastname', 'asc'))
      ;
  }

  getRsvpThatArePlayingByIndex(eventId) {
    return this.afs.collection(this.node, ref =>
      ref.where('eventId', '==', eventId)
        .where('isPlaying', '==', true)
        .orderBy('handicapIndex', 'asc'));
  }

  async getRsvpThatArePlayingByIndexMod(eventId: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId),
      where('isPlaying', '==', true),
      orderBy('handicapIndex', 'asc'),
    )

    return (await getDocs(q)).docs;
  }

  getRsvpThatArePlayingAndNotGuest(eventId) {

    return this.afs.collection(this.node, ref =>
      ref.where('eventId', '==', eventId)
        .where('isPlaying', '==', true)
        .where('isGuest', '==', false)
        .orderBy('firstname', 'asc')
        .orderBy('lastname', 'asc'))
      ;
  }

  async getRsvpThatArePlayingAndNotGuestMod(eventId: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId),
      where('isPlaying', '==', true),
      where('isGuest', '==', false),
      orderBy('firstname', 'asc'),
      orderBy('lastname', 'asc'),
    )

    return (await getDocs(q)).docs;
  }

  getRsvpForEventAndOwner(eventId, ownerId) {
    return this.afs.collection(this.node, ref =>
      ref.where('eventId', '==', eventId)
        .where('memberAuthId', '==', ownerId));
  }

  async getRsvpForEventAndOwnerMod(eventId: string, ownerId: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId),
      where('memberAuthId', '==', ownerId),
    )

    return (await getDocs(q)).docs;

  }

  getForEventIdAndOrigOwner(eventId: string, origRsvpId: string) {
    return this.afs.collection(this.node, ref =>
      ref.where('eventId', '==', eventId)
        .where('origRsvpId', '==', origRsvpId));
  }

  async getForEventIdAndOrigOwnerMod(eventId: string, origRsvpId: string) {
    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('eventId', '==', eventId),
      where('origRsvpId', '==', origRsvpId)
    )

    return (await getDocs(q)).docs;
  }

  togglePlayingStatus(id, newStatus, eventChangeCount) {

    if (newStatus) {
      return this.afs.collection(this.node).doc(id)
        .update({
          isPlaying: newStatus,
          eventChangeCount: eventChangeCount,
          isHidden: false,
        });
    } else {
      return this.afs.collection(this.node).doc(id)
        .update({
          isPlaying: newStatus,
          eventChangeCount: eventChangeCount,
        });
    }
  }

  toggleHidden(id, newValue: boolean) {
    return this.afs.collection(this.node).doc(id)
      .update({
        isHidden: newValue,
      });
  }

  updateWaitlistIsPlayingEventChangeCount(id, waitlistStatus, isPlayingStatus, eventChangeCount) {
    return this.afs.collection(this.node).doc(id)
      .update({
        waitlist: waitlistStatus,
        isPlaying: isPlayingStatus,
        eventChangeCount: eventChangeCount
      });
  }

  updateWaitlistIsPlaying(id: string, waitlistStatus: boolean, isPlayingStatus: boolean,) {
    return this.afs.collection(this.node).doc(id)
      .update({
        waitlist: waitlistStatus,
        isPlaying: isPlayingStatus
      });
  }

  updateWaitlistAndEventChangeCount(id, waitlistStatus, eventChangeCount) {
    return this.afs.collection(this.node).doc(id)
      .update({
        waitlist: waitlistStatus,
        eventChangeCount: eventChangeCount
      });
  }

  updateWaitlistGuestCountAndEventChangeCount(id, waitlistStatus, guestCount, eventChangeCount) {
    return this.afs.collection(this.node).doc(id)
      .update({
        waitlist: waitlistStatus,
        guestCount: guestCount,
        eventChangeCount: eventChangeCount
      });
  }

  updateKeyPad(rsvpId: string, member: any) {
    return this.afs.collection(this.node).doc(rsvpId)
      .update({
        'member': member,
      });
  }

  delete(id: string) {
    return this.afs.collection(this.node).doc(id).delete();
  }

  subscribeLog(data) {
    return super.create('subscribe-log', data);
  }

  logNetworkSpeed(data) {
    return super.create('network-speed-warning', data);
  }
}
