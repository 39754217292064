import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, doc, getDoc, getDocs, getFirestore, query, where, limit } from 'firebase/firestore';

@Injectable()
export class MemberService extends BaseService {

  node = 'member';

  createMember(data: any) {
    return super.create(this.node, data);
  }

  updateMember(id: string, data: any) {
    return super.update(this.node, id, data);
  }

  updateHandicapIndex(id: string, handicapIndex: number, displayIndex: string) {
    return this.afs.collection('member').doc(id).update({
      handicapIndex,
      displayIndex
    });
  }

  updatePromoDate(id: string, promoStartedDate: string) {
    return this.afs.collection(this.node).doc(id).update({
      'promoStartedDate': promoStartedDate,
    });
  }

  getMemberList() {
    return this.afs.collection('member');
  }

  async getMemberByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();

  }

  getMemberByDocId(id) {
    return this.afs.collection('member').doc(id);
  }

  async getByDocIdMod(id: string) {
    const db = getFirestore();
    const docRef = doc(db, this.node, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  getForEmail(email) {

    return this.afs.collection('member', ref => ref.where('email', '==', email));
  }

  updateEmail(memberId: string, email: string) {
    return this.afs.collection('member').doc(memberId).update({ 'email': email });
  }

  deleteUser(memberId: string) {
    return this.afs.collection('member').doc(memberId).delete();
  }

  async getForEmailMod(email) {

    const db = getFirestore();
    const q = query(
      collection(db, this.node),
      where('email', '==', email)
    )
    return (await getDocs(q)).docs;
  }

  addCourse(id, courseId) {
    return this.afs.collection('member').doc(id).update({ 'courses': firebase.firestore.FieldValue.arrayUnion(courseId) });
  }

  updateKeyPad(memberId: string, useNewKeyPad: boolean) {
    return this.afs.collection('member').doc(memberId).update({ 'useNewKeyPad': useNewKeyPad });
  }
}
