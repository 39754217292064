import { Component } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { RsvpService } from '../services/rsvp.service';

@Component({
  selector: 'app-mass-update-modal',
  templateUrl: './mass-update-modal.page.html',
  styleUrls: ['./mass-update-modal.page.scss'],
})
export class MassUpdateModalPage {

  eventId = '';
  displayRows: any[] = [];

  constructor(
    private rsvpService: RsvpService,
    private navParams: NavParams,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,

  ) { }

  ionViewWillEnter() {
    this.getRsvps();
  }

  async getRsvps() {
    const loader = await this.loadingCtrl.create({
      message: 'Loading Players',
      translucent: true,
      spinner: 'bubbles',
      duration: 7000
    });
    await loader.present();

    const rsvps = [];
    const eventId = this.navParams.get('eventId');
    const docs = await this.rsvpService.getRsvpsListForEventMod(eventId);
    for (const doc of docs) {
      const data = doc.data();
      const { firstname, lastname, id: rsvpId, isPlaying } = data;
      rsvps.push({
        name: `${firstname} ${lastname}`,
        rsvpId,
        isPlaying,
      })
    }
   
    this.formatRows(rsvps)
    loader.dismiss();
  }

  formatRows(rsvps) {

    this.displayRows = rsvps.reduce((acc: any, col: any, i: number) => {
      if (i % 2 == 0) {
        acc.push({ column1: col });
      } else {
        acc[acc.length - 1].column2 = col;
      }
      return acc;
    }, []);

  }

  toggleRsvpStatus(rsvp: any) {

    const { rsvpId, isPlaying } = rsvp;
    console.log({isPlaying});
    const count = !isPlaying ? 1 : -1;

    this.rsvpService.togglePlayingStatus(rsvpId, !isPlaying, count);

  }

  cancel() {
    this.modalCtrl.dismiss();
  }

}
