import { AuthService } from './../services/auth.service';
import { PairingService } from './../services/pairing-service';
import { NavParams, ModalController } from '@ionic/angular';
import { Component } from '@angular/core';
import { Pairing } from '../common/model-classes/pairing';


@Component({
  selector: 'app-bet-pairing-modal',
  templateUrl: './bet-pairing-modal.page.html',
  styleUrls: ['./bet-pairing-modal.page.scss'],
})
export class BetPairingModalPage {

  currentUser;
  displayPairings = [];
  pairings: any[];
  currentPairing: any;
  yourPairingDesc: string;

  constructor(
    private authService: AuthService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private pairingService: PairingService,

  ) { }

  async ionViewWillEnter() {
    this.currentUser = await this.authService.currentUser();
    const eventId = this.navParams.get('eventId');
    this.getPairings(eventId);

  }

  async getPairings(eventId: string) {
    const pairingArray = [];
    const docs = await this.pairingService.getForEventMod(eventId);
    for (const doc of docs) {
      const pairing = doc.data();
      const { playerArray } = pairing;
      if (playerArray.includes(this.currentUser)) {
        this.currentPairing = pairing;
        this.yourPairingDesc = this.getPairingDesc(pairing);
      } else {
        pairingArray.push(pairing);
      }
    }
    this.pairings = pairingArray;
  }

  getPairingDesc(pairing: any) {

    let pairingDesc = ''
    if (pairing) {
        const { players } = pairing
        pairingDesc = this.pairingDescription(players);
    }

    return pairingDesc;
  }

  pairingSelected(selectedPairing) {
    this.modalCtrl.dismiss({
      selectedPairing,
      currentPairing: this.currentPairing,
    });
  }

  async includesCurrentUser(playerArray: any[]) {

    return playerArray.includes(this.currentUser);

  }

  pairingDescription(players: any) {

    const reduced = players.reduce((a, currentValue) =>
      [...a, currentValue.name], '');
    const joined = reduced.join();

    return joined;
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
