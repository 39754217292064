import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, orderBy, getDocs, getFirestore, query, where, limit } from 'firebase/firestore';

@Injectable()
export class BillingProductService extends BaseService {

  node = 'billing-product';

  async getAll() {
    const db = getFirestore(); 
    const q = query(
      collection(db, this.node),
      orderBy('priority', 'asc')
    )

    return (await getDocs(q)).docs;
  }
}