import { Component  } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { MemberService } from '../services/member.service';

@Component({
  selector: 'app-guest-modal',
  templateUrl: './guest-modal.page.html',
  styleUrls: ['./guest-modal.page.scss'],
})
export class GuestModalPage {

  guestForm = this.fb.group({
    memberId: new FormControl(),
    guestFirstname: new FormControl('', Validators.required),
    guestLastname: new FormControl('', Validators.required),
    guestDisplayHandicapIndex: new FormControl('0.0', [Validators.required, Validators.pattern('[+]?[0-9]{0,2}[.]{1}[0-9]{1}')]),
    isGuest: new FormControl(true),
  });
  updateMode = false;
  
  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private memberService: MemberService,
  ) { }

  ionViewWillEnter() {
    const guest = this.navParams.get('guest');
    if (guest) {
      this.updateMode = true;
      this.guestForm.patchValue(guest);
    } else {
      const memberId = this.memberService.getId();
      this.guestForm.patchValue({
        memberId
      });
    }
  }

  processGuest() {
    
    this.capitalizeNames() 
    
    this.modalCtrl.dismiss({
      guest: this.guestForm,
    })
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  capitalizeNames() {
    const guestFirstname = this.capitalize(this.firstname);
    const guestLastname = this.capitalize(this.lastname);
    this.guestForm.patchValue({
      guestFirstname,
      guestLastname,
    })
  }

  capitalize(str: string) {
    const capStr = str.charAt(0).toUpperCase() + str.slice(1);
    return capStr;
  }

  get firstname() {
    return this.guestForm.get('guestFirstname').value;
  }

  get lastname() {
    return this.guestForm.get('guestLastname').value;
  }
}
