import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    async getBillingDate() {
        return (await Storage.get({key: 'billingDate'})).value
    }

    async setBillingDate(billingDate: string) {
        return await Storage.set(
            {
                key: 'bullingDate',
                value: billingDate,
            }
        )
    }
}
