import { ToastController } from "@ionic/angular";

export async function generateToast(toastCtrl: ToastController, msg: string, duration?: number) {

    const msgDuration = duration ? duration : null;
    
    const toast = await toastCtrl.create({
        message: msg,
        duration: msgDuration,
        cssClass: 'custom-toast',
        position: 'middle',
        color: 'primary',
        buttons: [
            {
              text: 'Dismiss',
              role: 'cancel',
            }
          ],
      });
      toast.present();
}
