import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable()
export class EventSummaryTransactionService extends BaseService {

  node = 'event-summary-transaction';

  getEventSummaryTotalForEventIdAndMemberId(eventId: string, memberId) {
    return this.afs.collection(this.node, ref =>
      ref.where('eventId', '==', eventId)
      .where('memberId', '==', memberId)
      .orderBy('amount', 'desc'));
  }
}
