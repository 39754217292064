import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-group-help-modal',
  templateUrl: './group-help-modal.page.html',
  styleUrls: ['./group-help-modal.page.scss'],
})
export class GroupHelpModalPage implements OnInit {

  helpItems = [
    { 
      index: 0,
      description: 'Group Name',
      displayInfo: false,
      helpText: '',
      
    },
    { 
      index: 1,
      description: 'Course Info',
      displayInfo: false,
      helpText: '',
    },

  ]

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  helpItemPressed(index: number) {
    this.helpItems[index].displayInfo = !this.helpItems[index].displayInfo;
    this.helpInfo(index);
  }

  helpInfo(index: number) {

    if (index === 0) {
      this.helpItems[index].helpText = 'Enter a name for your your group';
    }

    if (index === 1) {
      this.helpItems[index].helpText = 
      'Select your primary course which represents the course where most of your guys and gals play';
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
