import { Pairing } from './../common/model-classes/pairing';
import { NavParams, ModalController } from '@ionic/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-flip-wolf-modal',
  templateUrl: './flip-wolf-modal.page.html',
  styleUrls: ['./flip-wolf-modal.page.scss'],
})
export class FlipWolfModalPage {
  pairing: Pairing;

  holes = [];
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) { }

  ionViewWillEnter() {
    this.pairing = this.navParams.get('pairing');    

    for (let i = 0; i < 18; i++) {
      this.holes.push(+(i+1));
    }
  }

  holeDesc(i) {
    return `Hole ${this.holes[i]}`;
  }

  playerIsHeads(i: number, j: number) {
    return !this.pairing.partners[i].includes(j);
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  totalHeads() {

    let tot = 0;
    for (const partner of this.pairing.partners) {
      tot += partner.length;
    }
    return tot;
  }

  totalTails() {
    let tot = 0;
    for (const partner of this.pairing.partners) {
      tot += partner.length;
    }

    return (18 * this.pairing.players.length) - tot;
  }
}
