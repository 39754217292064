import { HttpService } from './../services/http.service';
import { CourseService } from './../services/course.services';
import { ModalController, LoadingController } from '@ionic/angular';
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-find-course-modal',
  templateUrl: './find-course-modal.page.html',
  styleUrls: ['./find-course-modal.page.scss'],
})
export class FindCourseModalPage {

  courses: any[];
  loader: any

  constructor(
    private courseService: CourseService,
    private http: HttpClient,
    private https: HttpService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
  ) { }

  searchChanged(event) {
    const value = event.detail.value;
    if (value.length > 2) {
      this.getCourseList(value);
    }
  }

  async getCourseList(value: string) {
    this.loader = await this.loadingCtrl.create({
      message: 'Loading Courses',
      translucent: true,
      spinner: 'bubbles',
    });
    await this.loader.present();

    this.https.searchByCourseString(value)
      .subscribe((res: any) => {

        const courses = [];
        res.forEach(course => {
          const { CourseID: courseId, CourseName: courseName, City: city, State: state, FacilityName: facilityName } = course;
          const courseObj = {
            courseName,
            courseId,
            city,
            state,
            facilityName,
          }
          courses.push(courseObj);
        })

        this.courses = courses;
        this.loader.dismiss();
      })
  }

  async courseSelected(course) {
    const loader = await this.loadingCtrl.create({
      message: 'Saving Course',
      translucent: true,
      spinner: 'bubbles',
    });
    await loader.present();

    const { courseId: ghinCourseId } = course;

    const docs = await this.courseService.getForGhinIdMod(ghinCourseId);
    
    if (docs.length === 0) {
      const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/createCourseHttp?ghinCourseId=${ghinCourseId}`
      this.http.get(url).subscribe((res: any) => {
        const { courseId: newCourseId, courseName, courseCity, courseState } = res;
        const returnParameters = {
          newCourseId,
          courseName,
          courseCity,
          courseState,
        }
        loader.dismiss();
        this.modalCtrl.dismiss({ returnParameters: returnParameters })
      })
    } else {
      let returnParameters;
      for (const doc of docs) {
        const courseData = doc.data();
        const { id: newCourseId, courseName, courseCity, courseState, facilityName } = courseData;
        returnParameters = {
          newCourseId,
          courseName,
          courseCity,
          courseState,
          facilityName,
        }
      }
      loader.dismiss();
      this.modalCtrl.dismiss({ returnParameters: returnParameters })
    }
  }

  displayState(stateDesc: string) {

    let displaySt = '';
    if (stateDesc !== undefined && stateDesc !== null) {
      displaySt = stateDesc;
      if (stateDesc.startsWith('US-')) {
        displaySt = stateDesc.substr(3);
      }
    }

    return displaySt;
  }

  showFacility(course: any) {
    let showFac = false;
    if (course !== undefined && course !== null) {
      const { courseName, facilityName } = course;
      if (courseName !== facilityName) {
        showFac = true;
      }
    }

    return showFac;
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
