import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HandicapHistoryService } from './../services/handicap-history.service';
import { EventFlightService } from './../services/event-flight.service';
import { EventService } from './../services/event.service';
import { CourseService } from './../services/course.services';
import { MemberService } from './../services/member.service';
import { AuthService } from './../services/auth.service';
import { PairingService } from './../services/pairing-service';
import { FormBuilder, FormControl, FormArray, Validators, FormGroup } from '@angular/forms';
import { Component } from '@angular/core';
import { ModalController, NavParams, LoadingController, AlertController } from '@ionic/angular';
import { RsvpService } from '../services/rsvp.service';
import { calcCourseHandicap, formatDisplayCourseHandicap } from '../common/helpers/reuse-functions';
import { GlobalService } from '../services/global.service';
import { PointsTeamSizeValidator } from '../common/helpers/validators';
import { pairingValidations } from '../common/validations';
import { Subscription } from 'rxjs';
import { PairingDeleteEventService } from '../services/pairing-delete-event.service';
import { PairingChangeEventService } from '../services/pairing-change-event.service';
 
@Component({
  selector: 'app-pairing-modal',
  templateUrl: './team-pairing-modal.page.html',
  styleUrls: ['./team-pairing-modal.page.scss'],
})
export class TeamPairingModalPage {
  validations = pairingValidations;
  eventId: string;
  event: any;
  eventLowGuy: any;
  pairing: any;
  pairingId: string;
  dbMembers: any[] = [];
  currentUser: any;
  course: any;
  first = true;
  isLowGuyAdjustment = false;
  saveRating: number;
  isLoading = false;
  eventFlights: any[] = [];
  formLoaded = false;
  mensTees: any[];
  ladiesTees: any[];
  lowSkinsHandicap = 0;
  formLowGuy: any;
  lowSkinsGuy: any;
  newLowSkinsGuy: any;
  pointsDescriptions = [];
  hasTeams = false;
  pairingPlayers = [];
  isSubmitted = false;
  isDisabled = false;
  isUpdateMode = false;
  pointsHandicapMethods = [];
  playersPlayingFromPreferredTees = [];
  subscription: Subscription;
  changedPairing: any;
  indexChangeLoader: any;
  deleteLoader: any;
  changeLoader: any;
  areTheSamePlayers = true;

  selectForm = this.fb.group({
    team1selMembers: new FormControl([], [Validators.required, this.validateSelections]),
    team2selMembers: new FormControl([], [Validators.required, this.validateSelections]),
    team1members: this.fb.array([]),
    team2members: this.fb.array([]),
    members: this.fb.array([]),
    usePoints: new FormControl(false),
    pointsDescription: new FormControl(),
    pointsAmount: new FormControl(),
    pointsHandicapMethod: new FormControl(),
    pointsHandicapPercentage: new FormControl(100, Validators.required),
    manualHandicaps: this.fb.array([]),
    team1: new FormControl(),
    team2: new FormControl(),
  });

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private pairingService: PairingService,
    private rsvpService: RsvpService,
    private fb: FormBuilder,
    private navParams: NavParams,
    private authService: AuthService,
    private courseService: CourseService,
    private eventService: EventService,
    private eventFlightService: EventFlightService,
    private handicapHistoryService: HandicapHistoryService,
    private globalService: GlobalService,
    private memberService: MemberService,
    private pairingDeleteEventService: PairingDeleteEventService,
    private pairingChangeEventService: PairingChangeEventService,
    private loadingCtrl: LoadingController,
    private db: AngularFirestore,
    private pointsTeamSizeValidator: PointsTeamSizeValidator,
  ) { }

  // two man swing
  async ionViewWillEnter() {
    this.subscription = new Subscription;
    this.currentUser = await this.authService.currentUser();
    this.getNavParams();
    this.subscribeToPairingEvents();
    this.pointsDescriptions = this.globalService.getPointsDescription();
    this.pointsHandicapMethods = this.globalService.getPointsHandicapMethods();
    const a = this.retrieveEventInfo();
    const b = this.getPlayingMembers();
    const c = this.getFlights();

    await Promise.all([a, b, c]);
    await this.getCourse();
    await this.getLowCourseHandicap();

    if (this.pairingId != null) {
      this.populateCurrentPlayers();
    } else {
      this.populateCurrentMember();
    }
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  getNavParams() {
    this.pairingId = this.navParams.get('pairingId');
    this.isLoading = this.pairingId ? true : false;
    this.isUpdateMode = this.pairingId ? true : false;
    this.eventId = this.navParams.get('eventId');
  }

  async getLowCourseHandicap() {
    const processedMembers = [];
    this.lowSkinsGuy = {
      memberId: '',
      handicapIndex: -99,
      name: '',
      courseHandicap: -99,
      difference: 0,
    }

    const { course, tee } = this.event;
    const courseData = await this.courseService.getCourseByDocIdMod(course);
    const { tees, handicapSets } = courseData;
    const theTee = tees.find(t => t.id === tee);
    if (theTee) {
      var { handicapSet, slope, rating } = theTee;
      const theHandicapSet = handicapSets.find(h => h.id === handicapSet);
      if (theHandicapSet) {
        var { par } = theHandicapSet;
      }
    }

    let mLength = 0;
    mLength = this.members.length;
    for (let i = 0; i < mLength; i++) {
      const member = (this.selectForm.get('members') as FormArray).at(i).value;
      const { courseHandicap, id: memberId, handicapIndex, name } = member;
      if (courseHandicap > this.lowSkinsGuy.courseHandicap) {
        const lowSkinsGuy = {
          memberId,
          handicapIndex,
          name,
          courseHandicap,
          difference: 0,
        }
        this.lowSkinsGuy = lowSkinsGuy;
      }
      processedMembers.push(memberId);
    }

    const pairingDocs = await this.pairingService.getForEventMod(this.eventId);
    for (const pairingDoc of pairingDocs) {
      const pairingData: any = pairingDoc.data();
      const { players } = pairingData;
      for (const player of players) {
        const { id: memberId, handicapIndex, courseHandicap, name } = player;
        if ((courseHandicap > this.lowSkinsGuy.courseHandicap) && !(processedMembers.includes(memberId))) {
          const lowSkinsGuy = {
            memberId,
            handicapIndex,
            name,
            courseHandicap,
          }
          this.lowSkinsGuy = lowSkinsGuy;
        }
        processedMembers.push(memberId)
      }
    }

    const rsvpSnapshots = await this.rsvpService.getLowIndexRsvp(this.eventId, 99).get().toPromise();
    const rsvpDocs = rsvpSnapshots.docs;
    for (const rsvpDoc of rsvpDocs) {
      const rsvpData: any = rsvpDoc.data();
      const { handicapIndex, member } = rsvpData;
      const { id: memberId, firstname, lastname } = member;
      const courseHandicap = Math.round(Number(handicapIndex) * (slope / 113) - (Number(rating) - par));
      if ((courseHandicap > this.lowSkinsGuy.courseHandicap) && !(processedMembers.includes(memberId))) {
        const lowSkinsGuy = {
          memberId,
          handicapIndex,
          name: `${firstname} ${lastname}`,
          courseHandicap,
          difference: 0,
        }
        this.lowSkinsGuy = lowSkinsGuy;
      }
    }
  }

  subscribeToPairingEvents() {
    if (this.isUpdateMode) {
      console.log('pairing id', this.pairingId)
      const subscription = this.pairingDeleteEventService.getForPairing(this.pairingId)
        .valueChanges()
        .subscribe((pairingUpdateEvents: any) => {
          if (pairingUpdateEvents.length > 0) {
            if (this.deleteLoader) {
              this.deleteLoader.dismiss();
              this.modalCtrl.dismiss({
                pairing: null,
                warning: 2,
              });
            }
          }
        });
      this.subscription.add(subscription);
      const subscription2 = this.pairingChangeEventService.getForPairing(this.pairingId)
      .valueChanges()
      .subscribe((pairingChangeEvents: any) => {
        if (pairingChangeEvents.length > 0) {
          if (this.changeLoader) {
            this.changeLoader.dismiss();
            this.modalCtrl.dismiss({
              pairing: this.changedPairing,
              warning: 0,
            });
          }
        }
      });
    this.subscription.add(subscription2);
    }
  }

  async resetPairing() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Pairing Reset',
      message: `You are attempting to reset your pairing. Reseting your pairing will remove the current pairing along with all scores that have already been entered. Select yes to continue with the reset. Then add your pairing and any scores as needed.`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            this.presentDoubleCheckAlert();
          }
        }, {
          text: 'No',
          handler: async () => {
            this.modalCtrl.dismiss();
          }
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
  }

  async presentDoubleCheckAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Pairing will be deleted',
      message: `You have chosen to delete your current pairing. This is a very rare choice and this is a second confirmation. If deleting your pairing is your goal, select Yes to continue or Cancel to cancel`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            this.deletePairing();
          }
        }, {
          text: 'Cancel',
          handler: async () => {
            this.modalCtrl.dismiss()
          }
        }
      ]
    });

    await alert.present();
    alert.onDidDismiss()
  }

  async deletePairing() {
    this.deleteLoader = await this.loadingCtrl.create({
      message: 'Deleting your pairing',
      translucent: true,
      spinner: 'bubbles',
      duration: 20000,
    });

    await this.deleteLoader.present();

    this.pairingService.delete(this.pairingId);
  }


  populateCurrentMember() {
    const a = [];
    a.push(this.currentUser);
    this.selectForm.patchValue({ team1selMembers: a });
  }

  async getPlayingMembers() {
    return new Promise<any>(async (resolve, reject) => {
      const loader = await this.loadingCtrl.create({
        message: 'Loading',
        translucent: true,
        spinner: 'bubbles',
        duration: 6000,
      });
      await loader.present();

      const pairedPlayers = await this.playersAlreadyInAPairing();

      const rsvpDocs = await this.rsvpService.getRsvpThatArePlayingMod(this.eventId);
      for (const rsvpDoc of rsvpDocs) {
        const rsvpData: any = rsvpDoc.data();
        const { firstname, lastname, id, } = rsvpData.member;
        if (!pairedPlayers.includes(id) || this.isUpdateMode) {
          const memberData = await this.memberService.getMemberByDocIdMod(id);
          const { handicapIndex } = memberData;
          const tempMember = {
            id,
            firstname,
            lastname,
            handicapIndex,
            scores: this.populateScores(),
          } as any;
          this.dbMembers.push(tempMember);
        }
      }
      loader.dismiss();
      resolve('');
    })
  }

  playersAlreadyInAPairing(): any {
    return new Promise<any>(async (resolve, reject) => {
      const playerArray = [];
      if (!this.isUpdateMode) {
        const pairingDocs = await this.pairingService.getForEventMod(this.event.id);
        for (const pairingDoc of pairingDocs) {
          const pairingData: any = pairingDoc.data();
          for (const player of pairingData.players) {
            playerArray.push(player.id);
          }
        }
      }

      resolve(playerArray);
    })
  }

  populateScores() {
    const scores = [];
    for (let i = 0; i < 18; i++) {
      const newHole = {
        gross: 0,
        net: 0,
        relationToParGross: 0,
        relationToParNet: 0,
        skinsNet: 0,
        pointsNet: 0,
        displayGross: '0',
        displayNet: '0',
        displayPoints: '0',
        isBoB: false,
        isAdjustment: false,
      } as any;

      scores.push(newHole);
    }

    return scores;
  }

  async teamSelectionChanged() {
    if (!this.isLoading) {
      this.clearMembers()
      this.playersPlayingFromPreferredTees.length = 0;
      const mLengthT1 = this.team1members.length;
      for (let i = 0; i < mLengthT1; i++) {
        this.team1members.removeAt(0);
      }

      const mLengthT2 = this.team2members.length;
      for (let i = 0; i < mLengthT2; i++) {
        this.team2members.removeAt(0)
      }

      const promises = [];
      const form1Members = this.team1selMembers.value;
      for (const memberId of form1Members) {
        const p = this.addTeamMember(memberId, 1); 
        promises.push(p);
      }

      const form2members = this.team2selMembers.value;
      if (form2members !== null) {
        for (const memberId of form2members) {
          const p = this.addTeamMember(memberId, 2);
          promises.push(p)
        }
      }
      
      await Promise.all(promises)
      if (this.playersPlayingFromPreferredTees.length > 0 && this.team1members.length > 0 && this.team2members.length > 0) {
        this.presentPreferredTeeAlert()
      }
    }
  
  }

  async presentPreferredTeeAlert() {
    const names = [...this.playersPlayingFromPreferredTees];
    const nameStr = this.getNamesStr(names);
    const isAre = this.playersPlayingFromPreferredTees.length > 1 ? 'are' : 'is';
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Preferred Tees',
      message: `Today, ${nameStr} ${isAre} playing from their Preferred Tees (tees they have selected to play that differ from the group's tee). Likely no action is needed, just a heads up reminder for the group.`,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'Ok',
        },
      ]
    });

    await alert.present();
    alert.onDidDismiss()
      .then();

  }

  getNamesStr(names) {
    let nameStr = '';
    for (let i = 0; i < names.length; i++) {
      const curStr = names[i];
      const str = i === 0 ? curStr : i !== names.length - 1  ? `, ${curStr}` : names.length > 2 ? `, and ${curStr}` : `and ${curStr}`;
      nameStr = nameStr.concat(str);
    }

    return nameStr;
  }

  usePointsChanged() {
    const validators = this.usePoints ? [Validators.required] : null;
    this.pointsAmountFC.setValidators(validators);
    this.pointsAmountFC.updateValueAndValidity();
    this.pointsHandicapMethodFC.setValidators(Validators.required);
    this.pointsHandicapMethodFC.updateValueAndValidity();
    this.pointsDescriptionFC.setValidators(validators)
    this.pointsDescriptionFC.updateValueAndValidity()

    if (!this.usePoints) {
      this.team1FC.setValidators(null);
      this.team2FC.setValidators(null);
      this.team1FC.updateValueAndValidity();
      this.team2FC.updateValueAndValidity();
      this.pointsHandicapMethodFC.setValidators(null);
      this.pointsHandicapMethodFC.updateValueAndValidity();
      this.pointsHandicapPercentageFC.setValidators(null);
      this.pointsHandicapPercentageFC.updateValueAndValidity();
      this.selectForm.patchValue({
        pointsDescription: null,
        pointsHandicapMethod: null,
        pointsHandicapPercentage: null,
        team1: [],
        team2: [],
      })
      this.hasTeams = false;
    }
  }

  pointsDescriptionChanged() {
    const thePointsDescription = this.pointsDescriptions.find(p => p.value === this.pointsDescription);
    if (thePointsDescription) {
      const { teamSize, hasTeams } = thePointsDescription;
      const arr = [];
      for (const t1player of this.selectForm.get('team1selMembers').value) {
        const theMember = this.dbMembers.find(m => m.id === t1player);
        if (theMember) {
          const { firstname, lastname } = theMember;
          const name = `${firstname} ${lastname}`;
          const player = {
            id: t1player,
            name,
          }
          arr.push(player)
        }
      }
      for (const t2player of this.selectForm.get('team2selMembers').value) {
        const theMember = this.dbMembers.find(m => m.id === t2player);
        if (theMember) {
          const { firstname, lastname } = theMember;
          const name = `${firstname} ${lastname}`;
          const player = {
            id: t2player,
            name,
          }
          arr.push(player)
        }
      }
      this.pairingPlayers = hasTeams ? arr : [];
      this.hasTeams = hasTeams;
      const validator = teamSize === 0 ? null : [Validators.required, this.pointsTeamSizeValidator.teamSizeValidator(teamSize)];
      this.team1FC.setValidators(validator);
      this.team2FC.setValidators(validator);
    }
  }

  clearMembers() {
    const mLength = this.members.length;
    for (let i = 0; i < mLength; i++) {
      (this.members.removeAt(0));
    }
  }

  getFlights() {
    return new Promise<void>(async (resolve, reject) => {
      const flightDocs = await this.eventFlightService.getForEventMod(this.eventId);
      for (const doc of flightDocs) {
        const docData = doc.data();
        this.eventFlights.push(docData);

      }
      resolve();
    });
  }

  async retrieveEventInfo() {
    this.event = await this.eventService.getByDocIdMod(this.eventId);
    return this.event;
  }

  async addTeamMember(memberId: string, teamNumber: number) {
    const scoreArray = [];
    for (let i = 0; i < 18; i++) {
      if (!this.isUpdateMode) {
        scoreArray.push(this.initHole());
      } else {
        const thePlayer = this.pairing.players.find((p: any) => p.id === memberId);
        if (thePlayer) {
          scoreArray.push(thePlayer.scores[i]);
        } else {
          scoreArray.push(this.initHole());
        }
      }
    }

    const memberData = await this.memberService.getMemberByDocIdMod(memberId)
    const { firstname, lastname, displayHandicapIndex: memDisplayHandicapIndex, handicapIndex: memHandicapIndex, id, gender = 'Male', preferredTees } = memberData;
    const handicapInfo = await this.determineHandicapIndex(id, memHandicapIndex, memDisplayHandicapIndex)
    const { retHandicapIndex: handicapIndex, retDisplayHandicapIndex: displayHandicapIndex } = handicapInfo;
    let memberTee = this.determineTee(preferredTees, memberData);
    const theTee = this.course.tees.find(((t: any) => t.id === memberTee));
    const par = this.course.handicapSets.find(h => h.id = theTee.handicapSet).par;
    const { slope, rating, } = theTee;
    const courseHandicap = calcCourseHandicap(handicapIndex, Number(slope), par, rating);
    const displayCourseHandicap = this.formatDisplayCourseHandicap(courseHandicap);
    const skinsHandicap = this.calcSkinsHandicap(id, courseHandicap);
    const displaySkinsHandicap = skinsHandicap;
    const concatName = `${firstname} ${lastname}`;
    const flight = this.findFlight(id);
    const tempMember = this.fb.group({
      name: [concatName],
      handicapIndex: [handicapIndex],
      displayHandicapIndex: new FormControl(displayHandicapIndex, [
        Validators.pattern('[+]?[0-9]{0,2}[.]{1}[0-9]{1}'),
        Validators.required,
      ]),
      courseHandicap: [courseHandicap],
      displayCourseHandicap: [displayCourseHandicap],
      skinsHandicap: [skinsHandicap],
      displaySkinsHandicap: [displaySkinsHandicap],
      gender: [gender],
      tee: [memberTee],
      scores: [scoreArray],
      id: [memberId],
      flight: [flight],
      includeInSkins: new FormControl(true, Validators.required),
      teamNumber: [teamNumber],
      firstname: [firstname],
      lastname: [lastname],
    });

    if (teamNumber === 1) {
      this.team1members.push(tempMember);
      const sortedT1 = this.team1members.value.sort((a, b) => (a.name > b.name) ? 1 : -1);
      this.selectForm.patchValue({ team1members: sortedT1 });
    }

    if (teamNumber === 2) {
      this.team2members.push(tempMember);
      const sortedT2 = this.team2members.value.sort((a, b) => (a.name > b.name) ? 1 : -1);
      this.selectForm.patchValue({ team2members: sortedT2 });
    }

    this.members.push(tempMember);
    const sortedM = this.members.value.sort((a, b) => (a.teamNumber > b.teamNumber) ? 1 : -1);
    this.selectForm.patchValue({ members: sortedM });
  }

  determineTee(preferredTees: any, member: any) {
    let usedTee = this.event.tee;
    if (preferredTees && preferredTees.length > 0) {
      const thePreferredTee = preferredTees.find(t => t.group === this.event.groups[0]);
      if (thePreferredTee) {
        usedTee = thePreferredTee.preferredTee;
        const { firstname, lastname } = member;
        this.playersPlayingFromPreferredTees.push(`${firstname} ${lastname}`);
      }
    }

    return usedTee;
  }

  async determineHandicapIndex(memberId: string, index: number, displayIndex) {
    let retHandicapIndex = index;
    let retDisplayHandicapIndex = displayIndex;
    const { handicapAsOfDate, useHandicapAsOfDate } = this.event;

    if (useHandicapAsOfDate) {
      const asOfDate = new Date(handicapAsOfDate).toISOString();
      const handicapHistoryDocs = await this.handicapHistoryService.getForMemberIdAndDateMod(memberId, asOfDate);
      if (handicapHistoryDocs.length > 0) {
        const handicapHistory: any = handicapHistoryDocs[0].data();
        const { handicapIndex, displayHandicapIndex } = handicapHistory;
        retHandicapIndex = handicapIndex;
        retDisplayHandicapIndex = displayHandicapIndex;
      }
    }

    const retObj = {
      retHandicapIndex,
      retDisplayHandicapIndex,
    } as any;

    return retObj
  }

  calcSkinsHandicap(playerId: string, courseHandicap: number) {

    let skinsHandicap = 0;

    const { handicapMethod, skinsFormat, useMaxStrokes, maxHandicapStrokes } = this.event;

    if (skinsFormat > 1) {
      if (handicapMethod === 1) {
        skinsHandicap = courseHandicap * -1;
      }
      if (handicapMethod === 2) {
        const { courseHandicap: lowCourseHandicap, memberId } = this.lowSkinsGuy;
        skinsHandicap = lowCourseHandicap - courseHandicap;
        if (memberId === playerId) {
          skinsHandicap = 0;
        }
      }
    }

    if (useMaxStrokes && skinsHandicap > maxHandicapStrokes) {
      skinsHandicap = maxHandicapStrokes;
    }

    return skinsHandicap;
  }


  initHole() {
    const newHole = {
      gross: 0,
      net: 0,
      relationToParGross: 0,
      relationToParNet: 0,
      skinsNet: 0,
      pointsNet: 0,
      displayGross: '0',
      displayNet: '0',
      stablefordGross: '0',
      stablefordNet: '0',
      isBoB: false,
      isAdjustment:  false, 
    } as any;
   
    return newHole;
  }

  findFlight(memberId: string) {
    let retFlight = '999';
    this.eventFlights.forEach(flight => {
      if (flight.players.includes(memberId)) {
        retFlight = flight.id;
      }
    });

    return retFlight;
  }

  async savePairing() {
    this.isSubmitted = true;
    if (this.selectForm.valid) {
      this.isDisabled = true;
      const docs = await this.pairingService.getForEventAndMemberMod(this.eventId, this.currentUser);
      if (docs.length === 0 || this.isUpdateMode) {
        this.continueWithPairingSave();
      } else {
        this.modalCtrl.dismiss({
          pairing: null,
          warning: 1,
        })
      }
    }
  }
  
  async continueWithPairingSave() {
    const { skinsFormat, useStablefordQuotaScoring, flightOption, birdOrBetOption, eventFormatType,
      id: eventId, doubleEagleAmount, eagleAmount, birdieAmount, parAmount, bogeyAmount, otherAmount, } = this.event;
    const memberData = this.selectForm.value;
    console.log({memberData})
    const { usePoints, pointsAmount, pointsDescription, pointsHandicapMethod, pointsHandicapPercentage } = memberData;
    const playerArray = this.populatePlayerArray();
    const team1members = this.populateTeamArray(this.team1members.value);
    const team2members = this.populateTeamArray(this.team2members.value);
    const eventObj = this.populateEventObj();
    const partners = usePoints ? ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''] : [];
    this.pairingId = !this.isUpdateMode ? this.pairingService.getId() : this.pairingId;

    let newPairing = {
      id: this.pairingId,
      createdTS: this.pairingService.getTimeStamp(),
      playerArray,
      team1members,
      team2members,
      players: memberData.members,
      eventId,
      skinsFormat,
      eventFormatType,
      eventObj,
      flightOption,
      birdOrBetOption,
      startingHole: 1,
      holeIndex: 0,
      currentHoleIndex: 0,
      holesPlayed: 0,
      handicapSet: this.eventHandicapSet(),
      updateOpId: this.currentUser,
      createBetEvents: true,
      useStablefordQuotaScoring,
      doubleEagleAmount: doubleEagleAmount,
      eagleAmount,
      birdieAmount,
      parAmount,
      bogeyAmount,
      otherAmount,
      usePoints,
      pointsAmount,
      pointsDescription,
      pointsHandicapMethod, 
      pointsHandicapPercentage,
      partners,
      autoCalc: false,
      hasTeams: false,
      selectPartners: false,
      hasProx: false,
      hasTwists: false,
      hasTurns: false,
      teamSize: 0,
      proxs: [],
      twists: [],
      turns: [],
      team1: [],
      team2: [],
    } as any;

    if (usePoints) {
      newPairing = this.addPointsInfo(newPairing);
    }

    const batch = this.db.firestore.batch();

    const pairingXref = this.db.firestore.collection('pairing').doc(newPairing.id);
    batch.set(pairingXref, newPairing)

    if (this.formLowGuy || this.newLowSkinsGuy) {
      const pairingDocs = await this.pairingService.getForEventMod(this.event.id);
      for (const pairingDoc of pairingDocs) {
        const memId = this.formLowGuy ? this.formLowGuy.memberId : this.newLowSkinsGuy.memberId;
        const pairingData: any = pairingDoc.data();
        const { players, id: otherPairingId } = pairingData;
        if (otherPairingId !== this.pairingId) {
          for (let player of players) {
            const { playerId: pairingPlayerId, courseHandicap } = player;
            if (memId !== pairingPlayerId) {
              player.skinsHandicap = this.calcSkinsHandicap(pairingPlayerId, courseHandicap);
              player.displaySkinsHandicap = player.skinsHandicap;
            }
          }
          const pRef = this.db.firestore.collection('pairing').doc(otherPairingId);
          batch.update(pRef, {
            'players': players,
          })
        }
      }
    }
    this.setChangeLoader(newPairing);

    await batch.commit();

    if (!this.isUpdateMode || this.isUpdateMode && this.areSamePlayers(newPairing)) {
      this.modalCtrl.dismiss({
        pairing: newPairing,
        warning: 0,
      })
    }
  }

  async setChangeLoader(pairing: any) {
    if (this.isUpdateMode) {
      if (!this.areSamePlayers(pairing)) {
        this.changeLoader = await this.loadingCtrl.create({
          message: 'Updating your Pairing',
          translucent: true,
          spinner: 'bubbles',
          duration: 20000,
        });
    
        await this.changeLoader.present();
    
        this.changedPairing = pairing;
      }
    }
  }

  areSamePlayers(pairing: any) {
    const originalPlayers = this.pairing.playerArray.sort();
    const newPlayers = pairing.playerArray.sort();
    for (let i = 0; i < originalPlayers.length; i++) {
      if (originalPlayers[i] !== newPlayers[i]) {
        return false;
      }
    }
    
    return true;
  }

  populatePlayerArray() {
    const playerArray = [];
    this.team1members.value.forEach(player => {
      const { id } = player;
      playerArray.push(id)
    })
    this.team2members.value.forEach(player => {
      const { id } = player;
      playerArray.push(id)
    })

    return playerArray;
  }

  populateTeamArray(teamArr: []) {
    const tArr = [];

    teamArr.forEach(player => {
      const { courseHandicap, name, id, displayCourseHandicap, firstname, lastname, tee } = player;
      const tempMem = {
        courseHandicap,
        name,
        id,
        displayCourseHandicap,
        firstname,
        lastname,
        tee,
      } as any
      tArr.push(tempMem);
    });

    return tArr;
  }

  populateEventObj() {

    const { compareDate, displayCourses, displayGroups, eventDate, id: eventId, } = this.event;

    const eventObj = {
      compareDate,
      displayCourses,
      displayGroups,
      eventDate,
      eventId,
    } as any;

    return eventObj;
  }

  addPointsInfo(pairing) {
    const formData = this.selectForm.value;
    const { pointsDescription, members, team1, team2, pointsHandicapMethod, pointsHandicapPercentage } = formData;
    const modPairing = pairing;
    const pointHoles = [];
    const thePointsDescription = this.pointsDescriptions.find(p => p.value === pointsDescription);
    const { autoCalc, selectPartners, hasTeams, hasProx, hasTwists, hasTurns } = thePointsDescription;
    modPairing.autoCalc = thePointsDescription ? autoCalc : false;
    modPairing.selectPartners = thePointsDescription ? selectPartners : false;
    modPairing.hasTeams = thePointsDescription ? hasTeams : false;
    modPairing.hasProx = thePointsDescription ? hasProx : false;
    modPairing.proxs = thePointsDescription && hasProx ? this.initProxs() : [];
    modPairing.twists = hasTwists ? this.initBoolArr(true) : [];
    modPairing.turns = hasTurns ? this.initBoolArr(false) : [];
    modPairing.team1 = team1 ? team1 : [];
    modPairing.team2 = team2 ? team2 : [];
    for (let i = 0; i < 18; i++) {
      const pointHole = {
        points: 0,
      }
      pointHoles.push(pointHole);
    }
    for (let player of modPairing.players) {
      player.pointHoles = pointHoles;
    }
    if ([1].includes(pointsDescription)) {
      const flipWolfPartners = [];
      for (let p = 0; p < 18; p++) {
        const headsArr = [];
        const tailsArr = [];
        for (let r = 0; r < members.length; r++) {
          const num = Math.round(Math.random() * 1000000).toString();
          const at = Math.round(Math.random() * num.length);
          const atInt = at > 0 ? at - 1 : at;
          const numAt = num.charAt(atInt);
          const heads = +numAt % 2 === 0;
          if (heads) {
            headsArr.push(r)
          } else {
            tailsArr.push(r);
          }
        }
        const usedPartners = headsArr.length > 0 ? headsArr.join('') : tailsArr.join('');
        flipWolfPartners.push(usedPartners)
      }

      modPairing.partners = flipWolfPartners;
    }

    if (pointsHandicapMethod > 1) {
      let low = -99;
      if (pointsHandicapMethod === 2) {
        for (const player of modPairing.players) {
          const { courseHandicap } = player;
          if (courseHandicap > low) {
            low = courseHandicap;
          }
        }
      }

      if (pointsHandicapMethod < 4) {
        for (const player of modPairing.players) {
          const { courseHandicap } = player;
          const pointsHandicap = pointsHandicapMethod === 2 ? player.courseHandicap - low : courseHandicap;
          player.pointsHandicap = Math.round(pointsHandicap * (pointsHandicapPercentage / 100));
        }
      }

      if (pointsHandicapMethod === 4) {
        for (let i = 0; i < this.manualHandicaps.length; i++) {
          const manualHandicap = this.manualHandicaps.at(i).value;
          const { id, handicap } = manualHandicap;
          const thePlayer = modPairing.players.find((p: any) => p.id === id);
          thePlayer.pointsHandicap = handicap * -1;
        }
      }
    }
    return modPairing;
  }

  initBoolArr(isTwist: boolean) {
    const bools = [];
    let isFirstTime = true;
    for (let i = 0; i < 18; i++) {
      if (isFirstTime && isTwist) {
        bools.push(true);
        isFirstTime = false;
      } else {
        bools.push(false);
      }
    }

    return bools;
  }

  initProxs() {
    const proxs = [];
    for (let i = 0; i < 18; i++) {
      proxs.push('');
    }

    return proxs;
  }

  eventHandicapSet() {
    return this.eventTee().handicapSet
  }

  eventTee() {
    return this.course.tees.find(((t: any) => t.id === this.event.tee));
  }

  formatDisplayCourseHandicap(courseHandicap: number) {
    let displayCourseHandicap = (courseHandicap * -1).toString();
    if (courseHandicap > 0) {
      if (Number.isInteger(courseHandicap)) {
        displayCourseHandicap = `+${courseHandicap}.0`;
      } else {
        displayCourseHandicap = `+${courseHandicap}`;
      }
    }

    return displayCourseHandicap;
  }

  async getCourse() {
    const courseData = await this.courseService.getCourseByDocIdMod(this.event.course);
    const { tees } = courseData;
    const filteredMensTees = tees.filter(t => t.gender === 'Male');
    this.mensTees = filteredMensTees.sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0);
    const filteredLadiesTees = tees.filter(t => t.gender === 'Female');
    this.ladiesTees = filteredLadiesTees.sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0);
    this.course = courseData;
    return
  }

  async populateCurrentPlayers() {
    const t1selMembers = [];
    const t2selMembers = [];

    const pairingData = await this.pairingService.getByDocIdMod(this.pairingId);
    const { players, team1members } = pairingData;
    this.pairing = pairingData;
    for (const player of players) {
      const { id } = player;
      const tempMember = this.buildTempMember(player);
      this.members.push(tempMember);
      const t1member = team1members.find(t => t.id === id);
      if (t1member !== undefined) {
        this.team1members.push(tempMember);
        t1selMembers.push(id)
      } else {
        this.team2members.push(tempMember)
        t2selMembers.push(id)
      }
    }
    this.selectForm.patchValue({
      team1selMembers: t1selMembers,
      team2selMembers: t2selMembers
    })
    this.populatePointsInfo();
    this.isLoading = false;
  }

  buildTempMember(player: any) {
    const { name, handicapIndex, displayHandicapIndex,
      courseHandicap, displayCourseHandicap, skinsHandicap, displaySkinsHandicap, scores, id, flight, includeInSkins, tee, gender,
      firstname, lastname } = player;
    const tempMember = this.fb.group({
      name: [name],
      handicapIndex: [handicapIndex],
      displayHandicapIndex: new FormControl(displayHandicapIndex, [
        Validators.pattern('[+]?[0-9]{0,2}[.]{1}[0-9]{1}'),
        Validators.required,
      ]),
      courseHandicap: [courseHandicap],
      displayCourseHandicap: [displayCourseHandicap],
      skinsHandicap: [skinsHandicap],
      displaySkinsHandicap: [displaySkinsHandicap],
      gender: [gender],
      tee: new FormControl(tee),
      scores: [scores],
      id: [id],
      flight: [flight],
      includeInSkins: new FormControl(includeInSkins),
      firstname: new FormControl(firstname),
      lastname: new FormControl(lastname),
    });

    return tempMember;
  }

  populatePointsInfo() {
    if (this.pairing) {
      const { players, usePoints, pointsDescription, pointsAmount, team1, team2, pointsHandicapMethod, pointsHandicapPercentage } = this.pairing;
      const thePointsDescription = this.pointsDescriptions.find(p => p.value === pointsDescription);
      if (thePointsDescription) {
        const { teamSize, hasTeams } = thePointsDescription;
        this.pairingPlayers = hasTeams ? this.members.value : [];
        this.hasTeams = hasTeams;
        const validator = teamSize === 0 ? null : [Validators.required, this.pointsTeamSizeValidator.teamSizeValidator(teamSize)];
        this.team1FC.setValidators(validator);
        this.team2FC.setValidators(validator);
        this.team1FC.updateValueAndValidity();
        this.team2FC.updateValueAndValidity();
      }

      this.selectForm.patchValue({
        usePoints,
        pointsDescription,
        pointsAmount,
        pointsHandicapMethod,
        pointsHandicapPercentage,
      })

      if (this.hasTeams) {
        this.selectForm.patchValue({
          team1,
          team2,
        })
      } 

       // load the manual array 
       if (pointsHandicapMethod === 4) {
        for (const player of players) {
          const { name, id, pointsHandicap } = player;
          const manualHandicap = this.fb.group({
            id: [id],
            name: [name],
            handicap: new FormControl([pointsHandicap], Validators.required),
          })
          this.manualHandicaps.push(manualHandicap)
        }
        for (let i = 0; i < this.members.length; i++) {
          this.manualHandicaps.at(i).get('name').disable();
        }
      }
    }
  }

  setMembersUsingFormChangeEvent(pairing) {
    const a = [];
    for (const player of pairing.players) {
      a.push(player.id);
    }

    this.selectForm.patchValue({ team1selMembers: a });
  }

  pointsHandicapMethodChanged() {
    if (this.pointsHandicapMethod === 4) {
      for (let i = 0; i < this.members.length; i++) {
        const member = this.members.at(i);
        const { id, name } = member.value;
        const manualHandicap = this.fb.group({
          id: [id],
          name: [name],
          handicap: new FormControl(0, Validators.required),
        })
        this.manualHandicaps.push(manualHandicap)
      }
      for (let i = 0; i < this.members.length; i++) {
        this.manualHandicaps.at(i).get('name').disable();
      }
    }
    if (this.pointsHandicapMethod < 4) {
      const len = (this.manualHandicaps as FormArray).length;
      for (let i = 0; i < len; i++) {
        (this.manualHandicaps as FormArray).removeAt(0);
      }
    }
  }

  async teeSelectionChanged(index: number) {
    const { handicapIndex, id: playerId } = this.members.value[index];
    const tee = this.members.controls[index].get('tee').value;
    const playerTee = this.course.tees.find(((t: any) => t.id === tee));
    console.log('course', this.course)
    console.log({playerTee})
    const par = this.course.handicapSets.find(h => h.id = playerTee.handicapSet).par
    const { slope, rating } = playerTee;
    const newCourseHandicap = calcCourseHandicap(handicapIndex, slope, par, rating)
    const newSkinsHandicap = this.calcSkinsHandicap(playerId, newCourseHandicap);
    const absNewSkinsHandicap = Math.abs(newSkinsHandicap);
    const displaySkinsHandicap = newSkinsHandicap >= 0 ? newSkinsHandicap : `+${absNewSkinsHandicap}`;
    this.members.controls[index].get('courseHandicap').patchValue(newCourseHandicap);
    this.members.controls[index].get('displayCourseHandicap').patchValue(formatDisplayCourseHandicap(newCourseHandicap));
    this.members.controls[index].get('skinsHandicap').patchValue(newSkinsHandicap);
    this.members.controls[index].get('displaySkinsHandicap').patchValue(displaySkinsHandicap);
    console.log('form', this.selectForm);
    const { memberId: lowGuyId } = this.lowSkinsGuy;
    let theLowCourseHandicap = this.lowSkinsGuy.courseHandicap
    if (this.formLowGuy) {
      theLowCourseHandicap = this.formLowGuy.courseHandicap;
    }
    if (newCourseHandicap > theLowCourseHandicap) {
      const difference = newCourseHandicap - theLowCourseHandicap;
      const lowSkinsGuy = {
        memberId: playerId,
        handicapIndex,
        name: this.members.controls[index].get('name').value,
        courseHandicap: newCourseHandicap,
        difference,
      }
      this.lowSkinsGuy = lowSkinsGuy;
      this.formLowGuy = this.lowSkinsGuy;
      this.newLowSkinsGuy = undefined;
      this.updateOtherPlayersInPairing(playerId);
    }
    // changed to a tee that makes no longer the low guy;
    if (newCourseHandicap < theLowCourseHandicap && playerId === lowGuyId) {
      await this.getLowCourseHandicap();
      const { memberId, courseHandicap: lgCourseHandicap } = this.lowSkinsGuy;
      const difference = newCourseHandicap - lgCourseHandicap;
      this.lowSkinsGuy.difference = difference;
      this.updateOtherPlayersInPairing(memberId);
      this.formLowGuy = undefined;
      this.newLowSkinsGuy = this.lowSkinsGuy;
      const isInGroup = this.checkIfLowGuyIsInThisGroup();
      if (isInGroup) {
        this.formLowGuy = this.lowSkinsGuy;
      }
    }
  }

  updateOtherPlayersInPairing(playerId: string) {
    for (let i = 0; i < this.members.length; i++) {
      const member = this.members.at(i);
      const { courseHandicap, id } = member.value;

      let newSkinsHandicap = this.calcSkinsHandicap(id, courseHandicap);
      if (id === playerId) {
        newSkinsHandicap = 0;
      }
      const newDisplaySkinsHandicap = newSkinsHandicap;
      this.members.controls[i].get('skinsHandicap').patchValue(newSkinsHandicap);
      this.members.controls[i].get('displaySkinsHandicap').patchValue(newDisplaySkinsHandicap);
    }
  }

  checkIfLowGuyIsInThisGroup() {
    let isInGroup = false;
    const { lgMemberId } = this.lowSkinsGuy;

    let mLength = 0;
    mLength = this.members.length;
    for (let i = 0; i < mLength; i++) {
      const member = (this.selectForm.get('members') as FormArray).at(i).value;
      const { id: memberId } = member;
      if (lgMemberId === memberId) {
        isInGroup = true;
      }
    }

    return isInGroup;
  }

  adjustSkinsHandicapForPairing(adjustmentAmt: number) {
    let i = 0;
    for (const player of this.members.value) {
      const newSkinsHandicap = player.skinsHandicap + adjustmentAmt;
      this.members.controls[i].get('skinsHandicap').patchValue(newSkinsHandicap);
      i++;
    }
  }

  cancel() {
    this.modalCtrl.dismiss(
      {
        pairing: null,
        warning: 0,
      }
    );
  }

  validateSelections(control: any) {
    const selectedValues = control.value;
    if (selectedValues && selectedValues.length === 2) {
      return null; // Validation passes if at least two values are selected
    } else {
      return { 'insufficientSelections': true }; // Validation fails
    }
  }

  get team1selMembers() {
    return this.selectForm.get('team1selMembers');
  }

  get team2selMembers() {
    return this.selectForm.get('team2selMembers');
  }

  get team1members() {
    return this.selectForm.get('team1members') as FormArray;
  }

  get team2members() {
    return this.selectForm.get('team2members') as FormArray;
  }

  get members() {
    return this.selectForm.get('members') as FormArray;
  }

  get usePoints() {
    return this.selectForm.get('usePoints').value;
  }

  get pointsAmount() {
    return this.selectForm.get('pointsAmount').value;
  }

  get pointsAmountFC() {
    return this.selectForm.get('pointsAmount');
  }

  get pointsHandicapMethod() {
    return this.selectForm.get('pointsHandicapMethod').value;
  }

  get pointsHandicapMethodFC() {
    return this.selectForm.get('pointsHandicapMethod');
  }

  get pointsHandicapPercentageFC() {
    return this.selectForm.get('pointsHandicapPercentage');
  }

  get pointsDescription() {
    return this.selectForm.get('pointsDescription').value;
  }

  get pointsDescriptionFC() {
    return this.selectForm.get('pointsDescription');
  }

  get team1FC() {
    return this.selectForm.get('team1');
  }

  get team2FC() {
    return this.selectForm.get('team2');
  }

  get manualHandicaps() {
    return this.selectForm.get('manualHandicaps') as FormArray;
  }

  get f() {
    return this.selectForm.controls;
  }
}
