import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';

@Injectable()
export class EventFlightBreaksService extends BaseService {

    node = 'event-flight-breaks';

    async getByDocId(id: string) {
        const db = getFirestore();
        const docRef = doc(db, this.node, id);
        const docSnap = await getDoc(docRef);
        return docSnap.data();
      }
}

