import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MemberService } from '../services/member.service';
import { BillingProductService } from '../services/billing-product.service';
import { AlertController, IonicSafeString, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../services/global.service';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-change-billing-modal',
  templateUrl: './change-billing-modal.page.html',
  styleUrls: ['./change-billing-modal.page.scss'],
})
export class ChangeBillingModalPage implements OnInit {

  currentUser = '';
  member: any;
  billingProducts = [];
  currentProduct: any;
  paymentMethod: any;
  changeSubscription = false;
  changePayment = false;
  cardForm: any;
  selectedBillingProduct: any;
  iFrameLoaded = false;
  updateBillingOptions = [];
  result: any;
  nextRunDate = '';

  public billingUpdateForm = this.fb.group({
    updateBillingOption: new FormControl(''),
  })

  constructor(
    private authService: AuthService,
    private billingProductService: BillingProductService,
    private globalServie: GlobalService,
    private memberService: MemberService,
    private fb: FormBuilder,
    private http: HttpClient,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,

  ) { }

  async ngOnInit() {
    this.updateBillingOptions = this.globalServie.getBillingUpdateOptions();
    this.getInitialData();
  }

  async getInitialData() {
    const loader = await this.loadingCtrl.create({
      message: 'Loading Your Billing Info',
      translucent: true,
      spinner: 'bubbles',
    });
    await loader.present();

    const currentUser = await this.authService.currentUser();
    const memberData = await this.memberService.getByDocIdMod(currentUser);
    this.member = memberData;
    await this.getPaymentMethod(memberData.billingEnrollment.paymentMethodId);
    const products = [];
    const docs = await this.billingProductService.getAll();
    for (const doc of docs) {
      const data = doc.data();
      products.push(data);
    }
    this.billingProducts = products;
    const { billingEnrollment } = this.member;
    const { productId } = billingEnrollment;
    const theProduct = this.billingProducts.find(p => p.id === productId);
    if (theProduct) {
      this.currentProduct = theProduct;
      this.selectedBillingProduct = theProduct;
    }

    loader.dismiss();
  }

  getPaymentMethod(paymentMethodId: number) {
    return new Promise<any>((resolve, reject) => {
      const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/getPaymentMethod?paymentMethodId=${paymentMethodId}`;
      this.http.get(url).subscribe({
        next: (res: any) => {
          this.paymentMethod = res;
        },
        error: (err) => {
          console.error('Error occurred:', err);
          this.presentErrorAlert();
        },
        complete: () => {
          resolve(null)
        }
      });
    })

  }

  updateBillingOptionChanged() {
    const option = this.f.updateBillingOption.value;

    if ([1, 2].includes(+option)) {
      setTimeout(() => {
        if (!this.iFrameLoaded) {
          this.loadBillingIFrame();
          this.iFrameLoaded = true;
        }
      }, 500);
    }

    if ([3].includes(+option)) {
      this.iFrameLoaded = false;
      this.getSchedule()
    }
  }

  getSchedule() {
    const { billingEnrollment } = this.member;
    const scheduleId = billingEnrollment ? billingEnrollment.scheduleId : 0;
    const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/getSchedule?scheduleId=${scheduleId}`;
    if (scheduleId) {
      this.http.get(url).subscribe({
        next: (res: any) => {
          this.nextRunDate = res.next_run_date;
        },
        error: (err) => {
          console.error('Error occurred:', err);
          this.presentErrorAlert();
        },
        complete: () => {
          console.log('Request completed.');
        }
      });
    }
  }

  loadBillingIFrame() {
    const z = new (window as any).HostedTokenization('pk_wdXqClnqD1tmPG2dDnICOyawGrYwl')
    const cardForm = z.create('card-form');
    this.cardForm = cardForm;
    cardForm.mount('#my-div')
    cardForm.setStyles({
      container: 'border: 2px solid blue',
    });
  }

  async acceptCard() {

    try {
      this.result = await this.cardForm.getNonceToken();
      const desc = this.getDesc();
      const alert = await this.alertCtrl.create({
        header: 'Alert',
        message: new IonicSafeString(`${desc}`),
        cssClass: 'custom-alert',
        buttons: [{
          text: 'Yes',
          handler: () => {
            this.submitCardInfo();
          }
        }, {
          text: 'No',
        }]
      });

      await alert.present();
      alert.onDidDismiss().then();
    }
    catch (error) {
      this.presentCardAlert(error.message);
    }
  }

  submitCardInfo() {
    const option = +this.f.updateBillingOption.value;
    if (option === 1) {
      this.processExpirationChange()
    }
    if (option === 2) {
      this.processCardChange()
    }
  }

  async processExpirationChange() {
    if (this.result) {
      const { last4 } = this.result;
      if (last4 === this.paymentMethod.last4) {
        const loader = await this.loadingCtrl.create({
          message: 'Updating your payment information',
          translucent: true,
          spinner: 'bubbles',
        });
        await loader.present();

        const body = {
          result: this.result,
          paymentMethodId: this.member.billingEnrollment.paymentMethodId,
          memberId: this.member.id,
        }

        const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/updatePaymentMethod`;
        this.http.post(url, body).subscribe({
          next: () => {
            loader.dismiss();
            this.makeSuccessfulToast();
            this.modalCtrl.dismiss();
          },
          error: (err) => {
            loader.dismiss();
            console.error('Error occurred:', err);
            this.presentErrorAlert();
          },
          complete: () => {
            console.log('Request completed.');
          }
        });
      } else {
        const alert = await this.alertCtrl.create({
          header: 'Alert',
          message: new IonicSafeString(`The last 4 digits for the card you submitted do not match the last 4 for the payment on record.
            <br><br>Please validate and try again`),
          cssClass: 'custom-alert',
          buttons: ['OK'],
        });

        await alert.present();
        alert.onDidDismiss().then();
      }
    }
  }

  async processCardChange() {
    if (this.result) {
      const loader = await this.loadingCtrl.create({
        message: 'Adding your new card',
        translucent: true,
        spinner: 'bubbles',
      });
      await loader.present();


      const { id: memberId, billingEnrollment } = this.member;
      const { paymentMethodId, scheduleId, billingCustomerId: customerId, amount, recurringDescription } = billingEnrollment;


      const body = {
        result: this.result,
        paymentMethodId,
        scheduleId,
        customerId,
        amount,
        memberId,
        recurringDescription,
      }

      const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/updateCard`;
      this.http.post(url, body).subscribe({
        next: () => {
          loader.dismiss();
          this.makeSuccessfulToast();
          this.modalCtrl.dismiss();
        },
        error: (err) => {
          loader.dismiss();
          this.presentErrorAlert();
          console.error('Error occurred:', err);
        },
        complete: () => {
          console.log('Request completed.');
        }
      });
    }
  }

  async billingProductSelected(billingProduct: any) {

    const { billingEnrollment } = this.member;
    const { amount: oldAmount, recurringDescription: oldRecurringDescription, frequency: oldFrequency } = billingEnrollment;
    const { amount, frequency } = billingProduct;

    const reformattedRunDate = this.reformatDate(this.nextRunDate);

    const msg = `You are attempting to change your current subscription from ${oldFrequency} at $${oldAmount} to ${frequency} at $${amount}.
    <br><br>Your card will be charged $${amount} on ${reformattedRunDate}.<br><br>Do you want to continue?`

    const alert = await this.alertCtrl.create({
      header: 'Alert',
      message: new IonicSafeString(msg),
      cssClass: 'custom-alert',
      buttons: [{
        text: 'Yes',
        handler: () => {
          this.submitSubscriptionChange(billingProduct);
        }
      }, {
        text: 'No',
      }]
    });

    await alert.present();
    alert.onDidDismiss().then();

  }

  async submitSubscriptionChange(billingProduct: any) {

    const loader = await this.loadingCtrl.create({
      message: 'Changing your Subscription',
      translucent: true,
      spinner: 'bubbles',
    });
    await loader.present();

    const { billingEnrollment, id: memberId } = this.member;
    const { scheduleId } = billingEnrollment;
    const { amount, frequency, description, id: productId } = billingProduct;

    const body = {
      scheduleId,
      amount,
      recurringDescription: description,
      frequency,
      memberId,
      productId,
    }

    const url = `https://us-central1-golfbetwhisperer.cloudfunctions.net/changeSubscription`;
    this.http.post(url, body).subscribe({
      next: () => {
        loader.dismiss();
        this.makeSuccessfulToast();
        this.modalCtrl.dismiss();
      },
      error: (err) => {
        loader.dismiss();
        this.presentErrorAlert();
        console.error('Error occurred:', err);
      },
      complete: () => {
        console.log('Request completed.');
      }
    });
  }

  getDesc() {
    let desc = '';
    const option = +this.f.updateBillingOption.value;
    if (option) {
      if (option === 1) {
        desc = 'You have choosen to change your expiration date. <br><br>Would you like to continue.'
      }
      if (option === 2) {
        desc = 'You have choosen to add a new card. Your payment info will be replaced with the new card info.<br><br> Would you like to continue?'
      }
      if (option === 3) {
        desc = 'You have choosen to change your subscription. <br><br>Your new plan will start at the conclusion of your previous subscription. <br><br>Would you like to continue?'
      }
    }

    return desc;
  }

  actionDescription() {
    let desc = '';
    const option = +this.f.updateBillingOption.value;
    if (option) {
      if (option === 1) {
        desc = 'You have choosen to change your expiration date, please enter your current card number, new expiration date (2 digit month and 2 digit year), and CVV number.'
      }
      if (option === 2) {
        desc = 'You have choosen to add a new card, please enter your new card number, new expiration date (2 digit month and 2 digit year), and CVV number.'
      }
      if (option === 3) {
        desc = 'You have choosen to change your subscription. Please choose your new subscription. You will be charged for the new plan when your current subscription expires.'
      }
    }

    return desc;
  }

  disableButton(product) {

    return this.member && this.member.billingEnrollment.productId === product.id;
  }

  async presentCardAlert(msg: string) {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Incorrect Billing Information',
      message: new IonicSafeString(`${msg}. <br><br>Please correct and retry.`),
      buttons: ['OK'],
      cssClass: 'custom-alert'
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async makeSuccessfulToast() {
    const message = this.getToastMessage();
    const toast = await this.toastCtrl.create({
      message,
      position: 'middle',
      color: 'primary',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        }
      ]
    });

    toast.present();
  }

  getToastMessage() {
    let msg = '';
    const option = +this.f.updateBillingOption.value;
    if (option) {
      if (option === 1) {
        msg = 'Your expiration date has been successfully updated';
      }
      if (option === 2) {
        msg = 'Your new card has been added and subsquent payments will use this card for payments';
      }
      if (option === 3) {
        msg = 'Your subscription has been updated and you will be charged the new amount with your next renewal payment'
      }
    }

    return msg;

  }

  reformatDate(dateStr: string) {
    const [year, month, day] = dateStr.split("-");

    return `${month}-${day}-${year}`;
  }

  changeSubscriptionPressed() {
    this.changeSubscription = true;
  }

  choosePlan(product: any) {
    this.selectedBillingProduct = product;
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  async presentErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Incorrect Payment Information',
      message: 'Unfortunately your billing information was not accepted, please try again by resubmitting',
      buttons: ['OK'],
      cssClass: 'custom-alert'
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  get f() {
    return this.billingUpdateForm.controls;
  }
} 
